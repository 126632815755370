<template>
  <el-dialog
    class="store-dialog"
    width="70%"
    title="选择商品"
    :visible.sync="showDialog"
    :close-on-click-modal="false"
    :before-close="cancelAction"
    append-to-body
  >
    <!-- <el-tabs v-model="params.item_type" type="card" @tab-click="handleTabClick">
      <el-tab-pane label="实体类商品" name="normal"></el-tab-pane>
      <el-tab-pane label="服务类商品" name="services"></el-tab-pane>
    </el-tabs> -->
    <div v-if="params.item_type === 'normal'" style="margin-bottom: 15px">
      <StoreFilter
        v-if="!filter"
        class="store"
        :data="store"
        :lock="lockStore"
        @change="handleStoreChange"
      />
      <el-radio-group class="radio" v-model="rel_item_type" v-if="recommend">
        <el-radio-button label="all">全部商品</el-radio-button>
        <el-radio-button label="some">指定商品</el-radio-button>
      </el-radio-group>
      <el-row :gutter="10">
        <el-col :span="6">
          <el-input
            placeholder="输入商品名称"
            v-model="params.keywords"
            clearable
            class="input-with-select"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="searchByKey"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="5">
          <el-select
            v-model="params.approve_status"
            placeholder="选择状态"
            clearable
            @change="searchByKey"
          >
            <el-option
              v-for="item in statusOption"
              :key="item.value"
              :label="item.title"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-select
            v-model="params.templates_id"
            @change="searchByKey"
            clearable
            placeholder="选择运费模板"
          >
            <el-option
              v-for="item in templatesList"
              :key="item.template_id"
              :label="item.name"
              :value="item.template_id"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <el-input
            placeholder="0"
            v-model="store_value"
            class="input-with-select"
            clearable
          >
            <el-select
              v-model="storeSelect"
              slot="prepend"
              placeholder="库存筛选"
              style="width: 110px"
            >
              <el-option label="库存大于" value="gt"></el-option>
              <el-option label="库存小于" value="lt"></el-option>
            </el-select>
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="storeChangeSelect"
            ></el-button>
          </el-input>
        </el-col>
      </el-row>
      <!--
      <el-row :gutter="10">
        <el-col :span="6">
          <el-select v-model="params.is_gift" placeholder="是否赠品" style="width: 110px">
            <el-option label="赠品" :value="true"></el-option>
            <el-option label="非赠品" :value="false"></el-option>
          </el-select>
        </el-col>
      </el-row>
      -->
    </div>
    <el-table
      ref="multipleTable"
      v-if="singleData"
      :height="wheight - 190"
      :data="itemsData"
      tooltip-effect="dark"
      style="width: 100%"
      v-loading="loading"
      key="radio"
    >
      <el-table-column label="选择" width="70">
        <template slot-scope="scope">
          <el-radio
            :label="scope.row.itemId"
            v-model="templateRadio"
            @change.native="getTemplateRow(scope.$index, scope.row)"
            >&nbsp;
          </el-radio>
        </template>
      </el-table-column>
      <el-table-column prop="goods_id" label="商品 ID" width="70"/>
      <el-table-column prop="itemId" label="SKU ID" width="70"/>
      <el-table-column prop="itemName" label="商品名称"></el-table-column>
      <el-table-column prop="item_bn" label="商品编码"></el-table-column>
      <el-table-column
        v-if="this.isSpec"
        prop="item_spec_desc"
        label="规格"
      ></el-table-column>
      <el-table-column
        prop="price"
        label="价格"
        width="80"
        :formatter="priceformatter"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="store"
        label="库存"
        width="80"
        show-overflow-tooltip
      ></el-table-column>
    </el-table>
    <el-table
      ref="multipleTable"
      v-else
      :data="itemsData"
      tooltip-effect="dark"
      style="width: 100%"
      @select="handleSelectChange"
      @select-all="handleSelectAll"
      v-loading="loading"
      :row-key="getRowKeys"
      :select-on-indeterminate="false"
      key="Checkbox"
    >
      <el-table-column
        type="selection"
        :selectable="disabledItem"
        :reserve-selection="true"
        width="50"
      ></el-table-column>
      <el-table-column prop="goods_id" label="商品 ID" width="70"/>
      <el-table-column prop="itemId" label="SKU ID" width="70"/>
      <el-table-column prop="itemName" label="商品名称"></el-table-column>
      <el-table-column prop="item_bn" label="商品编码"></el-table-column>
      <el-table-column
        v-if="this.isSpec"
        prop="item_spec_desc"
        label="规格"
      ></el-table-column>
      <el-table-column
        prop="price"
        label="价格"
        width="80"
        :formatter="priceformatter"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="store"
        label="库存"
        width="80"
        show-overflow-tooltip
      ></el-table-column>
    </el-table>
    <div v-if="total_count > params.pageSize" class="pager">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :current-page.sync="params.page"
        :page-sizes="[10, 20, 30, 50]"
        :total="total_count"
        :page-size="params.pageSize"
      >
      </el-pagination>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancelAction">取 消</el-button>
      <el-button type="primary" @click="saveStoreAction">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import StoreFilter from '@/components/function/storeFilter'
import { getItemsList, getCategory, getSkuList } from '@/api/goods'
import { getShippingTemplatesList } from '@/api/shipping'
import { getDefaultCurrency } from '@/api/company'
import { getDistributorItems } from '@/api/marketing'

export default {
  props: {
    itemsVisible: {
      type: Boolean,
      default: false
    },
    getStatus: {
      type: Boolean,
      default: false
    },
    relStore: {
      type: Object,
      default: function () {
        return {}
      }
    },
    lockStore: {
      type: Boolean,
      default: false
    },
    relItemsIds: {
      type: Array,
      default: function () {
        return []
      }
    },
    hiddenData: {
      type: Array,
      default: function () {
        return []
      }
    },
    itemType: {
      type: String,
      default: ''
    },
    filter: [String, Object],
    notSku: {
      type: Boolean,
      default: false
    },
    isSpec: {
      type: Boolean,
      default: false
    },
    isSpu: {
      type: Boolean,
      default: false
    },
    itemTier: {
      type: Array,
      default: function () {
        return ['all']
      }
    },
    limitNum: {
      type: Number,
      default: 0
    },
    single: {
      type: Boolean,
      default: false
    },
    isGift: {
      type: Boolean,
      default: false
    },
    unwantedGift: {
      type: Boolean,
      default: false
    },
    mainGoodsIsEdu: {
      type: Boolean,
      // default: function () {
        // return false
      // }
      default: null
    },
    recommend: {
      type: Boolean,
      default: false
    },
    is_addcart_goods: {
      type: String,
      default: ''
    },
    rowKey: {
      type: String,
      default: 'itemId'
    }
  },
  data () {
    return {
      loading: false,
      itemsData: [],
      multipleSelection: [],
      selectRows: [],
      total_count: '',
      activeType: 'first',
      store: {},
      params: {
        page: 1,
        pageSize: 30,
        keywords: '',
        item_type: 'normal',
        special_type: ['normal', 'drug'],
        approve_status: 'onsale',
        templates_id: '',
        distributor_id: '',
        is_sku: false,
        item_tier: [],
        // main_goods_is_edu: false,
        // is_gift: false,
      },
      hiddenItem: [],
      storeSelect: '',
      store_value: '',
      templatesList: [],
      statusOption: [
        {
          title: '前台可销售',
          value: 'onsale'
        },
        {
          title: '可线下销售',
          value: 'offline_sale'
        },
        {
          title: '前台仅展示',
          value: 'only_show'
        },
        {
          title: '不可销售',
          value: 'instock'
        }
      ],
      currency: {},
      cursymbol: '￥',
      templateRadio: '',
      rel_item_type: 'some'
    }
  },
  computed: {
    ...mapGetters(['wheight'])
  },
  components: {
    StoreFilter
  },
  methods: {
    handleTabClick () {
      this.getNewsList()
    },
    handleStoreChange (val) {
      this.store = val
      this.$refs.multipleTable.clearSelection()
      this.params.distributor_id = val.id
      this.getNewsList()
    },
    getTemplateRow (index, row) {
      this.selectRows = new Array(row)
    },
    disabledItem (row, index) {
      if (this.hiddenItem.indexOf(row.itemId) > -1) {
        return false
      } else {
        return true
      }
    },
    getRowKeys (row) {
      return row[this.rowKey]
    },
    handleCurrentChange (page_num) {
      this.$refs.multipleTable.clearSelection()
      this.params.page = page_num
      this.getNewsList()
    },
    handleSizeChange (pageSize) {
      this.$refs.multipleTable.clearSelection()
      this.params.page = 1
      this.params.pageSize = pageSize
      this.getNewsList()
    },
    searchByKey () {
      this.params.page = 1
      this.getNewsList()
    },
    storeChangeSelect () {
      if (this.storeSelect == 'gt') {
        this.params.store_gt = this.store_value
      }
      if (this.storeSelect == 'lt') {
        this.params.store_lt = this.store_value
      }
      this.params.page = 1
      this.getNewsList()
    },
    handleSelectAll (val) {
      if (this.limitNum) {
        this.$message({ message: '当前组件不支持全选', type: 'warning' })
        this.$refs.multipleTable.clearSelection()
        this.itemsData.forEach(item => {
          let checked = this.selectRows.find(n => n.itemId === item.itemId)
          if (checked) {
            this.$refs.multipleTable.toggleRowSelection(item)
          }
        })
        return
      }
      if (val.length > 0) {
        val.forEach(item => {
          let inChecked = this.selectRows.findIndex(
            n => item.itemId === n.itemId
          )
          if (inChecked === -1) {
            this.selectRows.push(item)
          }
        })
      } else {
        this.itemsData.forEach((item, index) => {
          let inChecked = this.selectRows.findIndex(
            n => item.itemId === n.itemId
          )
          if (inChecked !== -1) {
            this.selectRows.splice(inChecked, 1)
          }
        })
      }
    },
    handleSelectChange (val, row) {
      let inChecked = this.selectRows.findIndex(
        item => row.itemId === item.itemId
      )
      if (inChecked !== -1) {
        this.selectRows.splice(inChecked, 1)
      } else {
        if (this.limitNum && this.limitNum == 1) {
          this.selectRows = []
          this.selectRows.push(row)
          this.$refs.multipleTable.clearSelection()
          this.selectRows.forEach(item => {
            this.$refs.multipleTable.toggleRowSelection(item)
          })
          return
        } else if (this.limitNum && this.selectRows.length >= this.limitNum) {
          this.$message({
            message: `最多选择${this.limitNum}件商品`,
            type: 'warning'
          })
          this.$refs.multipleTable.clearSelection()
          this.selectRows.forEach(item => {
            this.$refs.multipleTable.toggleRowSelection(item)
          })
          return
        }
        this.selectRows.push(row)
      }
    },
    cancelAction () {
      this.selectRows = []
      this.$emit('closeStoreDialog')
    },
    saveStoreAction () {
      this.$emit('chooseStore', this.selectRows, this.store, this.rel_item_type)
    },
    getNewsList () {
      if (this.getStatus) {
        this.loading = true
        let param = {
          ...this.params
        }
        if (this.is_addcart_goods) { // 商品-普通快递配送商品模块用到传0 商品-推荐商品传1
          param['is_addcart_goods'] = this.is_addcart_goods
        }
        if (
          this.system_mode == 'platform' ||
          !this.params.distributor_id ||
          this.params.distributor_id == '0'
        ) {
          getItemsList(param).then(response => {
            this.itemsData = response.data.data.list
            this.total_count = parseInt(response.data.data.total_count)
            this.loading = false
            if(this.rowKey !== 'itemId') {
              // 根据回显数据设置勾选功能
              const ids = this.relItemsIds.map(re => re[this.rowKey])
              const selectRowIds = this.selectRows.map(s => s[this.rowKey])
              this.itemsData.forEach(item => {
                // 防止重复添加
                if (ids.includes(item[this.rowKey]) && !selectRowIds.includes(item[this.rowKey])) {
                  this.selectRows.push(item)
                }
              })
            }
            // 实现勾选
            this.$nextTick(() => {
              this.$refs.multipleTable.clearSelection()
              this.itemsData.forEach(item => {
                let checked = this.selectRows.find(n => n[this.rowKey] == item[this.rowKey])
                if (checked) {
                  this.$refs.multipleTable.toggleRowSelection(item, true)
                }
              })
            })
          })
        } else {
          getDistributorItems(param).then(response => {
            this.itemsData = response.data.data.list
            this.total_count = parseInt(response.data.data.total_count)
            this.loading = false
          })
        }
      }
    },
    getShippingTemplatesList () {
      this.loading = true
      getShippingTemplatesList(this.templatesParams).then(response => {
        this.templatesList = response.data.data.list
      })
    },
    getCurrencyInfo () {
      getDefaultCurrency().then(res => {
        this.currency = res.data.data
        this.cursymbol = this.currency.symbol
      })
    },
    getCategory () {
      getCategory([]).then(response => {
        this.categoryList = response.data.data
      })
    },
    priceformatter (row, column) {
      return this.cursymbol + row.price / 100
    },
    changeGoods (type) {
      switch (type) {
        case 'every':
          this.$set(this.params, 'consume_type', 'every')
          this.$delete(this.params, 'item_type')
          this.itemType = 'every'
          break
        case 'normal':
          this.$set(this.params, 'item_type', 'normal')
          this.$delete(this.params, 'consume_type')
          this.itemType = 'normal'
          break
      }
      this.getNewsList()
    }
  },
  mounted () {
    if (this.filter) {
      let filters = this.filter
      let params = this.params
      if (typeof filters === 'string') {
        filters = JSON.parse(filters)
      }
      Object.assign(params, filters)
      this.params = params
    }
    this.getShippingTemplatesList()
    this.getCurrencyInfo()
  },
  computed: {
    showDialog () {
      return this.itemsVisible
    },
    singleData () {
      return this.single
    }
  },
  watch: {
    itemsVisible (val) {
      if (val) {
        if (this.relStore.id) {
          this.params.distributor_id = this.relStore.id
          this.store = this.relStore
        }
        this.params.keywords = ''
        this.templateRadio = ''
        if (this.isSpec) {
          this.params.is_sku = this.isSpec
        }
        if (this.isSpu) {
          this.params.is_spu = this.isSpu
        }
        if (this.itemTier) {
          this.params.item_tier = this.itemTier
        }
        if (this.isGift) {
          this.params.is_gift = true
        }
        if (this.mainGoodsIsEdu !== null) {
          this.params.main_goods_is_edu = this.mainGoodsIsEdu
        }
        if (this.notSku) {
          Object.assign(this.params, { nospec: true })
        }
        let ids = []
        this.loading = true
        this.multipleSelection.forEach(item => {
          ids.push(item.item_id)
        })
        if (ids.length > 0) {
          let param = JSON.parse(JSON.stringify(this.params))
          param.page = 1
          param.pageSize = 999
          param.item_id = ids
          // this.selectRows = []
          if (
            this.system_mode == 'platform' ||
            !param.distributor_id ||
            param.distributor_id == '0'
          ) {
            if(this.rowKey !== 'itemId') {
              this.loading = false
              return
            }
            getSkuList(param).then(res => {
              const selectRows = res.data.data.list
              this.selectRows = selectRows
              this.$refs.multipleTable.clearSelection()
              this.itemsData.forEach(item => {
                let checked = selectRows.find(n => n.itemId === item.itemId)
                if (checked) {
                  this.$refs.multipleTable.toggleRowSelection(item)
                }
              })
              this.loading = false
            })
          } else {
            getDistributorItems(param).then(res => {
              const selectRows = res.data.data.list
              this.selectRows = selectRows
              this.$refs.multipleTable.clearSelection()
              this.itemsData.forEach(item => {
                let checked = selectRows.find(n => n.itemId === item.itemId)
                if (checked) {
                  this.$refs.multipleTable.toggleRowSelection(item)
                }
              })
              this.loading = false
            })
          }
        } else {
          const that = this
          this.$nextTick(() => {
            that.$refs.multipleTable.clearSelection()
          })
          this.loading = false
        }
      }
    },
    relItemsIds (newVal, oldVal) {
      this.multipleSelection = newVal
      this.selectRows = [...newVal]
    },
    relStore (newVal, oldVal) {
      if (newVal.id) {
        this.params.distributor_id = newVal.id
        this.store = newVal
        this.getNewsList()
      }
    },
    itemsData (val) {
      if (this.selectRows.length > 0) {
        this.itemsData.forEach(item => {
          let checked = this.selectRows.find(n => n.itemId === item.itemId)
          if (checked) {
            this.$refs.multipleTable.toggleRowSelection(item)
          }
        })
      }
    },
    getStatus (newVal, oldVal) {
      if (newVal) {
        this.getNewsList()
      }
    },
    itemType (newVal, oldVal) {
      if (newVal) {
        this.params.item_type = newVal
        if (this.unwantedGift == false) {
          if (this.isGift) {
            this.params.is_gift = true
          } else {
            this.params.is_gift = false
          }
        }
        if (this.notSku) {
          Object.assign(this.params, { nospec: true })
        }
        let ids = []
        this.loading = true
        this.multipleSelection.forEach(item => {
          ids.push(item.item_id)
        })
        if (ids.length > 0) {
          let param = JSON.parse(JSON.stringify(this.params))
          param.page = 1
          param.pageSize = 999
          param.item_id = ids
          // this.selectRows = []
          if (
            this.system_mode == 'platform' ||
            !param.distributor_id ||
            param.distributor_id == '0'
          ) {
            getSkuList(param).then(res => {
              const selectRows = res.data.data.list
              this.selectRows = selectRows
              this.$refs.multipleTable.clearSelection()
              this.itemsData.forEach(item => {
                let checked = selectRows.find(n => n.itemId === item.itemId)
                if (checked) {
                  this.$refs.multipleTable.toggleRowSelection(item)
                }
              })
              this.loading = false
            })
          } else {
            getDistributorItems(param).then(res => {
              const selectRows = res.data.data.list
              this.selectRows = selectRows
              this.$refs.multipleTable.clearSelection()
              this.itemsData.forEach(item => {
                let checked = selectRows.find(n => n.itemId === item.itemId)
                if (checked) {
                  this.$refs.multipleTable.toggleRowSelection(item)
                }
              })
              this.loading = false
            })
          }
        } else {
          const that = this
          this.$nextTick(() => {
            that.$refs.multipleTable.clearSelection()
          })
          this.loading = false
        }
      }
    },
    relStore (newVal, oldVal) {
      if (newVal.id) {
        this.params.distributor_id = newVal.id
        this.store = newVal
        this.getNewsList()
      }
    },
    itemsData (val) {
      if (this.selectRows.length > 0) {
        this.itemsData.forEach(item => {
          let checked = this.selectRows.find(n => n.itemId === item.itemId)
          if (checked) {
            this.$refs.multipleTable.toggleRowSelection(item)
          }
        })
      }
    },
    getStatus (newVal, oldVal) {
      if (newVal) {
        this.getNewsList()
      }
    },
    itemType (newVal, oldVal) {
      if (newVal) {
        this.params.item_type = newVal
      }
    },
    itemTier (newVal, oldVal) {
      debugger
      if (newVal) {
        this.params.item_tier = newVal
      }
    },
    hiddenData (val) {
      this.hiddenItem = val
    },
    single (val) {
      this.singleData = val
    }
  },
  hiddenData (val) {
    this.hiddenItem = val
  },
  single (val) {
    this.singleData = val
  }
}
</script>

<style scoped lang="scss">
.store {
  margin-bottom: 10px;
}
.store-dialog .el-checkbox {
  display: inline;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.el-select .el-input {
  width: 130px;
}
.pager {
  margin-top: 20px;
}
.tab-group {
  .tab-btn {
    &.on {
      border-color: #e6a23c;
      background-color: #e6a23c;
      color: #fff;
    }
  }
}
.radio{
  margin-bottom: 20px
}
</style>
