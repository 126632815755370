var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"picker-coupon"},[_c('SpFilterForm',{attrs:{"model":_vm.formData,"size":"small"},on:{"onSearch":_vm.onSearch,"onReset":_vm.onSearch}},[_c('SpFilterFormItem',{attrs:{"prop":"keywords"}},[_c('el-input',{attrs:{"placeholder":"请输入优惠券名称"},model:{value:(_vm.formData.keywords),callback:function ($$v) {_vm.$set(_vm.formData, "keywords", $$v)},expression:"formData.keywords"}})],1)],1),_c('SpFinder',{ref:"finder",attrs:{"reserve-selection":"","row-key":"card_id","other-config":{
      'max-height': 460,
      'header-cell-class-name': _vm.cellClass
    },"url":"/discountcard/list","fixed-row-action":true,"show-pager-text":`已选中：${_vm.localSelection.length}`,"setting":{
      columns: [
        // {
        //   name: '卡券类型',
        //   key: 'card_type',
        //   width: '100px',
        //   render: (h, { row }) =>
        //     h(
        //       'el-tag',
        //       {
        //         props: {
        //           size: 'mini'
        //         }
        //       },
        //       cardTypeFormatter(row)
        //     )
        // },
        { name: 'ID', key: 'card_id', width: '100px' },
        { name: '卡券名称', key: 'title' },
        // {
        //   name: '卡券有效期',
        //   formatter: (value, { takeEffect, begin_time, end_time }, col) => {
        //     if (takeEffect) {
        //       return takeEffect
        //     } else {
        //       return getCardValidate(begin_time, end_time)
        //     }
        //   }
        // },
        // {
        //   name: '可领取库存',
        //   formatter: (value, { quantity, get_num }, col) => {
        //     if (quantity > get_num) {
        //       return quantity - get_num
        //     } else {
        //       return 0
        //     }
        //   },
        //   width: '100px'
        // },
        // { name: '领取量', key: 'get_num', width: '80px' },
        // { name: '使用量', key: 'use_num', width: '80px' }
      ]
    },"hooks":{
      beforeSearch: _vm.beforeSearch,
      afterSearch: _vm.afterSearch
    }},on:{"select":_vm.onSelect,"select-all":_vm.handleSelectAll}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }