<template>
  <el-dialog
    class="store-dialog"
    title="选择店铺"
    :visible.sync="showDialog"
    :close-on-click-modal="false"
    :before-close="cancelAction"
  >
    <div style="margin-bottom: 15px">
      <el-row :gutter="20">
        <el-col :span="10">
          <el-input
            placeholder="输入店铺名称"
            v-model="name"
            clearable
          ></el-input>
        </el-col>
        <el-col :span="10">
          <el-input
            placeholder="输入店铺SHOP_CODE，多个英文逗号分割"
            v-model="shop_code"
            clearable
          ></el-input>
        </el-col>

      </el-row>
      <el-row :gutter="20">
        <el-col :span="10">
          <el-input
            placeholder="经销商名称"
            v-model="dealer"
            clearable
          ></el-input>
        </el-col>
        <el-col :span="10">
          <el-input
            placeholder="经销商HQID"
            v-model="hqid"
            clearable
          ></el-input>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="10">
          <el-input
            placeholder="经销商RTM"
            v-model="rtm"
            clearable
          ></el-input>
        </el-col>
        <el-col :span="10">
          <el-button
            icon="el-icon-search"
            @click="handleIconClick"
            type="primary"
          >搜索</el-button>
        </el-col>
      </el-row>

      <!-- <el-switch v-model="is_distributor" active-text="店铺" inactive-text="门店" @change="handleIconClick"></el-switch> -->
    </div>
    <div style="margin-bottom: 15px"></div>
    <el-table
      ref="multipleTable"
      :data="storeData"
      tooltip-effect="dark"
      style="width: 100%"
      @select="handleSelectionChange"
      @select-all="selectAll"
      v-loading="loading"
      :row-key="getRowKeys"
    >
      <el-table-column
        type="selection"
        :reserve-selection="true"
        width="50"
      ></el-table-column>
      <el-table-column
        prop="name"
        label="名称"
      ></el-table-column>
      <el-table-column
        prop="contact"
        label="联系人"
      ></el-table-column>
      <!-- <el-table-column prop="store_name" label="门店"></el-table-column> -->
      <el-table-column
        prop="address"
        label="地址"
        show-overflow-tooltip
      ></el-table-column>
    </el-table>
    <div
      v-if="total_count > params.pageSize"
      class="tr"
      style="margin-top: 20px; text-align: center"
    >
      <el-pagination
        layout="sizes, prev, pager, next"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[100, 200, 300, 400, 500]"
        :total="total_count"
        :page-size="params.pageSize"
      >
      </el-pagination>
    </div>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="cancelAction">取 消</el-button>
      <el-button
        type="primary"
        @click="saveStoreAction"
      >确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getDistributorEasyList } from '@/api/marketing'
export default {
  props: {
    relDataIds: {
      type: Array,
      default: function () {
        return []
      }
    },
    getStatus: {
      type: Boolean,
      default: false
    },
    setPageSize: {
      type: Number,
      default: 100
    },
    isValid: {
      type: Boolean,
      default: false
    },
    storeVisible: {
      type: Boolean,
      default: false
    },
    sourceType: {
      type: String,
      default: 'distributor'
    },
    returnType: {
      type: String,
      default: 'selectRow'
    },
    distributorValid: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      loading: false,
      storeData: [],
      multipleSelection: [],
      pageLimit: 100,
      selectRows: [],
      total_count: '',
      params: {
        page: 1,
        pageSize: 100,
        is_valid: 'true'
      },
      name: '',
      dealer: '',
      hqid: '',
      rtm: '',
      shop_code: '',
      is_distributor: true
    }
  },
  methods: {
    getRowKeys (row) {
      return row.wxShopId
    },
    handleSizeChange (page_size) {
      this.params.pageSize = page_size
      this.getNewsList()
    },
    handleCurrentChange (page_num) {
      this.params.page = page_num
      this.getNewsList()
    },
    handleIconClick () {
      this.params.page = 1
      this.params.dealer = this.dealer
      this.params.hqid = this.hqid
      this.params.rtm = this.rtm
      this.params.shop_code = this.shop_code
      this.params.name = this.name
      this.params.is_distributor = this.is_distributor
      console.log(this.params)
      this.getNewsList()
    },
    toggleSelection (rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    handleSelectionChange (val) {
      if (val.length > 0) {
        this.multipleSelection = val
        const newVal = this.selectRows.filter((item) => {
          const isHaveRow = this.storeData.some((row) => {
            if (item.distributor_id) {
              return item.distributor_id == row.distributor_id
            } else {
              return item == row.distributor_id
            }
          })
          return !isHaveRow
        })
        this.selectRows = [...newVal, ...val]
      } else {
        const list = this.selectRows.filter((row) => {
          const isHave = this.storeData.some((item) => {
            if (row.distributor_id) {
              return item.distributor_id == row.distributor_id
            } else {
              return item.distributor_id == row
            }
          })
          return !isHave
        })
        this.selectRows = list
      }
    },
    // 全选事件
    selectAll (val) {
      if (val.length > 0) {
        this.multipleSelection = val
        val.forEach((item) => {
          let isInArr = -1
          if (this.selectRows.length > 0) {
            isInArr = this.selectRows.findIndex((n) => {
              if (n.distributor_id) {
                return n.distributor_id == item.distributor_id
              } else {
                return n.distributor_id == item.distributor_id
              }
            })
          }
          if (isInArr === -1) {
            this.selectRows.push(item)
          }
        })
      } else {
        const list = this.selectRows.filter((item) => {
          const isHave = this.storeData.some(
            (n) => item.distributor_id === n.distributor_id
          )
          return !isHave
        })
        this.selectRows = list
      }
    },
    cancelAction () {
      this.$emit('closeStoreDialog')
    },
    saveStoreAction () {
      if (this.returnType === 'selectRow') {
        this.$emit('chooseStore', this.selectRows)
      } else {
        this.$emit('chooseStore', this.multipleSelection)
      }
    },
    getNewsList () {
      if (this.getStatus) {
        this.loading = true
        // this.params.pageSize = this.setPageSize
        this.pageLimit = this.setPageSize
        if (this.distributorValid) {
          this.params.is_valid = this.distributorValid
        }
        getDistributorEasyList(this.params).then((response) => {
          this.storeData = response.data.data.list
          this.total_count = parseInt(response.data.data.total_count)
          this.loading = false
          this.multipleSelection = []
          this.$refs.multipleTable.clearSelection()
          if (this.selectRows) {
            this.storeData.forEach((item) => {
              const isHave = this.selectRows.findIndex((n) => {
                if (n.distributor_id) {
                  return (
                    n.distributor_id && n.distributor_id == item.distributor_id
                  )
                } else {
                  return n == item.distributor_id
                }
              })
              if (isHave !== -1) {
                setTimeout(() => {
                  this.$refs.multipleTable.toggleRowSelection(item)
                })
              }
            })
          }
        })
      }
    }
  },
  computed: {
    showDialog () {
      return this.storeVisible
    }
  },
  watch: {
    relDataIds: {
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.selectRows = newVal
        } else {
          this.selectRows = []
        }
      },
      immediate: true,
      deep: true
    },
    getStatus (newVal, oldVal) {
      if (newVal) {
        this.params.is_valid = this.isValid ? this.isValid : 'true'
        this.getNewsList()
      }
    },
    sourceType (newVal, oldVal) {
      console.log(newVal)
      if (newVal) {
      }
    }
  }
}
</script>

<style type="text/css">
.store-dialog .el-checkbox {
  display: inline;
}
</style>

