<template>
    <el-dialog class="store-dialog" title="选择视频资源" :visible.sync="showDialog" :close-on-click-modal="false"
               :before-close="cancelAction">
        <div style="margin-bottom: 15px;">
            <el-input placeholder="输入自定义页面名称" v-model="material_name" clearable>
                <el-button slot="append" icon="el-icon-search" @click="handleIconClick"></el-button>
            </el-input>
        </div>
        <el-table ref="multipleTable" :data="storeData" tooltip-effect="dark" style="width: 100%" v-loading="loading">
            <el-table-column label="选择">
                <template slot-scope="scope">
                    <el-radio :label="scope.row.id" v-model="templateRadio"
                              @change.native="getTemplateRow(scope.$index,scope.row)">&nbsp;
                    </el-radio>
                </template>
            </el-table-column>
          <el-table-column prop="id" width="60" label="id"></el-table-column>
          <el-table-column prop="material_code" width="120" label="素材编码"></el-table-column>

          <el-table-column prop="material_name" min-width="100" label="素材名称"></el-table-column>
          <el-table-column label="素材内容" min-width="150">
            <template slot-scope="scope">
              <video class="video-html" :src="scope.row.material_url" controls="controls">
                您的浏览器不支持 video 标签。
              </video>
            </template>
          </el-table-column>
          <el-table-column prop="material_version" min-width="90" label="素材版本"></el-table-column>
          <el-table-column prop="status_name" min-width="100" label="状态"></el-table-column>
        </el-table>
        <div v-if="total_count > params.pageSize" class="tr">
            <el-pagination
                    layout="prev, pager, next"
                    @current-change="handleCurrentChange"
                    :total="total_count" :page-size="pageLimit">
            </el-pagination>
        </div>
        <span slot="footer" class="dialog-footer">
      <el-button @click="cancelAction">取 消</el-button>
      <el-button type="primary" @click="saveStoreAction">确 定</el-button>
    </span>
    </el-dialog>
</template>

<script>
  import {getAfterSaleMaterialList} from '@/api/aftersale_video'

  export default {
    props: {
      relDataIds: {
        type: Array,
        default: function () {
          return []
        }
      },
      getStatus: {
        type: Boolean,
        default: false
      },
      isValid: {
        type: Boolean,
        default: false
      },
      storeVisible: {
        type: Boolean,
        default: false
      },
      sourceType: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        templateRadio: '',
        dataType: 'distributor',
        loading: false,
        storeData: [],
        multipleSelection: [],
        pageLimit: 10,
        total_count: '',
        params: {
          page: 1,
          pageSize: 10,
          is_valid: 'true',
          status: 'indue',
          material_name:'',
        },
        material_name: '',
      }
    },
    methods: {
      getTemplateRow(index, row) {
        this.multipleSelection = new Array(row)
      },
      getCustomize() {
        getAfterSaleMaterialList(this.params).then(response => {
          this.storeData = response.data.data.list
          this.total_count = parseInt(response.data.data.total_count)
          this.loading = false
          this.multipleSelection = []
        })
      },
      handleCurrentChange(page_num) {
        this.params.page = page_num
        this.getCustomize()
      },
      handleIconClick() {
        this.params.material_name = this.material_name
        this.getCustomize()
      },
      cancelAction() {
        this.$emit('closeStoreDialog')
      },
      saveStoreAction() {
        this.$emit('chooseStore', this.multipleSelection)
      }
    },
    computed: {
      showDialog() {
        return this.storeVisible
      }
    },
    watch: {
      sourceType(newVal, oldVal) {
        this.dataType = this.sourceType
      },
      relDataIds(newVal, oldVal) {
        this.selectRows = newVal
      },
      getStatus(newVal, oldVal) {
        this.getCustomize()
      },
    }
  }
</script>

<style type="text/css">
    .store-dialog .el-checkbox {
        display: inline;
    }
    .video-html {
      width: 100%;
      height: 169px;
      border-bottom: 1px solid #e7e7eb;
    }
</style>
