<template>
  <el-dialog class="store-dialog" width='800px' title="选择店铺" :visible.sync="showDialog" :close-on-click-modal="false" :before-close="cancelAction">
    <div style="margin-bottom: 15px;">
      <el-row>
        <el-col :span="20" :push='1'>
          <el-input placeholder="输入店铺名称" v-model="form.storename" clearable class="input-with-select">
            <el-select slot="prepend" v-model="form.type" placeholder="请选择">
              <el-option label="店铺名称" :value="1"></el-option>
              <el-option label="联系人手机号" :value="2"></el-option>
            </el-select>
            <el-button slot="append" icon="el-icon-search" @click="handleIconClick"></el-button>
          </el-input>
        </el-col>
      </el-row>
    </div>
    <el-table ref="multipleTable" :data="storeData" tooltip-effect="dark" style="width: 100%" @select="handleSelectionChange" @select-all="selectAll" v-loading="loading" :row-key="getRowKeys">
      <el-table-column type="selection" :reserve-selection="true" width="50"></el-table-column>
      <el-table-column prop="store_name" label="门店名称"></el-table-column>
      <el-table-column prop="address" label="地址" show-overflow-tooltip></el-table-column>
    </el-table>
    <el-row justify='end' type='flex' v-if="total_count > params.pageSize" class="tr" style="margin-top:10px">
      <el-pagination
      background
      layout="prev, pager, next"
      @current-change="handleCurrentChange"
      :total="total_count" :page-size="pageLimit">
      </el-pagination>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancelAction">取 消</el-button>
      <el-button type="primary" @click="saveStoreAction">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getDistributorList, adapayMemberBind } from '@/api/marketing'
export default {
  props: {
    storeVisible: {
      type: Boolean,
      default: false
    },
    closeStoreDialog: {
      type: Function,
      default: null
    },
    getStatus: {
      type: Boolean,
      default: false
    },
    adapayMemberId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      loading: false,
      storeData: [],
      multipleSelection: [],
      pageLimit: 10,
      selectRows: [],
      total_count: '',
      params: {
        page: 1,
        pageSize: 10,
        adapay_member_id: 0
      },
      form: {
        storename: '',
        type: 1,
        mobile: ''
      }
    }
  },
  methods: {
    getRowKeys (row) {
      return row.distributor_id
    },
    handleIconClick () {
      if (this.form.type === 1) {
        this.params.name = this.form.storename
      } else {
        this.params.mobile = this.form.storename
      }
      this.getNewsList()
    },
    handleCurrentChange (page_num) {
      this.params.page = page_num
      this.getNewsList()
    },
    handleSelectionChange (val) {
      console.log(val)
      if (val.length > 0) {
        this.multipleSelection = val
        const newVal = this.selectRows.filter(item => {
          const isHaveRow = this.storeData.some(row => {
            if (item.distributor_id) {
              return item.distributor_id == row.distributor_id
            } else {
              return item == row.distributor_id
            }
          })
          return !isHaveRow
        })
        this.selectRows = [...newVal, ...val]
      } else {
        const list = this.selectRows.filter(row => {
          const isHave = this.storeData.some(item => {
            if (row.distributor_id) {
              return item.distributor_id == row.distributor_id
            } else {
              return item.distributor_id == row
            }
          })
          return !isHave
        })
        this.selectRows = list
      }
    },
      // 全选事件
    selectAll (val) {
      if (val.length > 0) {
        this.multipleSelection = val
        val.forEach(item => {
          let isInArr = -1
          if (this.selectRows.length > 0) {
            isInArr = this.selectRows.findIndex((n) => {
              if (n.distributor_id) {
                return n.distributor_id == item.distributor_id
              } else {
                return n.distributor_id == item.distributor_id
              }
            })
          }
          if (isInArr === -1) {
            this.selectRows.push(item)
          }
        })
      } else {
        const list = this.selectRows.filter(item => {
          const isHave = this.storeData.some(n => item.distributor_id === n.distributor_id)
          return !isHave
        })
        this.selectRows = list
      }
    },
    cancelAction () {
      this.$emit('closeStoreDialog')
    },
    saveStoreAction () {
      let ids = this.selectRows.map(item => item.distributor_id)
      console.log(JSON.stringify(ids), this.adapayMemberId)
      adapayMemberBind({ distributor_ids: JSON.stringify(ids), adapay_member_id: this.adapayMemberId }).then(response => {
        this.$emit('closeStoreDialog')
        this.$refs.multipleTable.clearSelection()
      })
    },
    getNewsList () {
      if (this.getStatus) {
        this.loading = true
        getDistributorList(this.params).then(response => {
          this.storeData = response.data.data.list
          this.total_count = parseInt(response.data.data.total_count)
          this.loading = false
          this.multipleSelection = []
          this.$refs.multipleTable.clearSelection()
          if (this.selectRows) {
            this.storeData.forEach(item => {
              console.log(this.storeData)
              const isHave = this.selectRows.findIndex((n) => {
                if (n.distributor_id) {
                  return n.distributor_id && n.distributor_id == item.distributor_id
                } else {
                  return n == item.distributor_id
                }
              })
              if (isHave !== -1) {
                setTimeout(() => {
                  this.$refs.multipleTable.toggleRowSelection(item)
                })
              }
            })
          }
        })
      }
    }
  },
  computed: {
    showDialog () {
      return this.storeVisible
    }
  },
  watch: {
    getStatus (newVal, oldVal) {
      if (newVal) {
        this.getNewsList()
      }
    }
  }
}
</script>

<style scoped type="text/css">
  .pagination-box {
    float: right;
    overflow: hidden;
    padding-top: 10px 0px;
  }
  .store-dialog .el-dialog__body {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .el-select .el-input {
    width: 140px;
  } 
  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }
</style>
