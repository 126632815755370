<template>
  <section v-if="name === 'blokScroll'" class="section">
    <div class="section-header with-border">设置</div>
    <div class="section-body">
      <el-form label-width="100px">
        <el-form-item label="标题">
          <el-input v-model="base.title" type="text"></el-input>
        </el-form-item>
        <el-form-item label="副标题">
          <el-input v-model="base.subtitle"></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input v-model="base.description"></el-input>
        </el-form-item>
        <el-form-item label="楼层">
          <el-input type="text" v-model="base.floor_var"></el-input>
        </el-form-item>
        <el-form-item label="组件间距">
          <el-switch
            v-model="base.padded"
            active-color="#27cc6a"
            inactive-color="#efefef"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="商品类型">
          <el-radio-group v-model="config.type" @change="changeType">
            <!--<el-radio :label="'goods'">单商品</el-radio>-->
            <!--<el-radio :label="'seckill'">秒杀活动</el-radio>-->
            <!--<el-radio :label="'limitTimeSale'">限时特惠</el-radio>-->
            <el-radio :label="'welfare'">福利</el-radio>
            <el-radio :label="'activity'">门店活动</el-radio>
            <el-radio :label="'service'">门店服务</el-radio>
            <el-radio :label="'selling'">热卖</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="config.type === 'goods'" label="开启排行榜">
          <el-switch
            v-model="config.leaderboard"
            active-color="#27cc6a"
            inactive-color="#efefef"
          >
          </el-switch>
        </el-form-item>
        <el-form-item v-if="config.type === 'selling'" label="显示价格">
          <el-switch
            v-model="config.showPrice"
            active-color="#27cc6a"
            inactive-color="#efefef"
          >
          </el-switch>
        </el-form-item>
        <el-form-item v-if="config.type === 'goods'" label="选择商品">
          <draggable
            v-model="items"
            class="content-bottom-padded"
            :options="dragItemsOptions"
            @end="onEnd"
          >
            <div
              class="setting-item item-selected"
              v-for="(item, index) in data"
              :key="index"
            >
              <img
                class="thumbnail"
                :src="wximageurl + item.imgUrl"
                alt="附近活动"
              />
              <div class="">{{ item.title }}</div>
              <div class="setting-remove" @click="removeItem(index)">
                <i class="iconfont icon-trash-alt"></i>
              </div>
            </div>
          </draggable>
          <el-button
            type="default"
            class="iconfont icon-cog banner-button-uploader"
            @click="setGoods"
          >
            设置商品</el-button
          >
        </el-form-item>

        <el-form-item v-if="config.type === 'selling'" label="热卖描述">
          <el-radio-group v-model="config.desc_type">
            <el-radio :label="'describe'">描述</el-radio>
            <el-radio :label="'coupon'">优惠价格</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item v-if="config.type === 'selling'" label="选择热卖商品">
          <draggable
            v-model="items"
            class="nearby-activity"
            :options="dragItemsOptions"
            @end="onEnd"
          >
            <el-card
              shadow="never"
              :body-style="{
                padding: '10px',
                alignItems: 'center',
              }"
              class="setting-item item-selected"
              v-for="(item, index) in data"
              :key="item.id"
            >
              <el-row :gutter="1">
                <el-col :span="1">
                  {{ item.id }}
                </el-col>
                <el-col :span="8">
                  标题:{{ item.activity_title }}
                </el-col>
                <el-col :span="8">
                  <div>
                    描述:{{ item.activity_desc }}
                  </div>
                </el-col>
                <el-col :span="2">
                  <span class="marginLeft20">
                  <el-button
                    type="primary"
                    size="mini"
                    @click="removeItem(index)"
                  >删除</el-button
                  >
                </span>
                </el-col>
              </el-row>
<!--              <div class="activity-content">
                {{ item.activity_title }}
                <span class="marginLeft20">
                  <el-button
                    type="primary"
                    size="mini"
                    @click="removeItem(index)"
                    >删除</el-button
                  >
                </span>
              </div>-->
            </el-card>
          </draggable>
          <el-button
            type="default"
            class="iconfont icon-cog banner-button-uploader"
            @click="setSelling"
          >
            设置热卖商品</el-button
          >
        </el-form-item>

        <el-form-item v-if="config.type === 'welfare'" label="选择福利">
          <draggable
            v-model="items"
            class="nearby-activity"
            :options="dragItemsOptions"
            @end="onEnd"
          >
            <el-card
              shadow="never"
              :body-style="{
                padding: '10px',
                display: 'flex',
                alignItems: 'center',
              }"
              class="setting-item item-selected"
              v-for="(item, index) in data"
              :key="index"
            >
              <img
                class="thumbnail"
                :src="wximageurl + item.activity_cover"
                alt="福利"
              />
              <div class="activity-content">
                <p>{{ item.activity_title }}</p>
                <p>
                  <el-button
                    type="primary"
                    size="mini"
                    @click="removeItem(index)"
                    >删除</el-button
                  >
                </p>
              </div>
            </el-card>
          </draggable>
          <el-button
            type="default"
            class="iconfont icon-cog banner-button-uploader"
            @click="setWelfare"
          >
            设置福利</el-button
          >
        </el-form-item>
        <el-form-item v-if="config.type === 'activity'" label="选择门店活动">
          <draggable
            v-model="items"
            class="nearby-activity"
            :options="dragItemsOptions"
            @end="onEnd"
          >
            <el-card
              shadow="never"
              :body-style="{
                padding: '10px',
                display: 'flex',
                alignItems: 'center',
              }"
              class="setting-item item-selected"
              v-for="(item, index) in data"
              :key="index"
            >
              <img
                class="thumbnail"
                :src="wximageurl + item.activity_cover"
                @click="handleImgChange(index)"
                alt="活动"
              />
              <div class="activity-content">
                <p>{{ item.activity_title }}</p>
                <p>
                  <el-button
                    type="primary"
                    size="mini"
                    @click="removeItem(index)"
                    >删除</el-button
                  >
                </p>
              </div>
            </el-card>
          </draggable>
          <el-button
            type="default"
            class="iconfont icon-cog banner-button-uploader"
            @click="setActivity"
          >
            设置活动</el-button
          >
        </el-form-item>
        <el-form-item v-if="config.type === 'service'" label="选择门店服务">
          <draggable
            v-model="items"
            class="nearby-activity"
            :options="dragItemsOptions"
            @end="onEnd"
          >
            <el-card
              shadow="never"
              :body-style="{
                padding: '10px',
                display: 'flex',
                alignItems: 'center',
              }"
              class="setting-item item-selected"
              v-for="(item, index) in data"
              :key="index"
            >
              <img
                class="thumbnail"
                :src="wximageurl + item.activity_cover"
                alt="服务"
              />
              <div class="activity-content">
                <p>{{ item.activity_title }}</p>
                <p>
                  <el-button
                    type="primary"
                    size="mini"
                    @click="removeItem(index)"
                    >删除</el-button
                  >
                </p>
              </div>
            </el-card>
          </draggable>
          <el-button
            type="default"
            class="iconfont icon-cog banner-button-uploader"
            @click="setService"
          >
            设置服务</el-button
          >
        </el-form-item>
        <el-form-item v-if="config.type === 'seckill'" label="选择活动">
          <el-select
            v-model="config.seckillId"
            placeholder="请选择"
            @change="selectSeckill"
          >
            <el-option
              v-for="(item, index) in seckill"
              :key="item.seckill_id"
              :label="item.activity_name"
              :value="item.seckill_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="config.type === 'limitTimeSale'" label="选择活动">
          <el-select
            v-model="config.seckillId"
            placeholder="请选择"
            @change="selectLimitTimeSale"
          >
            <el-option
              v-for="(item, index) in limitTimeSale"
              :key="item.seckill_id"
              :label="item.activity_name"
              :value="item.seckill_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!--<el-form-item v-if="config.moreLink" label="设置更多链接">
          <div class="goods-select" @click="handleMoreLink">
            <div class="link-content" v-if="config.moreLink.title">
              <template v-if="config.moreLink.linkPage === 'goods'">商品：</template>
              <template v-if="config.moreLink.linkPage === 'category'">分类：</template>
              <template v-if="config.moreLink.linkPage === 'article'">文章：</template>
              <template v-if="config.moreLink.linkPage === 'planting'">软文：</template>
              <template v-if="config.moreLink.linkPage === 'link'">页面：</template>
              <template v-if="config.moreLink.linkPage === 'marketing'">营销：</template>
              <template v-if="config.moreLink.linkPage === 'custom_page'">自定义页面：</template>
              {{config.moreLink.title}}
            </div>
            <div v-else class="content-center"><i class="iconfont icon-link"></i>设置路径</div>
          </div>
          <template>
            <el-form-item label="">
              <img v-if="base.backgroundImg" :src="wximageurl + base.backgroundImg" class="banner-uploader" @click="handleImgChange('backImg')" />
              <div class="banner-uploader" v-else @click="handleImgChange('backImg')">
                <i class="iconfont icon-camera"></i>
                上传图片
              </div>
            </el-form-item>
          </template>
        </el-form-item>-->
      </el-form>
    </div>
  </section>
</template>

<script>
import draggable from "vuedraggable";
import { seckillActivityGetList } from "@/api/promotions";

export default {
  props: {
    res: {
      type: Object,
      default: {},
    },
  },
  components: {
    draggable
  },
  watch: {
    res: {
      deep: true,
      handler(value) {
        if (value || value.name === "navigation") {
          this.setData(value);
          if (!value.config) return;
          if (value.config.type === "seckill" && this.seckill.length === 0) {
            seckillActivityGetList({
              page: 1,
              pageSize: 20,
              status: "valid",
            }).then((response) => {
              this.seckill = response.data.data.list;
            });
          }
          if (
            value.config.type === "limitTimeSale" &&
            this.limitTimeSale.length === 0
          ) {
            seckillActivityGetList({
              page: 1,
              pageSize: 20,
              status: "valid",
              seckill_type: "limited_time_sale",
            }).then((response) => {
              this.limitTimeSale = response.data.data.list;
            });
          }
        }
      },
    },
  },
  data() {
    return {
      name: "",
      base: {},
      config: {},
      data: [],
      seckill: [],
      limitTimeSale: [],
      items: [],
      temp: "",
      dragItemsOptions: {
        animation: 300,
        forceFallback: false,
        scroll: true,
      },
    };
  },
  methods: {
    setData(val) {
      this.name = val.name;
      this.base = val.base;
      this.config = val.config;
      this.data = val.data;
      this.items = val.data;
    },
    changeType(data) {
      if (data !== "goods") {
        this.config.leaderboard = false;
      } else {
        this.seckill = [];
        this.limitTimeSale = [];
      }
      this.data.splice(0);
      this.config.seckillId = "";
      this.config.type = data;
    },
    selectLimitTimeSale(data) {
      this.config.seckillId = data;
    },
    selectSeckill(data) {
      this.config.seckillId = data;
    },
    removeItem(index) {
      this.data.splice(index, 1);
    },
    setGoods() {
      this.$emit("bindGoods");
    },
    setActivity() {
      this.$emit("bindActivity");
    },
    setWelfare() {
      this.$emit("bindWelfare");
    },
    setSelling() {
      this.$emit("bindSelling");
    },
    setService() {
      this.$emit("bindService");
    },
    onEnd(evt) {
      this.temp = this.data[evt.oldIndex];
      this.data.splice(evt.oldIndex, 1);
      this.data.splice(evt.newIndex, 0, this.temp);
    },
    handleMoreLink() {
      this.$emit("bindLinks");
    },
    handleImgChange(index) {
      this.$emit("bindImgs", index);
    },
  },
  mounted() {
    this.setData(this.res);
  },
};
</script>

<style scoped lang="scss">
.section-body {
  ::v-deep .nearby-activity {
    margin-bottom: 10px;
    .setting-item {
      ::v-deep.el-card__body {
        display: flex;
        .thumbnail {
          cursor: pointer;
          margin-right: 20px;
        }
        .activity-content {
          .sd,
          .ed {
            font-size: 12px;
          }
          span {
            margin-right: 10px;
          }
        }
      }
    }
  }
}
.marginLeft20 {
  margin-left: 20px;
}
.icon-uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  color: #dadadd;
  border: 1px dashed #ddd;
  cursor: pointer;
  line-height: 1.3;
  margin-right: 10px;
  .icon-sm-text {
    font-size: 11px;
    margin-top: 6px;
  }
}
</style>

