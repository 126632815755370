var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"view-flex"},[(_vm.checked.media_id)?_c('div',{staticClass:"video-box"},[_c('video-player',{staticClass:"vjs-custom-skin",attrs:{"options":{
        preload: 'auto',
        aspectRatio: '16:9',
        fluid: true,
        sources: [{
          // mp4
          type: 'video/mp4',
          src: _vm.checked.url
        }],
        notSupportedMessage: '此视频暂无法播放，请稍后再试',
        controlBar: false
      }}})],1):_vm._e(),(!_vm.checked.media_id||(_vm.checked.media_id&&_vm.multiple))?_c('div',{staticClass:"upload-box",on:{"click":_vm.showVideos}},[_c('i',{staticClass:"iconfont icon-video"})]):_vm._e()]),_c('el-dialog',{staticClass:"video_dialog",attrs:{"title":"选择视频","visible":_vm.visible,"append-to-body":""},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('el-tabs',{on:{"tab-click":_vm.handleClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[(_vm.$store.getters.login_type != 'distributor')?_c('el-tab-pane',{attrs:{"label":"微信上传","name":"wechatvideos"}},[_c('div',[_c('el-row',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"gutter":10}},_vm._l((_vm.videoList),function(item,index){return _c('el-col',{key:index,staticClass:"media-item",class:{'checked' : _vm.temp.media_id === item.media_id},attrs:{"span":6}},[_c('video-player',{staticClass:"vjs-custom-skin",attrs:{"options":{
            preload: 'auto',
            aspectRatio: '16:9',
            fluid: true,
            sources: [{
              // mp4
              type: 'video/mp4',
              src: item.url
            }],
            notSupportedMessage: '此视频暂无法播放，请稍后再试',
            controlBar: false
          }}}),_c('div',{staticClass:"video-caption view-flex view-flex-middle",on:{"click":function($event){return _vm.checkVideo(item)}}},[_c('div',{staticClass:"view-flex-item video-name"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"check-btn iconfont icon-check"})])],1)}),1),_c('el-pagination',{attrs:{"background":"","layout":"total, sizes, prev, pager, next","current-page":_vm.params.page,"page-sizes":[10,20,50],"total":_vm.total_count,"page-size":_vm.params.pageSize},on:{"current-change":_vm.handleCurrentChange,"size-change":_vm.handleSizeChange,"update:currentPage":function($event){return _vm.$set(_vm.params, "page", $event)},"update:current-page":function($event){return _vm.$set(_vm.params, "page", $event)}}})],1)]):_vm._e(),_c('el-tab-pane',{attrs:{"label":"本地上传","name":"localvideos"}},[_c('div',[_c('el-row',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.localloading),expression:"localloading"}],attrs:{"gutter":10}},_vm._l((_vm.localvideoList),function(item,index){return _c('el-col',{key:index,staticClass:"media-item",class:{'checked' : _vm.temp.media_id === item.url},attrs:{"span":6}},[_c('video-player',{staticClass:"vjs-custom-skin",attrs:{"options":{
            preload: 'auto',
            aspectRatio: '16:9',
            fluid: true,
            sources: [{
              // mp4
              type: 'video/mp4',
              src: item.url
            }],
            notSupportedMessage: '此视频暂无法播放，请稍后再试',
            controlBar: false
          }}}),_c('div',{staticClass:"video-caption view-flex view-flex-middle",on:{"click":function($event){return _vm.checkLocalVideo(item)}}},[_c('div',{staticClass:"view-flex-item video-name"},[_vm._v(_vm._s(item.image_name))]),_c('div',{staticClass:"check-btn iconfont icon-check"})])],1)}),1),_c('el-pagination',{attrs:{"background":"","layout":"total, sizes, prev, pager, next","current-page":_vm.params.page,"page-sizes":[10,20,50],"total":_vm.total_count,"page-size":_vm.params.pageSize},on:{"current-change":_vm.handleLocalCurrentChange,"size-change":_vm.handleLocalSizeChange,"update:currentPage":function($event){return _vm.$set(_vm.params, "page", $event)},"update:current-page":function($event){return _vm.$set(_vm.params, "page", $event)}}})],1)])],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.handleCancel}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v("确 定")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }