import { render, staticRenderFns } from "./newcoupon.vue?vue&type=template&id=8b486a60&scoped=true"
import script from "./newcoupon.vue?vue&type=script&lang=js"
export * from "./newcoupon.vue?vue&type=script&lang=js"
import style0 from "./newcoupon.vue?vue&type=style&index=0&id=8b486a60&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b486a60",
  null
  
)

export default component.exports