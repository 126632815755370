<template>
  <section v-if="name === 'stickyLift'" class="section">
    <div class="section-header with-border">设置</div>
    <div class="current-form">
      <el-form>
        <el-form-item label="标题">
          <el-input v-model="base.title" type="text"></el-input>
        </el-form-item>
        <el-form-item label="副标题">
          <el-input v-model="base.subtitle"></el-input>
        </el-form-item>
        <el-form-item label="tab底色">
          <el-color-picker show-alpha v-model="base.bg_color"></el-color-picker>
        </el-form-item>
        <el-form-item label="tab默认色">
          <el-color-picker show-alpha v-model="base.default_color"></el-color-picker>
        </el-form-item>
        <el-form-item label="tab默认字体色">
          <el-color-picker show-alpha v-model="base.default_color_font"></el-color-picker>
        </el-form-item>
        <el-form-item label="tab选中色">
          <el-color-picker show-alpha v-model="base.checked_color"></el-color-picker>
        </el-form-item>
        <el-form-item label="tab选中字体色">
          <el-color-picker show-alpha v-model="base.checked_color_font"></el-color-picker>
        </el-form-item>
        <el-form-item label="楼层">
          <el-input type="text" v-model="base.floor_var"></el-input>
        </el-form-item>
        <el-form-item label="组件间距">
          <el-switch
            v-model="base.padded"
            active-color="#27cc6a"
            inactive-color="#efefef"
          >
          </el-switch>
        </el-form-item>
      </el-form>
      <div class="config-list">
        <div class="form-item" v-for="(item, index) of data" :key="index">
          <div class="group-items">
            <div class="float-l">
              <div class="input-group">
                <div class="title-box">
                  <el-input
                    placeholder="请输入标题"
                    v-model="item.title"
                    style="width:70%"
                  ></el-input>
                  <el-button
                    circle
                    @click="handleDelConfig(index)"
                    icon="el-icon-minus"
                  ></el-button>
                </div>
                <div style="margin-top:5px">
                  <div>长热区</div>
                  <div class="setting-item slider">
                    <img
                      v-if="item.longImgUrl"
                      :src="wximageurl + item.longImgUrl"
                      class="banner-uploader"
                      @click="handleImgChange(index, 'long')"
                    />
                    <div
                      class="banner-uploader"
                      v-else
                      @click="handleImgChange(index, 'long')"
                    >
                      <i class="iconfont icon-camera"></i>上传图片
                    </div>
                    <el-button
                      circle
                      @click="handleDelConfig(index, 'long')"
                      icon="el-icon-minus"
                    ></el-button>
                  </div>
                  <hotzone
                    class="hotzone"
                    :image="item.longImgUrl || ''"
                    :zonesInit="item.longData"
                    @add="handleAdd(index, 'long')"
                    @change="(event) => handleChange(event, index, 'long')"
                    @remove="(event) => handleRemove(event, index, 'long')"
                  ></hotzone>
                  <div
                    class="setting-item slider"
                    v-for="(zoneItem, zoneIndex) in item.longData"
                    :key="zoneIndex"
                  >
                    <div class="uploader-setting">
                      <div
                        class="goods-select"
                        @click="handleGoodsChange(index, zoneIndex, 'long')"
                      >
                        <div class="link-content" v-if="zoneItem.id">
                          <template v-if="zoneItem.linkPage === 'goods'"
                            >商品：</template
                          >
                          <template v-if="zoneItem.linkPage === 'category'"
                            >分类：</template
                          >
                          <template v-if="zoneItem.linkPage === 'article'"
                            >文章：</template
                          >
                          <template v-if="zoneItem.linkPage === 'planting'"
                            >软文：</template
                          >
                          <template v-if="zoneItem.linkPage === 'link'"
                            >页面：</template
                          >
                          <template v-if="zoneItem.linkPage === 'marketing'"
                            >营销：</template
                          >
                          <template v-if="zoneItem.linkPage === 'custom_page'"
                            >自定义页面：</template
                          >
                          <template v-if="zoneItem.linkPage === 'redpackage'"
                            >红包封面：</template
                          >
                          {{ zoneItem.title }} :
                          {{ zoneItem.ctoken || zoneItem.id }}
                        </div>
                        <div v-else class="content-center">
                          <i
                            class="iconfont icon-link"
                            @click="handleGoodsChange(index, zoneIndex, 'long')"
                          ></i
                          >设置路径
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div>短热区</div>
                  <div class="setting-item slider">
                    <img
                      v-if="item.imgUrl"
                      :src="wximageurl + item.imgUrl"
                      class="banner-uploader"
                      @click="handleImgChange(index, 'short')"
                    />
                    <div
                      class="banner-uploader"
                      v-else
                      @click="handleImgChange(index, 'short')"
                    >
                      <i class="iconfont icon-camera"></i>上传图片
                    </div>
                    <el-button
                    circle
                    @click="handleDelConfig(index, 'short')"
                    icon="el-icon-minus"
                  ></el-button>
                  </div>
                  <hotzone
                    class="hotzone"
                    :image="item.imgUrl"
                    :zonesInit="item.data"
                    @add="handleAdd(index, 'short')"
                    @change="(event) => handleChange(event, index, 'short')"
                    @remove="(event) => handleRemove(event, index, 'short')"
                  ></hotzone>
                  <div
                    class="setting-item slider"
                    v-for="(zoneItem, zoneIndex) in item.data"
                    :key="zoneIndex"
                  >
                    <div class="uploader-setting">
                      <div
                        class="goods-select"
                        @click="handleGoodsChange(index, zoneIndex, 'short')"
                      >
                        <div class="link-content" v-if="zoneItem.id">
                          <template v-if="zoneItem.linkPage === 'goods'"
                            >商品：</template
                          >
                          <template v-if="zoneItem.linkPage === 'category'"
                            >分类：</template
                          >
                          <template v-if="zoneItem.linkPage === 'article'"
                            >文章：</template
                          >
                          <template v-if="zoneItem.linkPage === 'planting'"
                            >软文：</template
                          >
                          <template v-if="zoneItem.linkPage === 'link'"
                            >页面：</template
                          >
                          <template v-if="zoneItem.linkPage === 'marketing'"
                            >营销：</template
                          >
                          <template v-if="zoneItem.linkPage === 'custom_page'"
                            >自定义页面：</template
                          >
                          <template v-if="zoneItem.linkPage === 'redpackage'"
                            >红包封面：</template
                          >
                          {{ zoneItem.title }} :
                          {{ zoneItem.ctoken || zoneItem.id }}
                        </div>
                        <div v-else class="content-center">
                          <i
                            class="iconfont icon-link"
                            @click="handleGoodsChange(index, zoneIndex, 'short')"
                          ></i
                          >设置路径
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div>短热区</div>
                  <div class="setting-item slider">
                    <img
                      v-if="item.shortBotImgUrl"
                      :src="wximageurl + item.shortBotImgUrl"
                      class="banner-uploader"
                      @click="handleImgChange(index, 'shortBot')"
                    />
                    <div
                      class="banner-uploader"
                      v-else
                      @click="handleImgChange(index, 'shortBot')"
                    >
                      <i class="iconfont icon-camera"></i>上传图片
                    </div>
                    <el-button
                    circle
                    @click="handleDelConfig(index, 'shortBot')"
                    icon="el-icon-minus"
                  ></el-button>
                  </div>
                  <hotzone
                    class="hotzone"
                    :image="item.shortBotImgUrl || ''"
                    :zonesInit="item.shortBotData"
                    @add="handleAdd(index, 'shortBot')"
                    @change="(event) => handleChange(event, index, 'shortBot')"
                    @remove="(event) => handleRemove(event, index, 'shortBot')"
                  ></hotzone>
                  <div
                    class="setting-item slider"
                    v-for="(zoneItem, zoneIndex) in item.shortBotData"
                    :key="zoneIndex"
                  >
                    <div class="uploader-setting">
                      <div
                        class="goods-select"
                        @click="handleGoodsChange(index, zoneIndex, 'shortBot')"
                      >
                        <div class="link-content" v-if="zoneItem.id">
                          <template v-if="zoneItem.linkPage === 'goods'"
                            >商品：</template
                          >
                          <template v-if="zoneItem.linkPage === 'category'"
                            >分类：</template
                          >
                          <template v-if="zoneItem.linkPage === 'article'"
                            >文章：</template
                          >
                          <template v-if="zoneItem.linkPage === 'planting'"
                            >软文：</template
                          >
                          <template v-if="zoneItem.linkPage === 'link'"
                            >页面：</template
                          >
                          <template v-if="zoneItem.linkPage === 'marketing'"
                            >营销：</template
                          >
                          <template v-if="zoneItem.linkPage === 'custom_page'"
                            >自定义页面：</template
                          >
                          <template v-if="zoneItem.linkPage === 'redpackage'"
                            >红包封面：</template
                          >
                          {{ zoneItem.title }} :
                          {{ zoneItem.ctoken || zoneItem.id }}
                        </div>
                        <div v-else class="content-center">
                          <i
                            class="iconfont icon-link"
                            @click="handleGoodsChange(index, zoneIndex, 'shortBot')"
                          ></i
                          >设置路径
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-button @click="handleAddConfig">添加</el-button>
    </div>
  </section>
</template>

<script>
import hotzone from 'vue-hotzone'

export default {
  props: {
    res: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      name: '',
      base: {},
      config: {},
      data: []
    }
  },
  watch: {
    res: {
      deep: true,
      handler (value) {
        this.setData(value)
      }
    }
  },
  components: {
    hotzone: hotzone
  },
  methods: {
    handleImgChange (index, type) {
      this.$emit('bindImgs', index, '', type)
    },
    handleGoodsChange (index, dataIndex, type) {
      this.$emit('bindLinks', index, dataIndex, type)
    },
    handleAdd (index, type) {
      let item = {
        linkPage: '',
        title: '',
        id: '',
        card_type: '',
        goods_id: ''
      }
      console.log(this.data, index, '---data---')
      if (type == 'long') {
        this.data[index].longData.push(item)
      } else if (type == 'shortBot') {
        this.data[index].shortBotData.push(item)
      } else {
        this.data[index].data.push(item)
      }
    },
    handleChange (zone, dataIndex, type) {
      setTimeout(() => {
        zone.forEach((item, index) => {
          if (item.leftPer) {
            let obj = {
              heightPer: item.heightPer,
              leftPer: item.leftPer,
              topPer: item.topPer,
              widthPer: item.widthPer
            }
            if (type == 'long') {
              Object.assign(this.data[dataIndex].longData[index], obj)
            } else if (type == 'shortBot') {
              Object.assign(this.data[dataIndex].shortBotData[index], obj)
            } else {
              Object.assign(this.data[dataIndex].data[index], obj)
            }
          }
        })
      }, 500)
    },
    handleRemove (index, dataIndex, type) {
      if (type == 'long') {
        this.data[dataIndex].longData.splice(index, 1)
      } else if (type == 'shortBot') {
        this.data[dataIndex].shortBotData.splice(index, 1)
        } else {
        this.data[dataIndex].data.splice(index, 1)
      }
    },
    handleAddConfig () {
      this.data.push({
        title: '',
        imgUrl: '',
        data: [],
        longImgUrl: '',
        longData: [],
        shortBotImgUrl: '',
        shortBotData: [],
      })
    },
    handleDelConfig (index, type) {
      if (type === 'long') {
        this.data[index].longImgUrl = ''
        this.data[index].longData = []
      }else if (type === 'short') {
        this.data[index].imgUrl = ''
        this.data[index].data = []
      } else if (type === 'shortBot') {
        this.data[index].shortBotImgUrl = ''
        this.data[index].shortBotData = []
      } else {
        this.data.splice(index, 1)
      }
    },
    setData (val) {
      this.name = val.name
      this.base = val.base
      this.data = val.data
    }
  },
  mounted () {
    this.setData(this.res)
  }
}
</script>

<style lang="scss" scoped>
.section {
  .section-header {
    position: static;
  }
  .current-form {
    padding: 30px;
    .config-list {
      .form-item {
        display: flex;
        align-items: flex-start;
        .group-items {
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          flex: 1;
          .float-l {
            flex: 1;
            margin-right: 10px;
            .input-group {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}
.title-box {
  display: flex;
  justify-content:space-between;
  align-items: center;
}
.hotzone {
  max-width: 1100px;
  margin-bottom: 10px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
}
.uploader-setting {
  padding: 0;
}
.setting-item {
  justify-content: space-between;
  align-items: center;
  .el-button {
    width: 40px;
    height: 40px;
  }
}
</style>
