<template>
  <div class="component-wrap" :class="{ active: active }">
    <div class="current-active"></div>
    <div v-if="base.title" class="component-header">{{ base.title }}</div>
    <ul class="list">
      <li class="item" v-for="(item, index) of data" :key="index">
        <div class="poster"><img :src="item.imgUrl" alt="" /></div>
        <div class="wrap">
          <div class="title">{{ item.title }}</div>
          <div class="subtitle">{{ item.subtitle }}</div>
          <div class="link">{{ item.moreLink.name || '' }}</div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    res: {
      type: Object,
      default: {}
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      base: {},
      data: []
    }
  },
  methods: {
    setData (val) {
      this.base = val.base
      this.data = val.data
    }
  },
  mounted () {
    this.setData(this.res)
  }
}
</script>

<style lang="scss" scoped>
.component-wrap {
  width: 320px;
  margin: 0 auto;
  padding: 0 10px;
  .component-header {
    font-size: 18px;
    font-weight: bold;
    padding: 0;
    margin-bottom: 5px;
  }
  .list {
    padding-bottom: 5px;
    .item {
      display: flex;
      align-items: center;
      border-radius: 10px;
      overflow: hidden;
      padding: 10px;
      margin-bottom: 5px;
      background-color: #fff;
      border: 1px solid #f8f8f8;
      .poster {
        min-width: 70px;
        max-width: 70px;
        height: 70px;
        overflow: hidden;
        background-color: #fff;
        margin-right: 10px;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      .wrap {
        .title {
          font-size: 16px;
          font-weight: bold;
          color: #333;
          width: 200px;
          @include text-overflow();
        }
        .subtitle {
          font-size: 12px;
          color: #999;
          width: 200px;
          @include multi-ellipsis(2);
        }
        .link {
          font-size: 14px;
          color: #007AFF;
          width: 200px;
          @include text-overflow();
        }
      }
    }
    .item:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
