<template>
  <section v-if="name === 'aroundStore'" class="section">
    <div class="section-header with-border">设置</div>
    <div class="current-form">
      <el-form>
        <el-form-item label="标题">
          <el-input v-model="base.title" type="text"></el-input>
        </el-form-item>
        <el-form-item label="楼层">
          <el-input type="text" v-model="base.floor_var"></el-input>
        </el-form-item>
        <el-form-item label="组件间距">
          <el-switch
            v-model="base.padded"
            active-color="#27cc6a"
            inactive-color="#efefef"
          >
          </el-switch>
        </el-form-item>
      </el-form>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    res: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      name: "",
      base: {},
      data: [],
    };
  },
  watch: {
    res: {
      deep: true,
      handler(value) {
        this.setData(value);
      },
    },
  },
  methods: {
    setData(val) {
      console.log(val);
      this.name = val.name;
      this.base = val.base;
      this.data = val.data;
    },
  },
  mounted() {
    this.setData(this.res);
  },
};
</script>

<style lang="scss" scoped>
.section {
  .section-header {
    position: static;
  }
  .current-form {
    padding: 30px;
  }
}
</style>
