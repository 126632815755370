var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loading)?_c('div',{staticClass:"picker-coupon"},[_c('SpFinder',{ref:"finder",attrs:{"other-config":{
  'max-height': 460,
},"row-key":"id","data":_vm.categoryList,"setting":{
  columns: [
    { name: 'ID', key: 'id', width: '100px' },
    { name: '分类名称', key: 'category_name' }
  ]
}},on:{"select":_vm.onSelect,"selection-change":_vm.onSelectionChange}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }