var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"picker-coupon"},[_c('SpFilterForm',{attrs:{"model":_vm.formData,"size":"small"},on:{"onSearch":_vm.onSearch,"onReset":_vm.onSearch}},[_c('SpFilterFormItem',{attrs:{"prop":"promotion_name"}},[_c('el-input',{attrs:{"placeholder":"请输入营销名称"},model:{value:(_vm.formData.promotion_name),callback:function ($$v) {_vm.$set(_vm.formData, "promotion_name", $$v)},expression:"formData.promotion_name"}})],1)],1),_c('SpFinder',{ref:"finder",attrs:{"reserve-selection":"","row-key":"id","other-config":{
    'max-height': 460,
    'header-cell-class-name': _vm.cellClass
  },"url":"/navigation/promotion/list","fixed-row-action":true,"show-pager-text":`已选中：${_vm.localSelection.length}`,"setting":_vm.setting,"hooks":{
      beforeSearch: _vm.beforeSearch,
      afterSearch: _vm.afterSearch
    },"selectable":_vm.selectable},on:{"select":_vm.onSelect,"select-all":_vm.handleSelectAll}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }