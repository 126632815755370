<style type="text/css" lang="scss">
.tip-info {
  padding: 8px 16px;
  background-color: #fff6f7;
  border-radius: 4px;
  border-left: 5px solid #ff7800;
  margin: 11px 11px 11px 11px;
  margin-top: 20px;
}
</style>
<template>
  <div style="display: inline-block">
    <el-button :type='buttonType ? buttonType : "primary"' :size='buttonSize ? buttonSize : ""' @click="visible = true" :id="buttonId?buttonId:'id_no'">{{buttonName ? buttonName : '导入'}}</el-button>
    <el-dialog
      :title=title
      :visible.sync="visible"
      width="80%"
      :before-close="handleClose"
      append-to-body
    >
      <div class="tip-info">
        <p>
          上传文件如果有处理失败的行数后将会生成错误文件，请及时查看错误信息修改后重新下载，错误描述文件只保留<strong>15天</strong>。
        </p>
        <p>超过<strong>15天</strong>的错误描述文件将会删除，不再提供下载查看</p>
      </div>
      <el-form ref="form" label-width="100px">
        <div class="content-bottom-padded">
          <el-upload
            class="fl"
            style="margin-right: 10px"
            action=""
            :on-change="uploadHandleChange"
            :auto-upload="false"
            :show-file-list="false"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
          <el-button size="small" @click="uploadHandleTemplate()" type="primary"
          >下载模版</el-button
          >
          <el-button v-if="showExportCity" style="margin-left: 10px" type="primary" size="small" @click="exportCityData">下载城市ID对照表</el-button>
        </div>
        <el-table
          :data="uploadList"
          v-loading="loading"
          :height="wheight - 220"
          element-loading-text="数据加载中"
        >
          <el-table-column
            prop="file_name"
            label="上传文件"
            min-width="100"
          ></el-table-column>
          <el-table-column
            prop="created_date"
            label="上传时间"
            min-width="80"
          ></el-table-column>
          <el-table-column
            prop="file_size_format"
            label="文件大小"
            min-width="60"
          ></el-table-column>
          <el-table-column label="处理状态" min-width="50">
            <template slot-scope="scope">
              <span v-if="scope.row.handle_status == 'wait'">等待处理</span>
              <span v-if="scope.row.handle_status == 'processing'">处理中</span>
              <span v-if="scope.row.handle_status == 'finish'">处理完成</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="finish_date"
            label="处理完成时间"
          ></el-table-column>
          <el-table-column label="待处理" width="80%">
            <template slot-scope="scope">
              <span v-if="scope.row.handle_message && scope.row.handle_message.waitLine > 0"
              >{{ scope.row.handle_message.waitLine }}行</span
              >
              <a
                v-if="scope.row.show_retry"
                type="primary"
                @click="retryWaitUpload(scope.row.id, scope.row.file_type)"
              >重试</a>
            </template>
          </el-table-column>
          <el-table-column label="处理成功" width="80%">
            <template slot-scope="scope">
              <span v-if="scope.row.handle_message"
              >{{ scope.row.handle_message.successLine }}行</span
              >
            </template>

          </el-table-column>
<!--          <el-table-column label="进 度" width="120%" >-->
<!--            <template slot-scope="scope">-->
<!--              <el-progress v-if="scope.row.par_finish >= 0 "  :text-inside="true" :stroke-width="20"  width="20%" :percentage="scope.row.par_finish"></el-progress>-->

<!--              <el-progress v-if="scope.row.par_fail > 0 "  :text-inside="true" :stroke-width="20" color="red" width="20%" :percentage="scope.row.par_fail"> </el-progress>-->

<!--            </template>-->

<!--          </el-table-column>-->
          <el-table-column label="处理失败" width="80%">
            <template slot-scope="scope">
              <span v-if="scope.row.isopcansale_fail_item_num"
              >店铺权限问题 {{ scope.row.isopcansale_fail_item_num }}行 (共{{scope.row.isopcansale_fail_store_count}}店铺)
                </span>
              <span v-if="scope.row.isopcansale_fail_store">
                </br>
                <!-- 店铺AppleId: {{ scope.row.isopcansale_fail_store }} -->

                <el-collapse>
                  <el-collapse-item title="展开查看" name="1" >
                    {{scope.row.isopcansale_fail_store}}
                  </el-collapse-item>

                </el-collapse>
              </span>



              <span v-if="scope.row.handle_message && scope.row.handle_message.errorLine > 0"
              >{{ scope.row.handle_message.errorLine }}行</span
              >
<!--              <a-->
<!--                v-if="-->
<!--                  scope.row.handle_message &&-->
<!--                  scope.row.handle_message.errorLine > 0-->
<!--                "-->
<!--                type="primary"-->
<!--                @click="exportErrorFile(scope.row.id, scope.row.file_type)"-->
<!--              >下载错误详情</a-->
<!--              >-->
            </template>
          </el-table-column>
          <el-table-column label="导入结果" width="120%">
            <template slot-scope="scope">
              <a
                type="primary"
                @click="exportErrorFile(scope.row.id, scope.row.file_type)"
              >下载导入结果</a>
            </template>

          </el-table-column>
        </el-table>
        <div
          v-if="total_count > pageSize"
          class="content-top-padded content-center"
        >
          <el-pagination
            @current-change="handleCurrentChange"
            layout="total, prev, pager, next"
            :total="total_count"
            :page-size="pageSize"
          >
          </el-pagination>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
  handleUploadFile,
  getUploadLists,
  exportUploadErrorFile,
  exportUploadTemplate,
  retryWaitUpload
} from "@/api/common";
import { exportCityData } from '@/api/company'
import { ExportLogFileDown } from '@/api/trade'

export default {
  props: ["fileName", "fileType", "buttonName","buttonId", "buttonType","buttonSize", 'relId', 'title', 'showExportCity'],
  data() {
    return {
      visible: false,
      loading: false,
      total_count: 0,
      pageSize: 20,
      page: 1,
      uploadList: [],
      // buttonName: '导入',
    };
  },
  computed: {
    ...mapGetters(["wheight"]),
  },
  watch: {
    visible (val, oldVal) {
      if(val) {
        this.page = 1
        this.uploadList = []
        this.getUploadList()
      }
    }
  },
  methods: {
    uploadHandleChange(file, fileList) {
      let params = {
        isUploadFile: true,
        file_type: this.fileType,
        file: file.raw,
        rel_id: this.relId || 0
      };
      handleUploadFile(params).then((response) => {
        this.$message({
          type: "success",
          message: "上传成功，等待处理",
        });
        this.getUploadList();
      });
    },
    uploadHandleTemplate() {
      let params = { file_type: this.fileType, file_name: this.fileName, rel_id: this.relId };
      exportUploadTemplate(params).then((response) => {
        if (response.data.data.file) {
          var a = document.createElement("a");
          a.href = response.data.data.file;
          a.download = response.data.data.name;
          document.body.appendChild(a);
          a.click();
          a.remove();
        } else {
          this.$message({
            type: "error",
            message: "没有相关数据可导出",
          });
        }
      });
    },
    exportErrorFile(id, fileType) {
      let params = { file_type: fileType };
      exportUploadErrorFile(id, params).then((response) => {
        if (response.data.data.file) {
          var a = document.createElement("a");
          a.href = response.data.data.file;
          a.download = response.data.data.name;
          document.body.appendChild(a);
          a.click();
          a.remove();
        } else {
          this.$message({
            type: "error",
            message: "没有相关数据可导出",
          });
        }
      });
    },
    retryWaitUpload(id, fileType) {
      let params = { file_type: fileType };
      retryWaitUpload(id, params).then((response) => {
        this.getUploadList()
      });
    },
    genUrl(encoded, options) {
      const dataBlob = new Blob([`\ufeff${encoded}`], {type: 'text/plain;charset=utf-8'});//返回的格式
      return window.URL.createObjectURL(dataBlob);
    },
    downloadFile (params) {
      ExportLogFileDown(params).then(response => {
        console.log('down response',response)

        const url = this.genUrl(response.data.data.csv_data, {}) //{}指的是表头，response.data.data.csv_data是后台返回来的数据
        const a = document.createElement('a');
        a.href = url;
        a.download = response.data.data.file_name;
        a.click();
        window.URL.revokeObjectURL(url);
      }).catch(err => {
        // this.$message.error("文件过期～")
      })
    },
    exportCityData () {
      exportCityData().then(response => {
        if (response.data.data.status) {
          this.$message({
            type: 'success',
            message: '已加入执行队列，请在设置-导出列表中下载'
          })
        } else if (response.data.data.log_id) {
          let params = {
            log_id: response.data.data.log_id
          }
          this.downloadFile(params)
        } else {
          this.$message({
            type: 'error',
            message: '没有相关数据可导出'
          })
        }
      })
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getUploadList();
    },
    getUploadList() {
      this.loading = true;
      let params = {
        file_type: this.fileType,
        page: this.page,
        pageSize: this.pageSize,
        rel_id: this.relId,
      };
      getUploadLists(params).then((response) => {
        this.uploadList = response.data.data.list;
        this.total_count = response.data.data.total_count;
        this.loading = false;
      });
    },
    handleClose () {
      this.visible = false
      this.$emit('handleClose')
    }
  },
  mounted() {
    // this.getUploadList();
    // this.buttonName = buttonName
  },
};
</script>
