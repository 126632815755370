<!--
 * @Author: Arvin
 * @GitHub: https://github.com/973749104
 * @Blog: https://liuhgxu.com
 * @Description: 说明
 * @FilePath: /app/src/components/template_editor/search.vue
 * @Date: 2020-05-25 11:06:01
 * @LastEditors: Arvin
 * @LastEditTime: 2020-07-06 16:10:07
--> 
<template>
  <section v-if="name === 'search'" class="section">
    <div class="section-header with-border">设置</div>
    <div class="section-body">
      <el-form label-width="100px">
        <el-form-item label="钉在顶部">
          <el-switch
            v-model="config.fixTop"
            active-color="#27cc6a"
            inactive-color="#efefef"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="组件间距">
          <el-switch
            v-model="base.padded"
            active-color="#27cc6a"
            inactive-color="#efefef"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="开启扫码">
          <el-switch
            v-model="config.scanCode"
            active-color="#27cc6a"
            inactive-color="#efefef"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="热词搜索配置">
          <el-input
            type="input"
            v-model="config.hotword"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
        <el-form-item label="资源位名称">
          <el-input type="text" v-model="base.flowName_var"></el-input>
        </el-form-item>
        <el-form-item label="楼层">
          <el-input type="text" v-model="base.floor_var"></el-input>
        </el-form-item>
      </el-form>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    res: {
      type: Object,
      default: {}
    }
  },
  watch: {
    res (value) {
      if (value) {
        this.setData(value)
      }
    }
  },
  data () {
    return {
      name: '',
      base: {},
      config: {},
      hotword: ''
    }
  },
  methods: {
    // hotwordBlur(val) {
    //   //let a = val.split('\n')
    //   this.config.hotword = val
    // },
    setData (val) {
      this.name = val.name
      this.base = val.base
      this.config = val.config
      // && this.config.hotword.length > 0
      // if (this.config.hotword) {
      //   this.hotword = this.config.hotword
      // }
    }
  },
  mounted () {
    this.setData(this.res)
  }
}
</script>

<style scoped lang="scss">
</style>
