var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"title":"设置路径内容","visible":_vm.linksVisible,"width":"800px","append-to-body":""},on:{"close":_vm.closeDialog}},[_c('div',{staticClass:"view-flex"},[_c('Tabs',{attrs:{"showlinks":_vm.links,"type":_vm.type},on:{"onClick":_vm.handleTabsClick}}),_c('div',{staticClass:"link-data"},[(
          _vm.type !== 'category' &&
          _vm.type !== 'marketing' &&
          _vm.type !== 'link' &&
          _vm.type !== 'modal' &&
          _vm.type !== 'redpackage'
        )?_c('Finder',{attrs:{"type":_vm.type},on:{"onSearch":_vm.handleSearch}},[(_vm.type === 'goods' || _vm.type === 'article' || _vm.type === 'planting')?_c('div',{staticClass:"store view-flex-item"},[_c('StoreFilter',{attrs:{"data":_vm.store,"lock":_vm.lockStore},on:{"change":_vm.handleStoreChange}})],1):_vm._e()]):_vm._e(),_c('List',{attrs:{"type":_vm.type,"store":_vm.store,"keywords":_vm.keywords,"name":_vm.name},on:{"onClick":_vm.handleRowClick}})],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.closeDialog}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.setComfirm}},[_vm._v("确 定")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }