<style lang="scss">
.picker-subscribeMessage {
  .sp-filter-form {
    padding: 8px 8px 0 8px;
  }
  .sp-finder-hd {
    display: none;
  }
  .sp-finder-hd {
    display: none;
  }
  .el-table {
    td {
      padding: 0;
    }
  }
  .el-pagination {
    margin: 0;
    padding: 10px;
  }
}
</style>
<template>
  <div class="picker-subscribeMessage">
    <SpFilterForm :model="formData" size="small" @onSearch="onSearch" @onReset="onSearch">
      <SpFilterFormItem prop="id">
        <el-input v-model="formData.id" clearable placeholder="请输入消息ID" />
      </SpFilterFormItem>
      <SpFilterFormItem prop="msg_name">
        <el-input v-model="formData.msg_name" clearable placeholder="请输入消息名称" />
      </SpFilterFormItem>
    </SpFilterForm>

    <SpFinder
      ref="finder"
      reserve-selection
      row-key="id"
      :other-config="{
        'max-height': 460,
        'header-cell-class-name': cellClass
      }"
      url="/subscribe/custom_msg/lists"
      show-pager-text="已选中：${n}"
      :fixed-row-action="true"
      :setting="{
        columns: [
          { name: 'ID', key: 'id', width: 80 },
          { name: '消息名称', key: 'msg_name' },
        ]
      }"
      :hooks="{
        beforeSearch: beforeSearch,
        afterSearch: afterSearch
      }"
      @select="onSelect"
      @selection-change="onSelectionChange"
    />
  </div>
</template>

<script>
import BasePicker from './base'
import PageMixin from '../mixins/page'

export default {
  name: 'PickerSubscribeMessage',
  extends: BasePicker,
  mixins: [PageMixin],
  config: {
    title: '订阅消息'
  },
  props: ['value'],
  components:{},
  data() {
    const { queryParams } = this.value
    const defaultParams = {
      msg_name: '',
      id: ''
    }
    const formData = Object.assign(defaultParams, queryParams)
    return {
      formData,
      list: [],
      multiple: this.value?.multiple ?? true,
      localSelection: []
    }
  },
  created() {
    this.localSelection = this.value.data || []
  },
  mounted() {},
  methods: {
    beforeSearch(params) {
      params = {
        ...params,
        ...this.formData
      }
      return params
    },
    afterSearch(response) {
      const { list } = response.data.data
      if (this.localSelection.length > 0) {
        const selectRows = list.filter((item) => this.localSelection.includes(item.id))
        const { finderTable } = this.$refs.finder.$refs
        setTimeout(() => {
          finderTable.$refs.finderTable.setSelection(selectRows)
        })
      }
    },
    onSearch() {
      this.$refs.finder.refresh(true)
    },
    onSelect(selection, row) {
      if (!this.multiple) {
        const { finderTable } = this.$refs.finder.$refs
        finderTable.clearSelection()
        setTimeout(() => {
          finderTable.$refs.finderTable.setSelection(selection.length > 0 ? [row] : [])
        })
      }
    },
    onSelectionChange(selection) {
      this.localSelection = []
      this.updateVal(selection)
    },
  }
}
</script>
