<template>
  <el-dialog
    width="900px"
    title="选择活动"
    :visible="activityVisible"
    @close="closeDialog"
    append-to-body
    destroy-on-close
  >
    <el-table
      ref="activityTable"
      :data="list"
      style="width: 100%"
      v-loading="loading"
      element-loading-text="数据加载中"
      :row-key="getRowKeys"
      @select="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        width="60"
        :reserve-selection="true"
      ></el-table-column>
      <el-table-column prop="id" width="60" label="编号"></el-table-column>
      <el-table-column prop="typetext" label="类型"></el-table-column>
      <el-table-column prop="activity_title" label="标题"></el-table-column>
      <el-table-column prop="company_id" label="公司id"></el-table-column>
      <el-table-column
        prop="customize_id"
        label="自定义页面id"
        width="120"
      ></el-table-column>
      <el-table-column
        prop="page_name"
        label="自定义页面名称"
        width="120"
      ></el-table-column>
      <el-table-column label="开始时间" width="200">
        <template slot-scope="scope">
          <span>{{
            scope.row.start_time | datetime("yyyy-MM-dd hh:mm:ss")
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="结束时间" width="200">
        <template slot-scope="scope">
          <span>{{
            scope.row.end_time | datetime("yyyy-MM-dd hh:mm:ss")
          }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div v-if="total_count > params.pageSize" class="pager">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :current-page.sync="params.page"
        :page-sizes="[10, 20, 30, 50]"
        :total="total_count"
        :page-size="params.pageSize"
      >
      </el-pagination>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancelAction">取 消</el-button>
      <el-button type="primary" @click="saveStoreAction">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getActivityList } from '@/api/promotions'
export default {
  props: {
    activityVisible: {
      type: Boolean,
      default: false
    },
    res: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      loading: false,
      params: {
        page: 1,
        pageSize: 20,
        type: 'activity'
      },
      total_count: 0,
      list: [],
      selection: [],
      selectRows: []
    }
  },
  methods: {
    closeDialog () {
      this.selectRows = this.selection
      this.$emit('closeDialog', 'activity')
      this.$emit('chooseStore', this.selectRows)
    },
    cancelAction () {
      this.selectRows = this.selection
      this.$emit('closeDialog', 'activity')
      this.$emit('chooseStore', this.selectRows)
    },
    saveStoreAction () {
      this.$emit('chooseStore', this.selectRows)
    },
    handleSelectionChange (val, row) {
      let inChecked = this.selectRows.findIndex(
        item => row.id === item.id
      )
      if (inChecked !== -1) {
        this.selectRows.splice(inChecked, 1)
      } else {
        this.selectRows.push(row)
        // this.$refs.activityTable.clearSelection();
        this.$nextTick(() => {
          this.selectRows.forEach(item => {
            this.$refs.activityTable.toggleRowSelection(item, true)
          })
        })
      }
    },
    getRowKeys (row) {
      return row.id
    },
    handleCurrentChange (page_num) {
      this.$refs.activityTable.clearSelection()
      this.params.page = page_num
      this.getActivityList()
    },
    handleSizeChange (pageSize) {
      this.$refs.activityTable.clearSelection()
      this.params.page = 1
      this.params.pageSize = pageSize
      this.getActivityList()
    },
    async getActivityList () {
      const { data, name } = this.res
      if (name !== 'blokScroll') return
      const res = await getActivityList(this.params)
      this.loading = false
      this.list = res.data.data.list
      this.selectRows = data
      this.selection = JSON.parse(JSON.stringify(data))
      this.list.map(item => {
        if (item.type === 'activity') {
          item.typetext = '活动'
        }
      })
      this.total_count = res.data.data.total_count
      /* let selection = []
      this.list.map(value => {
        for (let item of data) {
          if(item.id === value.id) {
            selection.push(value)
          }
        }
      })
      this.$nextTick(() => {
        selection.forEach(row => {
          this.$refs.activityTable.toggleRowSelection(row)
        })
      }) */
    }
  },
  mounted () {
    this.getActivityList()
  },
  watch: {
    list (val) {
      if (this.selectRows.length > 0) {
        this.list.forEach(item => {
          let checked = this.selectRows.find(n => n.id === item.id)
          if (checked) {
            this.$refs.activityTable.toggleRowSelection(item)
          }
        })
      }
    }
  }
}
</script>

<style>
.pager {
  margin-top: 20px;
}
</style>
