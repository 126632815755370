var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"picker-goods"},[_c('SpFilterForm',{attrs:{"model":_vm.formData,"size":"small"},on:{"onSearch":_vm.onSearch,"onReset":_vm.onSearch}},[(_vm.isShowFormItem('distributor_id'))?_c('SpFilterFormItem',{attrs:{"prop":"distributor_id"}},[_c('SpSelectShop',{attrs:{"clearable":"","placeholder":"请选择店铺"},on:{"change":_vm.onSearch},model:{value:(_vm.formData.distributor_id),callback:function ($$v) {_vm.$set(_vm.formData, "distributor_id", $$v)},expression:"formData.distributor_id"}})],1):_vm._e(),_c('SpFilterFormItem',{attrs:{"prop":"keywords"}},[_c('el-input',{attrs:{"clearable":"","placeholder":"请输入商品名称"},model:{value:(_vm.formData.keywords),callback:function ($$v) {_vm.$set(_vm.formData, "keywords", $$v)},expression:"formData.keywords"}})],1)],1),_c('SpFinder',{ref:"finder",attrs:{"reserve-selection":"","row-key":"goods_id","other-config":{
      'max-height': 460,
      'header-cell-class-name': _vm.cellClass
    },"url":"/goods/items","show-pager-text":"已选中：${n}","fixed-row-action":true,"setting":{
      columns: [
        { name: '商品ID', key: 'goods_id', width: 80 },
        {
          name: '商品名称',
          key: 'page_name',
          render: (h, { row }) =>
            h('div', { class: 'item-info' }, [
              h('div', { class: 'item-image' }, [
                h('SpImage', {
                  props: {
                    src: row.pics[0],
                    width: 60,
                    height: 60
                  }
                })
              ]),
              h('div', { class: 'item-name' }, row.itemName)
            ])
        },
        { name: '商品货号', key: 'item_bn', width: 150 },
        {
          name: '价格（¥）',
          key: 'price',
          width: 100,
          render: (h, { row }) => h('span', {}, row.price / 100)
        },
        { name: '库存', key: 'store', width: 100 }
      ]
    },"hooks":{
      beforeSearch: _vm.beforeSearch,
      afterSearch: _vm.afterSearch
    }},on:{"select":_vm.onSelect,"selection-change":_vm.onSelectionChange}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }