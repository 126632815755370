<template>
  <div
    class="component-wrap setw-w"
    :class="{ 'component-padded': base.padded, active: active }"
  >
    <div class="current-active cutt"></div>
    <el-carousel
      class="article"
      :direction="config.direction"
      indicator-position="none"
      arrow="never"
    >
      <el-carousel-item v-for="(item, index) in data" :key="index">
        <div
          class="article-item"
          :class="{ 'gradient-text': !item.fontcolor }"
          :style="'color:' + item.fontcolor"
        >
          <img class="carousel-icon" :src="item.imgUrl" />
          <p>{{ item.text }}</p>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  props: {
    res: {
      type: Object,
      default: {},
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    res(value) {
      if (value) {
        this.setData(value);
      }
    },
  },
  data() {
    return {
      base: {},
      config: {},
      data: [],
    };
  },
  methods: {
    setData(val) {
      this.base = val.base;
      this.config = val.config;
      this.data = val.data;
    },
  },
  created() {
    this.setData(this.res);
  },
};
</script>

<style scoped lang="scss">
.setw-w {
  .curtt {
  }
}
.article {
  width: 320px;
  height: 33px;
  background-color: #000 !important;
  .article-item {
    margin: 0 20px;
    width: auto;
    max-width: 320px;
    height: 33px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
  }
  .carousel-icon {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }
  .gradient-text {
    background-image: linear-gradient(
      45deg,
      #5edaf5 0%,
      #85b8fa 30%,
      #9e84e6 100%
    );
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; /*需要文字透明*/
  }
}
</style>
