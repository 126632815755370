<template>
  <section v-if="name === 'goodsLift'" class="section">
    <div class="section-header with-border">设置</div>
    <div class="current-form">
      <el-form>
        <el-form-item label="标题">
          <el-input v-model="base.title" type="text"></el-input>
        </el-form-item>
        <el-form-item label="副标题">
          <el-input v-model="base.subtitle"></el-input>
        </el-form-item>
        <el-form-item label="tab底色">
          <el-color-picker show-alpha v-model="base.bg_color"></el-color-picker>
        </el-form-item>
        <el-form-item label="tab默认色">
          <el-color-picker show-alpha v-model="base.default_color"></el-color-picker>
        </el-form-item>
        <el-form-item label="tab默认字体色">
          <el-color-picker show-alpha v-model="base.default_color_font"></el-color-picker>
        </el-form-item>
        <el-form-item label="tab选中色">
          <el-color-picker show-alpha v-model="base.checked_color"></el-color-picker>
        </el-form-item>
        <el-form-item label="tab选中字体色">
          <el-color-picker show-alpha v-model="base.checked_color_font"></el-color-picker>
        </el-form-item>
        <el-form-item label="楼层">
          <el-input type="text" v-model="base.floor_var"></el-input>
        </el-form-item>
        <el-form-item label="组件间距">
          <el-switch v-model="base.padded" active-color="#27cc6a" inactive-color="#efefef">
          </el-switch>
        </el-form-item>
        <div class="config-list">
          <draggable v-model="items" class="nearby-activity" :options="dragItemsOptions" @end="onEnd">
            <el-card shadow="never" :body-style="{
                padding: '10px',
                alignItems: 'center',
                width:'100%'
              }" class="setting-item item-selected" v-for="(item, index) in data" :key="item.id">
              <el-row :gutter="1">
                <el-col :span="1">
                  {{ item.id }}
                </el-col>
                <el-col :span="8">
                  标题:{{ item.activity_title }}
                </el-col>
                <!-- <el-col :span="8">
                  <div>
                    描述:{{ item.activity_desc }}
                  </div>
                </el-col> -->
                <el-col :span="2">
                  <span class="marginLeft20">
                    <el-button type="primary" size="mini" @click="removeItem(index)">删除</el-button>
                  </span>
                </el-col>
              </el-row>
            </el-card>
          </draggable>
        </div>
      </el-form>
      <el-button type="default" class="iconfont icon-cog banner-button-uploader set-selling" @click="setSelling">
        设置企业员工购商品
      </el-button>
    </div>
  </section>
</template>

<script>
import hotzone from 'vue-hotzone'
import draggable from "vuedraggable";

export default {
  props: {
    res: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      name: '',
      base: {},
      config: {},
      data: [],
      items: [],
      temp: "",
      dragItemsOptions: {
        animation: 300,
        forceFallback: false,
        scroll: true,
      },
    }
  },
  watch: {
    res: {
      deep: true,
      handler(value) {
        this.setData(value)
      }
    }
  },
  components: {
    hotzone: hotzone,
    draggable
  },
  methods: {
    setData(val) {
      this.name = val.name
      this.base = val.base
      this.data = val.data
      this.items = val.data
    },
    setSelling() {
      this.$emit("bindSelling");
    },
    onEnd(evt) {
      this.temp = this.data[evt.oldIndex];
      this.data.splice(evt.oldIndex, 1);
      this.data.splice(evt.newIndex, 0, this.temp);
    },
    removeItem(index) {
      this.data.splice(index, 1);
    },
  },
  mounted() {
    this.setData(this.res)
  }
}
</script>

<style lang="scss" scoped>
.section {
  .section-header {
    position: static;
  }

  .current-form {
    padding: 30px;

    .config-list {


      .el-row{
        display: flex;
        align-items: center
      }
    }
  }
  .set-selling{
    margin-top: 10px;
  }
}
.setting-item {
  justify-content: space-between;
  align-items: center;
}
</style>
