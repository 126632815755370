<template>
  <div
    class="component-wrap"
    :class="{ 'component-padded': base.padded, active: active }"
  >
    <div class="current-active"></div>
    <div class="wrap" :style="`background:${data.wrap.background};`">
      <el-button
        :style="`
          background-color:${data.btn.style.background};
          color: ${data.btn.style.color}; 
          width: ${data.btn.style.width}px;
          backgroundImage:url(${config.imgUrl});
        `"
        type="primary"
        round
        >{{ data.btn.text }}</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    res: {
      type: Object,
      default: {}
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      data: {},
      base: {},
      config: {}
    }
  },
  watch: {
    res: {
      handler (value) {
        if (value) {
          this.setData(value)
        }
      }
    }
  },
  methods: {
    setData (val) {
      this.base = val.base
      this.config = val.config
      this.data = val.data
    }
  },
  mounted () {
    console.log(this.res)
    this.setData(this.res)
  }
}
</script>

<style lang="scss" scoped>
.component-wrap {
  .wrap {
    padding-top: 10px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
  }
  button {
    background-repeat: no-repeat;
    background-size: cover;
  }
}
.components-wrap .component-wrap.component-padded {
  padding: 0;
}
</style>
