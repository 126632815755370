var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"component-wrap",class:{ 'component-padded': _vm.base.padded, 'active': _vm.active }},[_c('div',{staticClass:"current-active"}),(_vm.base.title)?_c('div',{staticClass:"component-header"},[_c('div',{staticClass:"component-title"},[_c('div',[_vm._v(_vm._s(_vm.base.title))]),_c('div',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.base.subtitle))])]),_vm._m(0)]):_vm._e(),_vm._l((_vm.data),function(item,index){return _c('div',{key:index},[_vm._l((item.hotzoneList),function(em,ex){return (_vm.tabsIndex == index)?_c('div',{key:'index' + ex,staticClass:"img-hotzone"},[_c('img',{attrs:{"src":em.imgUrl,"alt":""}}),_vm._l((em.list),function(ie,ix){return _c('div',{key:ix,staticClass:"zone",style:({
          width: _vm.getZoneStyle(ie.widthPer),
          height: _vm.getZoneStyle(ie.heightPer),
          top: _vm.getZoneStyle(ie.topPer),
          left: _vm.getZoneStyle(ie.leftPer)
        }),on:{"click":function($event){return _vm.onTabsIndex(ie.hotzoneTypeIndex, ie.hotzoneType)}}})})],2):_vm._e()}),_c('div',{staticClass:"img-hotzone"},[_c('img',{attrs:{"src":item.fixedBtnImg,"alt":""}})])],2)})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"component-more"},[_c('div',{staticClass:"three-dot"})])
}]

export { render, staticRenderFns }