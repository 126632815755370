<template>
  <div
    class="component-wrap"
    :class="{ 'component-padded': base.padded, active: active }"
  >
    <div class="current-active"></div>
    <div v-if="base.title" class="component-header">{{ base.title }}</div>
    <img
      class="poster"
      src="http://b-img-cdn.yuanyuanke.cn/image/1/2022/03/21/942749657d51c14e1084271e9bf2422aHbWf5r6h5CTKRFzO3oTJt6kLI4NhYQ34"
      alt=""
    />
  </div>
</template>

<script>
export default {
  props: {
    res: {
      type: Object,
      default: {},
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      base: {},
      data: [],
    };
  },
  methods: {
    setData(val) {
      this.base = val.base;
      this.data = val.data;
    },
  },
  mounted() {
    this.setData(this.res);
  },
};
</script>

<style lang="scss" scoped>
.component-wrap {
  width: 320px;
  margin: 0 auto;
  .component-header {
    font-size: 18px;
    font-weight: bold;
    padding: 0;
    margin-bottom: 5px;
    padding: 0 10px;
  }
}
.poster {
  width: 320px;
  margin: 0 auto;
}
</style>
