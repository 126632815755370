var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"picker-live"},[_c('SpFinder',{ref:"finder",class:['shop-finder', { 'no-multiple': !_vm.multiple }],attrs:{"other-config":{
      'max-height': 460
    },"url":"/promotions/liverooms","fixed-row-action":true,"setting":{
      columns: _vm.columns
    },"hooks":{
      beforeSearch: _vm.beforeSearch,
      afterSearch: _vm.afterSearch
    }},on:{"select":_vm.onSelect,"selection-change":_vm.onSelectionChange}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }