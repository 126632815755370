<template>
  <el-dialog class="store-dialog" :title="'选择经销商'" :visible.sync="showDialog" :close-on-click-modal="false" :before-close="cancelAction" width="70%" append-to-body >
    <!-- :maxSelect="maxSelect" -->
    <div style="margin-bottom: 15px;">
      <el-input class="input-m" placeholder="输入经销商名称" v-model="params.name" clearable @clear="handleIconClick"></el-input>
      <el-input class="input-m" placeholder="经销商HQID" v-model="params.dealer_hqid" clearable @clear="handleIconClick"></el-input>
      <el-input class="input-m" placeholder="分销商HQID" v-model="params.distributor_hqid" clearable @clear="handleIconClick"></el-input>
      <el-input class="input-m" placeholder="分销商VAD" v-model="params.distributor_vad" clearable @clear="handleIconClick"></el-input>
      <el-input class="input-m" placeholder="渠道RTM" v-model="params.channel_rtm" clearable @clear="handleIconClick"></el-input>
      <el-button slot="append" icon="el-icon-search" @click="handleIconClick" type="primary">搜索</el-button>
    </div>
    <div>
      <el-button @click="clearAll" type="text">清空已选</el-button>
    </div>
    <el-table ref="multipleTable" :data="storeData" tooltip-effect="dark" @select="handleSelectionChange" @select-all="handleSelectAll" v-loading="loading" :row-key="getRowKeys" :select-on-indeterminate="true">
      <el-table-column type="selection" :reserve-selection="true" width="50"></el-table-column>
      <el-table-column prop="dealer_id" label="ID"></el-table-column>
      <el-table-column prop="name" label="经销商名称"></el-table-column>
      <el-table-column prop="dealer_hqid" label="经销商HQID"></el-table-column>
      <el-table-column prop="distributor_hqid" label="分销商HQID"></el-table-column>
      <el-table-column prop="distributor_vad" label="分销商VAD"></el-table-column>
      <el-table-column prop="channel_rtm" label="渠道RTM"></el-table-column>
<!--      <el-table-column prop="contact" label="联系人"></el-table-column>-->
<!--      <el-table-column prop="operator_type" label="类型"></el-table-column>-->
<!--      <el-table-column prop="store_name" label="门店"></el-table-column>-->
<!--      <el-table-column prop="address" label="地址" show-overflow-tooltip></el-table-column>-->
    </el-table>
    <div class="tr">
      <el-pagination
        style="margin-top: 15px; text-align: center"
        layout="sizes, prev, pager, next"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[100, 200, 300, 400, 500]"
        :total="total_count"
        :current-page="params.page"
        :page-size="params.pageSize">
      </el-pagination>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="cancelAction">取 消</el-button>
      <el-button type="primary" @click="saveStoreAction">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getDealerList } from '@/api/marketing'
export default {
  props: {
    relDataIds: {
      type: Array,
      default: function () {
        return []
      }
    },
    getStatus: {
      type: Boolean,
      default: false
    },
    isValid: {
      type: Boolean,
      default: false
    },
    storeVisible: {
      type: Boolean,
      default: false
    },
    sourceType: {
      type: String,
      default: ''
    },
    isSingleSelect: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dataType: 'dealer',
      loading: false,
      storeData: [],
      multipleSelection: [],
      pageLimit: 100,
      total_count: 0,
      params: {
        page: 1,
        pageSize: 100,
          // is_valid: 'true',
        name: '',
        dealer_hqid: '',
        distributor_hqid: '',
        distributor_vad: '',
        channel_rtm: ''
      },
      selectRows: []
    }
  },
  methods: {
    getDealer () {
      this.loading = true
      getDealerList(this.params).then(response => {
        this.storeData = response.data.data.list
        this.total_count = parseInt(response.data.data.total_count)
        this.loading = false
        this.multipleSelection = []
        this.$refs.multipleTable.clearSelection()
        if (this.selectRows) {
          this.selectRows.forEach(item => {
            this.$refs.multipleTable.toggleRowSelection(item)
          })
          // this.storeData.forEach(item => {
          //   const isHave = this.selectRows.findIndex((n) => {
          //     if (n.dealer_id) {
          //       return n.dealer_id && n.dealer_id == item.dealer_id
          //     } else {
          //       return n == item.dealer_id
          //     }
          //   })
          //   if (isHave !== -1) {
          //     setTimeout(() => {
          //       this.$refs.multipleTable.toggleRowSelection(item)
          //     })
          //   }
          // })
        }
      })
    },
    getRowKeys (row) {
      return row.dealer_id
    },
    handleSizeChange (page_size) {
      this.params.pageSize = page_size
      this.getDealer()
    },
    handleCurrentChange (page_num) {
      this.params.page = page_num
      this.getDealer()
    },
    handleIconClick () {
        // this.params.name = this.name
      this.params.page = 1
      this.getDealer()
    },
    toggleSelection (rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    handleSelectionChange (val, row) {
      console.log(val, row)
      let inChecked = this.selectRows.findIndex(
          item => row.dealer_id === item.dealer_id
        )

      if (val) {
        this.multipleSelection = val
        val.forEach(item => {
          let isInArr = this.selectRows.findIndex((n) => n.dealer_id === item.dealer_id)
          if (isInArr === -1) {
            this.selectRows.push(item)
          }
        })
      }
      if (this.isSingleSelect) {
        this.$refs.multipleTable.clearSelection()
        this.$refs.multipleTable.toggleRowSelection(row)
        this.selectRows = [row]
        return
      }

      if (inChecked === -1 && this.maxSelect && this.selectRows.length >= this.maxSelect) {
          // this.$message({message: `最多选择选择 `+this.maxSelect+` 个经销商`, type: 'warning'})
          // this.$refs.multipleTable.clearSelection()
          // this.selectRows.forEach(item => {
          //   this.$refs.multipleTable.toggleRowSelection(item)
          // })
          // return
      }

      // this.selectRows = val

        // if (isInArr === -1) {
        //   this.selectRows.push(row)
        // }
    },
    handleSelectAll (val) {
        // console.log(val)
      if (this.isSingleSelect) {
        this.$message({ message: '当前不支持全选', type: 'warning' })
        this.$refs.multipleTable.clearSelection()
        if (this.selectRows && this.selectRows.length > 0) {
          this.$refs.multipleTable.toggleRowSelection(this.selectRows[0])
        }
        return
          // this.$refs.multipleTable.clearSelection();
          // this.storeData.forEach(item => {
          //   let checked = this.selectRows.find(n => n.dealer_id === item.dealer_id);
          //   if (checked) {
          //     this.$refs.multipleTable.toggleRowSelection(item);
          //   }
          // });
          // return;
      }
      if (val) {
        this.multipleSelection = val
        val.forEach(item => {
          let isInArr = this.selectRows.findIndex((n) => n.dealer_id === item.dealer_id)
          if (isInArr === -1) {
            this.selectRows.push(item)
          }
        })
      }
      // this.selectRows = val
    },
    cancelAction () {
      this.params.page = 1
      this.$emit('closeStoreDialog')
    },
    saveStoreAction () {
      this.$emit('chooseStore', this.multipleSelection)
    },
    clearAll () {
      this.$refs.multipleTable.clearSelection()
      this.selectRows = []
    }
  },
  computed: {
    showDialog () {
      return this.storeVisible
    }
  },
  watch: {
    sourceType (newVal, oldVal) {
      this.dataType = this.sourceType
    },
    relDataIds: {
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.selectRows = newVal
        } else {
          this.selectRows = []
        }
      },
      immediate: true,
      deep: true
    },
    getStatus (newVal, oldVal) {
      if (newVal) {
        this.getDealer()
      }
    }
  }
}
</script>

<style type="text/css">
  .store-dialog .el-checkbox {
    display: inline;
  }
</style>
