<template>
  <el-dialog width="75%" class="store-dialog" title="已同步的店铺列表" :visible.sync="showDialog" :close-on-click-modal="false" :before-close="cancelAction" append-to-body>
    <div style="margin-bottom: 15px;">
      <el-input
        style="width: 200px"
        placeholder="AppleId"
        v-model="shop_code"
        clearable>
      </el-input>
      <el-input
        style="width: 200px"
        placeholder="输入店铺名称"
        v-model="name"
        clearable>
      </el-input>
      <el-button icon="el-icon-search" @click="handleIconClick" type="primary">搜索</el-button>
      <el-button @click="handleCreateClick" type="primary">新增门店</el-button>
      <ImportDistributor
        buttonName="批量导入门店"
        buttonType="primary"
        fileName="批量导入门店模版"
        fileType="upload_template_distributor"
        :relId="templateId"
        @handleClose="importDialogClose"
      ></ImportDistributor>
    </div>
    <el-table ref="multipleTable" :data="storeData" tooltip-effect="dark" style="width: 100%" v-loading="loading">
      <el-table-column prop="distributor_id" label="ID" width="90"></el-table-column>
      <el-table-column prop="shop_code" label="门店AppleId" width="120"></el-table-column>
      <el-table-column prop="mobile" label="联系方式" width="120"></el-table-column>
      <el-table-column prop="name" label="店铺名称"></el-table-column>
      <el-table-column prop="is_valid" label="门店状态">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.is_valid == 'true'">启用</el-tag>
          <el-tag type="warning" v-else-if="scope.row.is_valid == 'false'">禁用</el-tag>
          <el-tag type="error" v-else>废弃</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="地址" show-overflow-tooltip></el-table-column>
    </el-table>
    <div v-if="total_count > params.pageSize" style="text-align: center; margin-top: 20px" class="tr">
      <el-pagination
        layout="sizes, prev, pager, next"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[20, 50, 100, 200, 300, 400, 500]"
        :total="total_count"
        :page-size="params.pageSize">
      </el-pagination>
    </div>
  </el-dialog>
</template>

<script>
import { getDistributorList } from '@/api/marketing'
import ImportDistributor from '@/components/importDistributor'
export default {
  props: {
    templateId: {
      type: String,
      default: ''
    },
    storeVisible: {
      type: Boolean,
      default: false
    },
    platform: {
      type: String,
      default: ''
    },
    refreshData: {
      type: String,
      default: ''
    },
    distributorValid: {
      type: String,
      default: ''
    }
  },
  components: { ImportDistributor },
  data () {
    return {
      loading: false,
      storeData: [],
      total_count: '',
      params: {
        page: 1,
        pageSize: 20,
        platform: '',
        is_valid: 'true'
      },
      name: '',
      shop_code: ''
    }
  },
  methods: {
    getDistributor() {
      this.loading = true
      this.params.platform = this.platform
      if (this.distributorValid) {
        this.params.is_valid = this.distributorValid
      }
      this.params.pages_template_id = this.templateId
      getDistributorList(this.params).then( response => {
        this.storeData = response.data.data.list
        this.total_count = parseInt(response.data.data.total_count)
        this.loading = false
      })
    },
    importDialogClose() {
      this.getDistributor()
    },
    handleCreateClick () {
      this.$emit('handleCreateClick')
    },
    handleSizeChange (page_size) {
      this.params.pageSize = page_size
      this.getDistributor()
    },
    handleCurrentChange (page_num) {
      this.params.page = page_num
      this.getDistributor()
    },
    handleIconClick () {
      this.params.page = 1
      this.params.name = this.name
      this.params.shop_code = this.shop_code
      this.getDistributor()
    },
    cancelAction () {
      console.log('cancel')
      this.$emit('handleClose')
    }
  },
  computed: {
    showDialog () {
      return this.storeVisible
    }
  },
  watch: {
    storeVisible (newVal) {
      if (newVal) {
        this.getDistributor()
      }
    },
    refreshData (newVal) {
      if (newVal) {
        this.getDistributor()
      }
    }
  }
}
</script>

<style type="text/css">
.store-dialog .el-checkbox {
  display: inline;
}
</style>
