var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"component-wrap",class:{'component-padded' : _vm.base.padded, 'active': _vm.active}},[_c('div',{staticClass:"current-active"}),(_vm.base.title)?_c('div',{staticClass:"component-header"},[_c('div',{staticClass:"component-title"},[_c('div',[_vm._v(_vm._s(_vm.base.title))]),_c('div',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.base.subtitle))])]),_vm._m(0)]):_vm._e(),_c('div',{staticClass:"img-hotzone"},[_c('img',{attrs:{"src":_vm.config.imgUrl,"alt":""}}),_vm._l((_vm.data),function(item,index){return _c('div',{key:index,staticClass:"zone",style:({
        width: _vm.getZoneStyle(item.widthPer),
        height: _vm.getZoneStyle(item.heightPer),
        top: _vm.getZoneStyle(item.topPer),
        left: _vm.getZoneStyle(item.leftPer)
      })})})],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"component-more"},[_c('div',{staticClass:"three-dot"})])
}]

export { render, staticRenderFns }