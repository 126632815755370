<template>
  <section v-if="name === 'imgHotzone'" class="section">
    <div class="section-header with-border">设置</div>
    <div class="section-body">
      <el-form label-width="100px">
        <el-form-item label="标题">
          <el-input v-model="base.title"></el-input>
        </el-form-item>
        <el-form-item label="副标题">
          <el-input v-model="base.subtitle"></el-input>
        </el-form-item>
        <el-form-item label="楼层">
          <el-input type="text" v-model="base.floor_var"></el-input>
        </el-form-item>
        <el-form-item label="abTest">
          <el-switch
            v-model="base.abtest"
            active-text="打开"
            inactive-text="关闭"
            active-color="#27cc6a"
            inactive-color="#efefef"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="实验数值" v-if="base.abtest">
          <el-input v-model="base.testGroup" placeholder="多个用','号隔开"></el-input>
        </el-form-item>
        <el-form-item label="组件间距">
          <el-switch
            v-model="base.padded"
            active-color="#27cc6a"
            inactive-color="#efefef"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="展示图">
          <div class="setting-item slider">
            <img
              v-if="config.imgUrl"
              :src="wximageurl + config.imgUrl"
              class="banner-uploader"
              @click="handleImgChange"
            />
            <div class="banner-uploader" v-else @click="handleImgChange">
              <i class="iconfont icon-camera"></i>上传图片
            </div>
          </div>
        </el-form-item>
        <el-form-item label="热区" v-if="refresh">
          <hotzone
            class="hotzone"
            :image="config.imgUrl"
            :zonesInit="data"
            @add="handleAdd"
            @change="handleChange"
            @remove="handleRemove"
          ></hotzone>
          <div
            class="setting-item slider"
            v-for="(item, index) in data"
            :key="index"
          >
            <div v-if="item.id === 'CopyLink'" class="Copy-Link">
              <el-input v-model="item.CopyUrl" placeholder="请输入链接"></el-input>
              <el-input v-model="item.CopyTitle" placeholder="请输入复制文案"></el-input>
              <el-button icon="iconfont icon-link" class="set" @click="handleGoodsChange(index)">设置路径</el-button>
            </div>
            <div v-else-if="item.id === 'thirdpage'" class="thirdpage-Link">
              <el-input v-model="item.linkTitle" placeholder="请输入跳转路径"></el-input>
              <el-input v-model="item.linkAppid" placeholder="请输入对应小程序APPID"></el-input>

              <el-form-item label="是否半屏跳转" v-if="item.linkProgram">
                  <el-radio-group v-model="item.linkProgram">
                    <el-radio label="1">否</el-radio>
                    <el-radio label="2">是</el-radio>
                  </el-radio-group>
              </el-form-item>

              <el-button icon="iconfont icon-link" class="set" @click="handleGoodsChange(index)">设置路径</el-button>
            </div>
            <div v-else-if="item.id === 'videopage'" class="video-Link">
              <el-form-item label="视频封面" label-width="80px">
                <img v-if="item.video_cover" :src="item.video_cover" class="video-uploader" @click="handleVideoImgChange(index, 'video_cover')" />
                <div v-else class="icon-uploader" @click="handleVideoImgChange(index, 'video_cover')">
                  <i class="iconfont icon-camera"></i>
                </div>
              </el-form-item>
              <el-form-item label="自动播放" label-width="80px">
                <el-switch
                  v-model="item.autoplay"
                  active-color="#27cc6a"
                  inactive-color="#efefef"
                />
              </el-form-item>
              <el-form-item label="选择视频" label-width="80px" style="margin: 10px 0px;">
                <Videoselect
                  @change="(data) => handleVideoChange(data, index)"
                  :data="item.video"
                  :multiple="false"
                  :k="index"
                />
              </el-form-item>
              <el-button icon="iconfont icon-link" class="set" @click="handleGoodsChange(index)">设置路径</el-button>
            </div>
            <div v-else :class="item.id == 'drawer-shop' ? 'uploader-setting flex' :'uploader-setting'">
              <div class="goods-select" @click="handleGoodsChange(index)">
                <div class="link-content" v-if="item.id">
                  <template v-if="item.linkPage === 'goods'">商品：</template>
                  <template v-if="item.linkPage === 'category'"
                    >分类：</template
                  >
                  <template v-if="item.linkPage === 'article'">文章：</template>
                  <template v-if="item.linkPage === 'planting'"
                    >软文：</template
                  >
                  <template v-if="item.linkPage === 'link'">页面：</template>
                  <template v-if="item.linkPage === 'marketing'"
                    >营销：</template
                  >
                  <template v-if="item.linkPage === 'custom_page'"
                    >自定义页面：</template
                  >
                  <template v-if="item.linkPage === 'redpackage'"
                    >红包封面：</template
                  >
                  <template v-if="item.linkPage === 'platform_order_marketing'"
                    >平台下单营销：
                  </template>
                  {{ item.title }} : {{ item.ctoken || item.id }}
                </div>
                <div v-else class="content-center">
                  <i
                    class="iconfont icon-link"
                    @click="handleGoodsChange(index)"
                  ></i
                  >设置路径
                </div>
              </div>
              <div class="cusdrawer-shop" v-if="item.id == 'drawer-shop' && $route.path.indexOf('custompage') > -1">
                <el-select v-model="item.rule_id" placeholder="请选择">
                  <el-option
                    v-for="selectitem in selectShopOptions"
                    :key="selectitem.rule_id"
                    :label="selectitem.rule_title"
                    :value="selectitem.rule_id"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="douyin-id" v-if="item.id == 'douyinLive' || item.id == 'douyinIm'">
                <el-form-item 
                  v-if="item.id == 'douyinLive'"
                  label="抖音直播ID">
                  <el-input  
                    v-model="item.liveId"
                    placeholder="请填写" />
                </el-form-item>
                <el-form-item  
                  v-if="item.id == 'douyinIm'"
                  label="抖音客服ID">
                  <el-input 
                    v-model="item.imId"
                    placeholder="请填写" />
                </el-form-item>
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </section>
</template>

<script>
import hotzone from 'vue-hotzone'
import { getShowRuleList } from '@/api/template'
import Videoselect from '@/components/videoselect/index'

export default {
  props: {
    res: {
      type: Object,
      default: {}
    },
    platform: {
      type: String,
      default: 'wxapp'
    },
    editorIndex:{
      type: Number|String,
      default: ''
    }
  },
  watch: {
    res: {
      deep: true,
      handler (value) {
        if (value) {
          this.setData(value)
        }
      }
    },
    editorIndex: {
      deep: true,
      immediate: true,
      handler(value) {
        this.refresh = false
        this.$nextTick(() => {
          this.refresh = true
        })
      }
    }
  },
  components: {
    Videoselect,
    hotzone: hotzone
  },
  data () {
    return {
      name: '',
      base: {},
      config: {},
      data: [],
      zones: [],
      selectShopid: '',
      selectShopOptions: [],
      refresh:true
    }
  },
  methods: {
    setData (val) {
      console.log(val)
      this.name = val.name
      this.base = val.base
      this.config = val.config
      this.data = val.data
      if (val.name === 'imgHotzone') {
        const filter = val && val.data && val.data.filter(el => el.id == 'drawer-shop') || []
        if (filter.length > 0) {
          this.getShopList()
        }
      }
    },
    handleImgChange (index) {
      this.$emit('bindImgs', index)
    },
    handleGoodsChange (index, item) {
      this.$emit('bindLinks', index)
    },
    handleAdd () {
      let item = {
        linkPage: '',
        title: '',
        id: '',
        linkTitle: '',
        linkAppid: '',
        linkProgram: '1',
        card_type: '',
        goods_id: '',
        rule_id: '0',
        CopyUrl: '',
        CopyTitle: ''
      }
      this.data.push(item)
    },
    handleChange (zone) {
      setTimeout(() => {
        zone.forEach((item, index) => {
          if (item.leftPer) {
            let obj = {
              heightPer: item.heightPer,
              leftPer: item.leftPer,
              topPer: item.topPer,
              widthPer: item.widthPer
            }
            Object.assign(this.data[index], obj)
          }
        })
      }, 500)
    },
    handleRemove (index) {
      this.data.splice(index, 1)
    },
    async getShopList () {
      if (this.$route.path.indexOf('custompage') == -1) return
      const res = await getShowRuleList({page: 1, page_size: -1, platform: this.platform})
      const data = [
        { rule_id: '0', rule_title: '全部门店' },
        ...res.data.data.list
      ]
      this.selectShopOptions = data
    },
    handleVideoChange (data, index) {
      this.data[index].video = data
      this.$emit('change', {data: data, index})
    },
    handleVideoImgChange (index, type) {
      this.$emit('bindImgs', index, '', type)
    }
  },
  mounted () {
    this.setData(this.res)
  }
}
</script>

<style scoped lang="scss">
.hotzone {
  max-width: 500px;
  margin-bottom: 10px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
}
.video-uploader {
  width: 120px;
  height: 120px;
}
.icon-uploader {
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
  border-radius: 6px;
  border: 1px dashed #c0ccda;
  .icon-camera {
    font-size: 30px;
    color: #ccc;
  }
}
.uploader-setting {
  padding: 0;
  display: flex;
  .cusdrawer-shop, .goods-select,.douyin-id{
    flex: 1
  }
  .cusdrawer-shop {
    margin-left: 20px;
    .el-select {
      width: 100%;
    }
  }
}
.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Copy-Link{
  width: 100%;
  display: flex;
  .set{
    margin-left: 20px;
  }
}

.thirdpage-Link{
  .el-input{
    margin-bottom: 10px;
  }
}
</style>
