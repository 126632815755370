var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"picker-custom-wgt"},[_c('div',{staticClass:"btn-tool-list"},[_c('el-button',{attrs:{"type":"medium"},on:{"click":_vm.handleCreate}},[_vm._v("新建")]),_c('el-button',{attrs:{"type":"medium"},on:{"click":_vm.onSearch}},[_vm._v("刷新")])],1),_c('SpFinder',{ref:"finder",attrs:{"reserve-selection":"","row-key":"pages_template_id","other-config":{
    'max-height': 460,
    'header-cell-class-name': _vm.cellClass
  },"url":"/pagestemplate/lists?page_type=custom_module","show-pager-text":"已选中：${n}","fixed-row-action":true,"setting":{
    columns: [
      { name: '模块ID', key: 'pages_template_id', width: 80 },
      { name: 'AB实验组件', key: 'pendant_data', render: (h, { row }) => h('span', {}, _vm.setType(row.pendant_data)), width: 120 },
      { name: '模块名称', key: 'template_title' },
      {
        name: '模块封面', key: 'template_pic', render: (h, { row }) =>
          h('SpImage', {
            props: {
              src: row.template_pic,
              width: 60,
              height: 60
            }
          })
      },
    ]
  },"hooks":{
    beforeSearch: _vm.beforeSearch,
    afterSearch: _vm.afterSearch
  },"selectable":_vm.selectable},on:{"select":_vm.onSelect,"selection-change":_vm.onSelectionChange}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }