<style lang="scss">
.picker-coupon {
  .sp-filter-form {
    margin-bottom: 0;

    .filter-form__bd {
      margin-left: 8px;
    }
  }

  .filter-tools {
    display: flex;
    align-items: center;
    padding: 8px;

    .el-cascader,
    .el-input {
      width: 196px;
      margin-right: 8px;
    }
  }

  .sp-finder-hd {
    display: none;
  }

  .disableheadselection {
    >.cell .el-checkbox__inner {
      display: none;
    }
  }
}
</style>
<template>
  <div class="picker-coupon" v-if="!loading">
    <SpFinder ref="finder" :other-config="{
    'max-height': 460,
  }" row-key="id" :data="categoryList" :setting="{
    columns: [
      { name: 'ID', key: 'id', width: '100px' },
      { name: '分类名称', key: 'category_name' }
    ]
  }" @select="onSelect" @selection-change="onSelectionChange" />
  </div>
</template>

<script>
import BasePicker from './base'
import PageMixin from '../mixins/page'
import { getCategory } from '@/api/goods'
export default {
  name: 'PickerClassification',
  extends: BasePicker,
  mixins: [PageMixin],
  config: {
    title: '选择产品分类'
  },
  props: ['value'],
  data() {
    return {
      formData: {
        region: [],
        keywords: ''
      },
      categoryList: [],
      loading: true,
      multiple: this.value?.multiple ?? true
    }
  },
  created() {
    this.fetch()
  },
  mounted() {
  },
  methods: {
    fetch() {
      getCategory().then(response => {
        this.categoryList = response.data.data.map(item => {
          return {
            id: item.id,
            category_name: item.category_name
          }
        })
        this.loading = false
        this.setdefaultSelection()
      })
    },
    onSelect(selection, row) {
      if (this.multiple) {
        // this.updateVal(selection)
      } else {
        const { finderTable } = this.$refs.finder.$refs
        console.log('finderTable:', finderTable)
        finderTable.clearSelection()
        setTimeout(() => {
          finderTable.$refs.finderTable.setSelection([row])
        })
      }
    },
    onSelectionChange(selection) {
      this.updateVal(selection)
    },
    setdefaultSelection() {
      this.$nextTick(() => {
        if (this.value.data) {
          const data = this.value.data.split(',')
          const selectRows = this.categoryList.filter((item) => {
            if (data.indexOf(item.id) > -1) return item
          })
          const { finderTable } = this.$refs.finder.$refs
          setTimeout(() => {
            finderTable.$refs.finderTable.setSelection(selectRows)
          })
        }
      })
    }
  },
}
</script>
