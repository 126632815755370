var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"picker-compare"},[_c('SpFinder',{ref:"finder",class:['shop-finder', { 'no-multiple': !_vm.multiple }],attrs:{"other-config":{
      'max-height': 460
    },"data":_vm.list,"fixed-row-action":true,"setting":{
  columns: [
    { name: 'ID', key: 'id', width: 120 },
    { name: '标题名称', key: 'title' }
  ]
}},on:{"select":_vm.onSelect,"selection-change":_vm.onSelectionChange}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }