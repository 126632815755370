<template>
  <section v-if="name === 'bottomFixed'" class="section">
    <div class="section-header with-border">设置</div>
    <div class="section-body">
      <el-form label-width="100px">
        <el-form-item label="标题">
          <el-input v-model="base.title"></el-input>
        </el-form-item>
        <el-form-item label="副标题">
          <el-input v-model="base.subtitle"></el-input>
        </el-form-item>
        <el-form-item label="楼层">
          <el-input type="text" v-model="base.floor_var"></el-input>
        </el-form-item>


        <el-form-item label="展示图">
          <div class="setting-item slider">
            <img v-if="config.imgUrl" :src="wximageurl + config.imgUrl" class="banner-uploader" @click="itemImgChange" />
            <div class="banner-uploader" v-else @click="itemImgChange">
              <i class="iconfont icon-camera"></i>上传图片
            </div>
          </div>
          <div>
            <div v-if="config.moreLink.id === 'CopyLink'" class="Copy-Link">
              <el-input v-model="config.moreLink.CopyUrl" placeholder="请输入链接"></el-input>
              <!-- <el-input v-model="config.moreLink.CopyTitle" placeholder="请输入复制文案"></el-input> -->
              <el-button icon="iconfont icon-link" class="set" @click="handleGoodsChange">设置路径</el-button>
            </div>
            <div v-else-if="config.moreLink.id === 'thirdpage'" class="thirdpage-Link">
              <el-input v-model="config.moreLink.linkTitle" placeholder="请输入跳转路径"></el-input>
              <el-input v-model="config.moreLink.linkAppid" placeholder="请输入对应小程序APPID"></el-input>
              <el-button icon="iconfont icon-link" class="set" @click="handleGoodsChange">设置路径</el-button>
            </div>
            <div v-else :class="config.moreLink.id == 'drawer-shop' ? 'uploader-setting flex' : 'uploader-setting'">
              <div class="goods-select" @click="handleGoodsChange">
                <div class="link-content" v-if="config.moreLink.id">
                  <template v-if="config.moreLink.linkPage === 'goods'">商品：</template>
                  <template v-if="config.moreLink.linkPage === 'category'">分类：</template>
                  <template v-if="config.moreLink.linkPage === 'article'">文章：</template>
                  <template v-if="config.moreLink.linkPage === 'planting'">软文：</template>
                  <template v-if="config.moreLink.linkPage === 'link'">页面：</template>
                  <template v-if="config.moreLink.linkPage === 'marketing'">营销：</template>
                  <template v-if="config.moreLink.linkPage === 'custom_page'">自定义页面：</template>
                  <template v-if="config.moreLink.linkPage === 'redpackage'">红包封面：</template>
                  <template v-if="config.moreLink.linkPage === 'platform_order_marketing'">平台下单营销：
                  </template>
                  {{ config.moreLink.title }} : {{ config.moreLink.ctoken || config.moreLink.id }}
                </div>
                <div v-else class="content-center">
                  <i class="iconfont icon-link" @click="handleGoodsChange"></i>设置路径
                </div>
              </div>
              <div class="cusdrawer-shop"
                v-if="config.moreLink.id == 'drawer-shop' && $route.path.indexOf('custompage') > -1">
                <el-select v-model="config.moreLink.rule_id" placeholder="请选择">
                  <el-option v-for="selectitem in selectShopOptions" :key="selectitem.rule_id"
                    :label="selectitem.rule_title" :value="selectitem.rule_id">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
        </el-form-item>

      </el-form>
    </div>
  </section>
</template>

<script>
import hotzone from 'vue-hotzone'
import { getShowRuleList } from '@/api/template'
import { isProxy } from 'vue';

export default {
  props: {
    res: {
      type: Object,
      default: {}
    }
  },
  watch: {
    res: {
      deep: true,
      handler(value) {
        if (value) {
          this.setData(value)
        }
      }
    },
  },
  components: {
    hotzone: hotzone
  },
  data() {
    return {
      name: '',
      base: {},
      config: {},
      data: [],
    }
  },
  methods: {
    setData(val) {
      this.name = val.name
      this.base = val.base
      this.config = val.config
      this.data = val.data
      const filter = val && val.data && val.data.filter(el => el.id == 'drawer-shop') || []
      if (filter.length > 0) {
        this.getShopList()
      }
    },
    handleGoodsChange(index) {
      this.$emit('bindLinks', index)
    },
    itemImgChange(index) {
      this.$emit('bindImgs', index)
    },
    async getShopList() {
      if (this.$route.path.indexOf('custompage') == -1) return
      const res = await getShowRuleList({ page: 1, page_size: -1 })
      const data = [
        { rule_id: '0', rule_title: '全部门店' },
        ...res.data.data.list
      ]
      this.selectShopOptions = data
    },
  },
  mounted() {
    this.setData(this.res)
  }
}
</script>

<style scoped lang="scss">
.hotzone {
  max-width: 500px;
  margin-bottom: 10px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
}

.uploader-setting {
  padding: 0;

  .cusdrawer-shop,
  .goods-select {
    flex: 1
  }

  .cusdrawer-shop {
    margin-left: 20px;

    .el-select {
      width: 100%;
    }
  }
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Copy-Link {
  width: 100%;
  display: flex;

  .set {
    margin-left: 20px;
  }
}

.thirdpage-Link {
  .el-input {
    margin-bottom: 10px;
  }
}

.tabs-li {
  padding: 40px 20px 20px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  position: relative;

  .el-form-item {
    margin-bottom: 10px;
  }

  .install {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 0 20px;
  }

  .goods {
    margin-top: 20px
  }

  .hotzone-li {
    position: relative;

    .hotzone-remove {
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 100;
    }
  }

  .setting-remove {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 100;
  }
}
</style>
