<style lang="scss">
.picker-custom-wgt {
  .btn-tool-list {
    padding: 8px 8px 0;
  }

  .item-info {
    display: flex;

    .item-image {
      width: 60px;
      margin-right: 8px;
    }

    .sp-image {
      margin-right: 10px;
    }

    .item-name {
      color: #333;
      margin-top: 4px;
    }
  }

  .el-table {
    td {
      padding: 0;
    }
  }

  .disableheadselection {
    >.cell .el-checkbox__inner {
      display: none;
    }
  }

  .el-pagination {
    margin: 0;
    padding: 10px;
  }
}
</style>
<template>
  <div class="picker-custom-wgt">
    <div class="btn-tool-list">
      <el-button type="medium" @click="handleCreate">新建</el-button>
      <el-button type="medium" @click="onSearch">刷新</el-button>
    </div>
    <SpFinder ref="finder" reserve-selection row-key="pages_template_id" :other-config="{
      'max-height': 460,
      'header-cell-class-name': cellClass
    }" url="/pagestemplate/lists?page_type=custom_module" show-pager-text="已选中：${n}" :fixed-row-action="true" :setting="{
      columns: [
        { name: '模块ID', key: 'pages_template_id', width: 80 },
        { name: 'AB实验组件', key: 'pendant_data', render: (h, { row }) => h('span', {}, setType(row.pendant_data)), width: 120 },
        { name: '模块名称', key: 'template_title' },
        {
          name: '模块封面', key: 'template_pic', render: (h, { row }) =>
            h('SpImage', {
              props: {
                src: row.template_pic,
                width: 60,
                height: 60
              }
            })
        },
      ]
    }" :hooks="{
      beforeSearch: beforeSearch,
      afterSearch: afterSearch
    }" @select="onSelect" @selection-change="onSelectionChange" :selectable="selectable" />
  </div>
</template>

<script>
import { SALES_STATUS } from '@/consts'
import BasePicker from './base'
import PageMixin from '../mixins/page'
import SpSelectShop from '@/components/sp-select-shop'
import { isObject } from '@/utils'
import { MODULETYPE } from '@/consts'

export default {
  name: 'PickerCustomWgt',
  extends: BasePicker,
  mixins: [PageMixin],
  config: {
    title: '选择自定义页面模块'
  },
  props: ['value'],
  components: { SpSelectShop },
  data() {
    const { queryParams } = this.value
    const defaultParams = {
      keywords: '',
      distributor_id: ''
    }
    const formData = Object.assign(defaultParams, queryParams)
    return {
      formData,
      salesStatus: SALES_STATUS,
      list: [],
      multipleSelection: [],
      goodsBranchList: [],
      goodsBranchParams: {
        page: 1,
        pageSize: 1000,
        attribute_type: 'brand',
        attribute_name: ''
      },
      categoryList: [],
      multiple: this.value?.multiple ?? true,
      localSelection: [],
    }
  },
  created() {
    this.localSelection = this.value.data || []
  },
  mounted() {
    this.getGoodsBranchList()
    this.getCategory()
  },
  methods: {
    setType(pendant_data) {
      if (isObject(pendant_data)) {
        return !pendant_data.is_ab ? '否' : '是'
      } else {
        return ''
      }
    },
    selectable(row) {
      if(this.value.isAb){
        return !(isObject(row.pendant_data) && row.pendant_data.is_ab)
      }else{
        return true
      }
    },
    beforeSearch(params) {
      const { pageSize: page_size, page: page_no, ...query } = params
      params = {
        page_no,
        page_size,
        ...query,
        item_type: 'normal',
        ...this.formData,
        status: 1
      }
      return params
    },
    afterSearch(response) {
      const { list } = response.data.data
      if (this.localSelection.length > 0) {
        const selectRows = list.filter((item) => this.localSelection.includes(item.pages_template_id))
        const { finderTable } = this.$refs.finder.$refs
        setTimeout(() => {
          finderTable.$refs.finderTable.setSelection(selectRows)
        })
      }
    },
    onSearch() {
      this.$refs.finder.refresh(true)
    },
    onSelect(selection, row) {
      if (!this.multiple) {
        const { finderTable } = this.$refs.finder.$refs
        console.log('finderTable:', finderTable)
        finderTable.clearSelection()
        setTimeout(() => {
          finderTable.$refs.finderTable.setSelection(selection.length > 0 ? [row] : [])
        })
      }
    },
    onSelectionChange(selection) {
      this.localSelection = []
      this.updateVal(selection)
    },
    isShowFormItem(key) {
      const { paramsFieldExclude = [] } = this.value
      return !paramsFieldExclude.includes(key)
    },
    async getGoodsBranchList(searchVal = '') {
      this.goodsBranchParams.attribute_name = searchVal
      const { list } = await this.$api.goods.getGoodsAttr(this.goodsBranchParams)
      this.goodsBranchList = list
    },
    async getCategory() {
      const res = await this.$api.goods.getCategory({ is_show: false })
      this.categoryList = res
    },
    handleCreate() {
      window.open(`${window.location.origin}/distiadmin/wxapp/manage/customTem`, '_blank')
    }
  }
}
</script>
