<style lang="scss">
.picker-coupon {
  .sp-filter-form {
    margin-bottom: 0;

    .filter-form__bd {
      margin-left: 8px;
    }
  }

  .filter-tools {
    display: flex;
    align-items: center;
    padding: 8px;

    .el-cascader,
    .el-input {
      width: 196px;
      margin-right: 8px;
    }
  }

  .sp-finder-hd {
    display: none;
  }

  .disableheadselection {
    >.cell .el-checkbox__inner {
      display: none;
    }
  }
}
</style>
<template>
  <div class="picker-coupon">
    <SpFilterForm :model="formData" size="small" @onSearch="onSearch" @onReset="onSearch">
      <SpFilterFormItem prop="promotion_name">
        <el-input v-model="formData.promotion_name" placeholder="请输入营销名称" />
      </SpFilterFormItem>
    </SpFilterForm>

    <SpFinder ref="finder" reserve-selection row-key="id" :other-config="{
      'max-height': 460,
      'header-cell-class-name': cellClass
    }" url="/navigation/promotion/list" :fixed-row-action="true" :show-pager-text="`已选中：${localSelection.length}`"
      :setting="setting" :hooks="{
        beforeSearch: beforeSearch,
        afterSearch: afterSearch
      }" @select="onSelect" @select-all="handleSelectAll" :selectable="selectable" />
  </div>
</template>

<script>
import { CARD_TYPE } from '@/consts'
import { MARKETTYPE, PLATFORM } from '@/consts'
import moment from 'moment'
import { cloneDeep } from 'lodash'
import BasePicker from './base'
import PageMixin from '../mixins/page'

export default {
  name: 'PickerNavmartket',
  extends: BasePicker,
  mixins: [PageMixin],
  config: {
    title: '选择导航营销'
  },
  props: ['value'],
  data() {
    return {
      formData: {
        id: '',
        promotion_name: ''
      },
      loading: false,
      multiple: this.value?.multiple ?? true,
      localSelection: [],
      setting: {
        columns: [
          { name: 'ID', key: 'id', width: '100px' },
          { name: '营销类型', key: 'show_type', render: (h, { row }) => h('span', MARKETTYPE.find(item => item.label == row.show_type)?.name) },
          { name: '营销名称', key: 'promotion_name' },
          {
            name: '适用平台',
            key: 'platform',
            render: (h, { row }) => h('span', PLATFORM.find(item => item.label == row.platform)?.name)
          },
        ]
      }
    }
  },
  watch: {
    localSelection: {
      handler(val) {
        this.updateVal(val)
      },
      deep: true
    }
  },
  created() {
    this.localSelection = cloneDeep(this.value.list) || []
  },
  methods: {
    selectable(row) {
      const isSelect = this.value.selectList.find(item => item.id == row.id)
      return isSelect ? false : true
    },
    beforeSearch(params) {
      params = {
        ...params,
        ...this.formData,
        page: params.page,
        page_size: params.pageSize,
        platform: this.value.platform
      }
      return params
    },
    afterSearch(response) {
      const { list } = response.data.data
      if (this.value?.list?.length) {
        // 新版本 传入list
        if (this.localSelection.length > 0) {
          const ids = this.localSelection.map((m) => m.id)
          const selectRows = list.filter((item) => ids.includes(item.id))
          const { finderTable } = this.$refs.finder.$refs
          setTimeout(() => {
            finderTable.$refs.finderTable.setSelection(selectRows)
          })
        }
      } else {
        // 旧版本 传入id集合
        if (this.value.data) {
          const selectRows = list.filter((item) => this.value?.data.includes(item.id))
          const { finderTable } = this.$refs.finder.$refs
          setTimeout(() => {
            finderTable.$refs.finderTable.setSelection(selectRows)
          })
        }
      }
    },
    onSearch() {
      this.$refs.finder.refresh()
    },
    onSelect(selection, row) {
      if (!this.multiple) {
        const { finderTable } = this.$refs.finder.$refs
        finderTable.clearSelection()
        this.localSelection = [row]
        setTimeout(() => {
          finderTable.$refs.finderTable.setSelection([row])
        })
      } else {
        const isAdd = selection.includes(row)
        const idx = this.localSelection.findIndex((f) => f.id === row.id)
        if (isAdd && idx === -1) {
          this.localSelection.push(row)
        } else {
          this.localSelection.splice(idx, 1)
        }
      }
    },
    /**
     * @description 全选只针对当前页 那么在状态改变的时候 应该不考虑未来的选中数据
     * @description 循环调用 handleFinderSelect 方法保存或删除当前页选中数据
     * @param list 当前页勾选数据 如果localSelection存在未来页数据 那么页码切换的时候 list中也会有
     */
    handleSelectAll(list) {
      // 当前页得数据
      const currentPageData = this.$refs.finder.$refs.finderTable.$refs.finderTable.list
      const currentPageDataIds = currentPageData.map((m) => m.id)
      const currentPageSelectList = list.filter((item) => currentPageDataIds.includes(item.id))
      // list 中需要排除掉未来页的数据 才能保证当前页全选或者反选
      if (currentPageSelectList.length === 0) {
        currentPageData.forEach((item) => this.onSelect([], item))
      } else {
        currentPageData.forEach((item) => this.onSelect(currentPageData, item))
      }
    },
    cardTypeFormatter({ card_type }) {
      return CARD_TYPE[card_type]
    },
    getCardValidate(beginDate, endDate) {
      return `${moment(beginDate * 1000).format('YYYY-MM-DD HH:mm:ss')} ~ ${moment(
        endDate * 1000
      ).format('YYYY-MM-DD HH:mm:ss')}`
    }
  }
}
</script>
