<template>
  <section v-if="name === 'footer_nav' && config && data" class="section">
    <div class="section-header with-border">设置</div>
    <el-form>
      <el-card shadow="never" class="custom-wrap">
        <el-form-item label-width="100px" label="背景色">
          <el-color-picker v-model="data.wrap.background"></el-color-picker>
        </el-form-item>
      </el-card>
      <el-card shadow="never" class="custom-btn">
        <el-form-item label="按钮图片">
          <div class="setting-item slider">
            <img
              v-if="config.imgUrl"
              :src="wximageurl + config.imgUrl"
              class="banner-uploader"
              @click="handleImgChange"
            />
            <div class="banner-uploader" v-else @click="handleImgChange">
              <i class="iconfont icon-camera"></i>上传图片
            </div>
          </div>
        </el-form-item>
        <el-form-item label-width="100px" label="按钮背景色">
          <el-color-picker
            v-model="data.btn.style.background"
          ></el-color-picker>
        </el-form-item>
        <el-form-item label-width="100px" label="字体颜色">
          <el-color-picker v-model="data.btn.style.color"></el-color-picker>
        </el-form-item>
        <el-form-item label-width="100px" label="按钮文案">
          <el-input v-model="data.btn.text" placeholder="请输入文案"></el-input>
        </el-form-item>
        <el-form-item label-width="100px" label="按钮宽度">
          <el-input v-model="data.btn.style.width" placeholder="请输入宽度"
            ><template slot="append">px</template></el-input
          >
        </el-form-item>
        <el-form-item label-width="100px" label="跳转">
          <el-button icon="el-icon-link" @click="handleAddLink">{{
            data.btn.linkPage ? `${data.btn.title}` : "设置链接"
          }}</el-button>
           <el-select 
              v-if="data.btn.id == 'drawer-shop' && $route.path.indexOf('custompage') > -1"
              class="cusdrawer-shop" 
              v-model="data.btn.rule_id" 
              placeholder="请选择" >
            <el-option
              v-for="selectitem in selectShopOptions"
              :key="selectitem.rule_id"
              :label="selectitem.rule_title"
              :value="selectitem.rule_id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-card>
    </el-form>
  </section>
</template>

<script>
import { getShowRuleList } from '@/api/template'
export default {
  props: {
    res: {
      type: Object,
      default: {}
    },
    platform: {
      type: String,
      default: 'wxapp'
    }
  },
  data() {
    return {
      name: '',
      base: {},
      config: {},
      data: {},
      selectShopOptions: []
    }
  },
  watch: {
    res: {
      deep: true,
      handler(value) {
        if (value) {
          this.setData(value)
        }
      }
    }
  },
  methods: {
    handleImgChange (index) {
      this.$emit('bindImgs', index)
    },
    setData(val) {
      this.name = val.name
      this.base = val.base
      this.config = val.config
      this.data = val.data

      if (val.name === 'footer_nav') {
        const isShop = val && val.data && val.data.btn.id === 'drawer-shop'
        if (isShop) {
          this.getShopList()
        }
      }
    },
    handleAddLink() {
      this.$emit('bindLinks')
    },
    async getShopList () {
      if (this.$route.path.indexOf('custompage') == -1) return
      const res = await getShowRuleList({page: 1, page_size: -1, platform: this.platform})
      const data = [
        { rule_id: '0', rule_title: '全部门店' },
        ...res.data.data.list
      ]
      this.selectShopOptions = data
    }
  },
  mounted() {
    // console.log(this.data)
    this.setData(this.res)
  }
}
</script>

<style lang="scss" scoped>
.section {
  padding: 60px 30px;
  .el-form {
    width: 500px;
  }

  .cusdrawer-shop {
    margin-left: 6px;
  }
}
</style>
