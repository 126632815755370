<template>
  <section v-if="name === 'storeRuleList'" class="sections">
    <div class="section-header with-border">设置</div>
    <div class="current-form">
      <el-form>
        <el-form-item label="展示规则">
          <el-select v-model="config.rule_id" placeholder="请选择">
            <el-option
              v-for="selectitem in selectShopOptions"
              :key="selectitem.rule_id"
              :label="selectitem.rule_title"
              :value="Number(selectitem.rule_id)"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="距离顶部高度">
          <el-input v-model="config.num" type="number" style="width: 198px;"></el-input>
        </el-form-item>
      </el-form>
    </div>
  </section>
</template>

<script>
import { getShowRuleList } from '@/api/template'
export default {
  props: {
    res: {
      type: Object,
      default: {}
    },
    platform: {
      type: String,
      default: 'wxapp'
    }
  },
  data () {
    return {
      name: '',
      base: {},
      config: {},
      data: [],
      selectShopOptions: []
    }
  },
  watch: {
    res: {
      deep: true,
      handler (value) {
        this.setData(value)
      }
    }
  },
  components: { },
  methods: {
    setData (val) {
      this.name = val.name
      this.base = val.base
      this.data = val.data
      this.config = val.config
    },
    async getShopList () {
      const res = await getShowRuleList({page: 1, page_size: -1, platform: this.platform})
      const data = [
        { rule_id: '0', rule_title: '全部门店' },
        ...res.data.data.list
      ]
      this.selectShopOptions = data
    }
  },
  mounted () {
    this.setData(this.res)
    this.getShopList()
  }
}
</script>

<style lang="scss" scoped>
.sections {
  .section-header {
    position: static;
  }
  .current-form {
    padding: 50px 30px;
  }
}
</style>

