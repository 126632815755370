var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"picker-seckill"},[_c('SpFilterForm',{attrs:{"model":_vm.formData,"size":"small"},on:{"onSearch":_vm.onSearch,"onReset":_vm.onSearch}},[_c('SpFilterFormItem',{attrs:{"prop":"name"}},[_c('el-input',{attrs:{"placeholder":"请输入活动名称"},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1)],1),_c('SpFinder',{ref:"finder",attrs:{"other-config":{
      'max-height': 460,
      'header-cell-class-name': _vm.cellClass
    },"url":"/promotions/seckillactivity/getlist","fixed-row-action":true,"setting":{
      columns: [
        { name: '活动ID', key: 'seckill_id', width: '80' },
        { name: '活动名称', key: 'activity_name' },
        {
          name: '活动时间',
          key: 'activity_start_date',
          formatter: (value, row, col) => {
            return `${row.activity_start_date} ~ ${row.activity_end_date}`
          }
        },
        {
          name: '活动状态',
          key: 'status',
          width: '160',
          formatter: (value, row, col) => {
            return this.statusList[value]
          }
        }
      ]
    },"hooks":{
      beforeSearch: _vm.beforeSearch,
      afterSearch: _vm.afterSearch
    }},on:{"select":_vm.onSelect,"selection-change":_vm.onSelectionChange}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }