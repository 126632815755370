var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"picker-afterfilm"},[_c('SpFinder',{ref:"finder",class:[{ 'no-multiple': !_vm.multiple }],attrs:{"other-config":{
    'max-height': 460,
  },"url":"/aftersales/material/real/getlist","fixed-row-action":true,"setting":{
    columns: [
      { name: 'ID', key: 'id', width: '100px' },
      {
        name: '封面图', key: 'cover_pic', render: (h, { row }) => h('el-image', {
          class: 'item-image',
          props: { src: row.cover_pic, fit: 'cover' },
        }
        )
      },
      { name: '描述', key: 'title' },
      {
        name: '素材内容', key: 'original_material', render: (h, scope) => h('video', {
          attrs: {
            src: scope.row.original_material?.material_url, controls: true
          },
          class: 'video-html'
        },
          '您的浏览器不支持 video 标签。'
        )
      }
    ]
  },"hooks":{
    beforeSearch: _vm.beforeSearch,
    afterSearch: _vm.afterSearch
  }},on:{"select":_vm.onSelect}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }