<template>
    <el-dialog class="questionnaire-select-dialog" title="选择调查问卷弹框" :visible.sync="showDialog" :close-on-click-modal="false" :before-close="cancelAction">
      <div style="margin-bottom: 15px;">
        <el-input placeholder="输入调查问卷名称" v-model="tpl_name" clearable>
          <el-button slot="append" icon="el-icon-search" @click="handleIconClick"></el-button>
        </el-input>
      </div>
      <el-table :data="tableData" tooltip-effect="dark" style="width: 100%" v-loading="loading">
        <el-table-column width="80px">
          <template slot-scope="scope">
            <el-radio
              :label="scope.row.id"
              v-model="templateRadio"
              @change.native="getTemplateRow(scope.$index,scope.row)">&nbsp;
            </el-radio>
          </template>
        </el-table-column>
        <el-table-column prop="id" label="ID" />
        <el-table-column prop="tpl_name" label="问卷标题" />
        <el-table-column prop="tpl_desc" label="问卷描述" />
        <el-table-column prop="created" label="创建时间">
          <template slot-scope="scope">
            {{scope.row.created | datetime('yyyy-MM-dd hh:mm:ss')}}
          </template>
        </el-table-column>
      </el-table>
      <div class="tr">
        <el-pagination
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :total="total_count" :page-size="pageLimit">
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
      <el-button @click="cancelAction">取 消</el-button>
      <el-button type="primary" @click="saveStoreAction">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { getQuestionnairetplList } from '@/api/wxa'
  export default {
    props: {
      getStatus: {
        type: Boolean,
        default: false
      },
      visible: {
        type: Boolean,
        default: false
      },
      radioId: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        templateRadio: '',
        loading: false,
        tableData: [],
        multipleSelection: [],
        pageLimit: 10,
        total_count: 0,
        params: {
          page: 1,
          page_size: 10,
          // scene: 5
        },
        tpl_name: '',
      }
    },
    methods: {
      getTemplateRow(index, row) {
        this.multipleSelection = new Array(row)
      },
      getCustomize() {
        getQuestionnairetplList(this.params).then(response => {
          this.tableData = response.data.data.list
          this.total_count = response.data.data.total_count
          this.loading = false
          this.multipleSelection = []
        })
      },
      handleCurrentChange(page_num) {
        this.params.page = page_num
        this.getCustomize()
      },
      handleIconClick() {
        this.params.tpl_name = this.tpl_name
        this.getCustomize()
      },
      cancelAction() {
        this.$emit('closeDialog')
      },
      saveStoreAction() {
        this.$emit('saveAction', this.multipleSelection)
      }
    },
    computed: {
      showDialog() {
        return this.visible
      }
    },
    watch: {
      visible(newVal, oldVal) {
        if (newVal) {
          this.templateRadio = this.radioId
          this.getCustomize()
        }
      },
    }
  }
</script>

<style type="text/css">
  .questionnaire-select-dialog .el-checkbox {
    display: inline;
  }
</style>
