<style lang="scss">
.picker-welfare {
  .sp-filter-form {
    padding: 8px 8px 0px 8px;
  }

  .sp-finder-hd {
    display: none;
  }

  .sp-finder {
    &.no-multiple {
      .sp-finder-bd {
        .el-table__fixed-header-wrapper {
          table thead {
            tr {
              th {
                &:nth-child(1) {
                  .el-checkbox {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }

      .el-table__fixed-body-wrapper {
        top: 38px !important;
      }
    }
  }

  .el-pagination {
    margin: 0;
    padding: 10px;
  }
}
</style>
<template>
  <div class="picker-welfare">
    <SpFinder
      ref="finder"
      row-key="id"
      :class="[{ 'no-multiple': !multiple }]"
      :other-config="{ 'max-height': 460 }"
      url="/preferential/getlist"
      :fixed-row-action="true"
      :setting="{ columns }"
      :hooks="{ beforeSearch: beforeSearch, afterSearch: afterSearch }"
      @select="handleSelectChange"
      @select-all="handleSelectAll"
      reserve-selection
    />
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import BasePicker from './base'
import dayjs from 'dayjs'
import PageMixin from '../mixins/page'
export default {
  name: 'PickerWelfare',
  extends: BasePicker,
  mixins: [PageMixin],
  config: {
    title: '选择'
  },
  props: ['value'],
  data() {
    return {
      welfareList: [],
      selection: [],
      multiple: this.value?.multiple ?? true,
      columns: [
      { name: 'ID', key: 'id' },
        // { name: '类型', key: 'typetext' },
        { name: '标题', key: 'activity_title' },
        { name: '描述', key: 'activity_desc' }
        // { name: '公司id', key: 'company_id' },
        // { name: '自定义页面id', key: 'customize_id' },
        // { name: '自定义页面名称', key: 'page_name' },
      ]
    }
  },
  watch: {
    selection: {
      handler(val) {
        this.updateVal(val)
      },
      deep: true
    }
  },
  created() {
    this.selection = cloneDeep(this.value.data)
    if (this.value.params.type == 'activity') {
      this.columns.push(
        { name: '开始时间', key: 'start_time', formmatter: (val) => this.transferTime(val) },
        { name: '结束时间', key: 'end_time', formmatter: (val) => this.transferTime(val) }
      )
    }
  },
  methods: {
    beforeSearch(params) {
      if (this.value.params) {
        params = {
          ...params,
          ...this.value.params
        }
      }
      return params
    },
    afterSearch(response) {
      const { list } = response.data.data
      if (this.selection) {
        const selectRows = list.filter((item) => this.selection.some((ele) => ele.id === item.id))
        const { finderTable } = this.$refs.finder.$refs
        setTimeout(() => {
          finderTable.$refs.finderTable.setSelection(selectRows)
        })
      }
      list.map((item) => {
        if (item.type === 'welfare') {
          item.typetext = '福利'
        }
        if (item.type === 'activity') {
          item.typetext = '门店活动'
        }
        if (item.type === 'service') {
          item.typetext = '门店服务'
        }

        if (item.type === 'selling') {
          item.typetext = '热卖'
        }
      })
      this.welfareList = list
      return list
    },
    onSearch() {
      this.$refs.finder.refresh(true)
    },
    handleSelectChange(selection, row) {
      // 判断是否是多选
      if (this.multiple) {
        // 查找当前行是否在已选择项中
        let inChecked = this.selection.findIndex((item) => row.id === item.id)
        // 如果存在，则从已选择项中移除
        if (inChecked !== -1) {
          this.selection.splice(inChecked, 1)
        } else {
          this.selection.push(row)
        }
      } else {
        // 获取finderTable的引用
        const { finderTable } = this.$refs.finder.$refs
        // 清除已选择项
        finderTable.clearSelection()
        // 设置延时，让选择项渲染出来
        setTimeout(() => {
          finderTable.$refs.finderTable.setSelection([row])
        })
      }
    },
    transferTime(val) {
      return dayjs(val * 1000).format('YYYY-MM-DD HH:mm:ss')
    },
    async handleSelectAll(val) {
      if (val.length > 0) {
        await val.forEach((item) => {
          if (!this.selection.some((ele) => ele.id === item.id)) {
            this.selection.push(item)
          }
        })
      } else {
        await this.welfareList.forEach((item) => {
          const index = this.selection.findIndex((ele) => ele.id === item.id)
          if (index != -1) {
            this.selection.splice(index, 1)
          }
        })
      }

      this.updateVal(this.selection)
    }
  }
}
</script>
