<style lang="scss">
.picker-offlineactivity {
  .sp-filter-form {
    padding: 8px 8px 0px 8px;
  }

  .sp-finder-hd {
    display: none;
  }

  .disableheadselection {
    >.cell .el-checkbox__inner {
      display: none;
    }
  }

  .el-pagination {
    margin: 0;
    padding: 10px;
  }
}
</style>
<template>
  <div class="picker-offlineactivity">
    <SpFilterForm :model="formData" size="small" @onSearch="onSearch" @onReset="onSearch">
      <SpFilterFormItem prop="activity_id">
        <el-input v-model="formData.activity_id" placeholder="请输入活动ID" />
      </SpFilterFormItem>
      <SpFilterFormItem prop="activity_title">
        <el-input v-model="formData.activity_title" placeholder="请输入活动标题" />
      </SpFilterFormItem>
    </SpFilterForm>
    <SpFinder ref="finder" :other-config="{
      'max-height': 460,
      'header-cell-class-name': cellClass
    }" row-key="activity_id" reserve-selection url="/promotions/activity/exchange/lists" :fixed-row-action="true"
      :setting="{
        columns: [
          { name: 'ID', key: 'activity_id', width: 80 },
          { name: '活动名称', key: 'activity_title' }
        ]
      }" :hooks="{
        beforeSearch: beforeSearch,
        afterSearch: afterSearch
      }" @select="onSelect" @selection-change="onSelectionChange" />
  </div>
</template>

<script>
import BasePicker from './base'
import PageMixin from '../mixins/page'
export default {
  name: 'PickOrderToGift',
  extends: BasePicker,
  mixins: [PageMixin],
  config: {
    title: '选择订单兑礼'
  },
  props: ['value'],
  data() {
    return {
      formData: {
        activity_title: '',
        activity_id: ''
      },
      multiple: this.value?.multiple ?? true,
    }
  },
  created() {

   },
  methods: {
    beforeSearch(params) {
      params = {
        ...params,
        ...this.formData
      }
      if (this.value.params) {
        params = {
          ...params,
          ...this.value.params
        }
      }
      return params
    },
    afterSearch(response) {
      const { list } = response.data.data
      if (this.value.data) {
        const selectRows = list.filter((item) => this.value.data.includes(item.activity_id))
        const { finderTable } = this.$refs.finder.$refs || {}
        setTimeout(() => {
          finderTable.$refs.finderTable.setSelection(selectRows)
        })
      }
    },
    onSearch() {
      this.$refs.finder.refresh(true)
    },
    onSelect(selection, row) {
      if (this.multiple) {
      } else {
        const { finderTable } = this.$refs.finder.$refs
        console.log('finderTable:', finderTable)
        finderTable.clearSelection()
        setTimeout(() => {
          finderTable.$refs.finderTable.setSelection([row])
        })
      }
    },
    onSelectionChange(selection) {
      this.updateVal(selection)
    }
  }
}
</script>
