<template>
  <div class="links">
    <ul>
      <template v-for="item in links">
        <li
          class="links-item"
          :key="item.value"
          v-if="item.value !== 'store'"
          :class="current === item.value && 'active'"
          @click="handleClick(item.value)"
        >
          {{ item.label }}
        </li>
        <li
          class="links-item"
          :key="item.value"
          v-if="item.value === 'store' && system_mode === 'platform'"
          :class="current === item.value && 'active'"
          @click="handleClick(item.value)"
        >
          {{ item.label }}
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import store from '@/store'
export default {
  props: {
    type: {
      type: String,
      default: ''
    },
    showlinks: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      links: [{
        label: '商品',
        value: 'goods'
      },
      {
        label: '店铺',
        value: 'store'
      },
      {
        label: '分类',
        value: 'category'
      },
      {
        label: '标签',
        value: 'tag'
      },
      {
        label: '文章',
        value: 'article'
      },
      {
        label: '软文',
        value: 'planting'
      },
      {
        label: '页面',
        value: 'link'
      },
      {
        label: '营销',
        value: 'marketing'
      },
      {
        label: '平台下单营销',
        value: 'platform_order_marketing'
      },
      {
        label: '活动报名',
        value: 'regactivity'
      },
      {
        label: '秒杀',
        value: 'seckill'
      },
      {
        label: '自定义页面',
        value: 'custom_page'
      },
      {
        label: '优惠券',
        value: 'coupon'
      },
      {
        label: '直播',
        value: 'liverooms'
      },
      {
        label: '弹窗',
        value: 'modal'
      },
      {
        label: '红包封面',
        value: 'redpackage'
      },
      {
        label: '门店活动',
        value: 'offlineactivity'
      },
      {
        label: '特殊功能',
        value: 'special'
      },
      {
        label: '机型对比',
        value: 'compare'
      },
      {
        label: '抽奖',
        value: 'lottery'
      },
      {
        label: '我的',
        value: 'member'
      },{
        label: '产品分类',
        value: 'classification'
      },{
        label: '订单兑礼',
        value: 'ordertogift'
      }
      ],
      current: ''
    }
  },
  created () {
    if (this.showlinks.length) {
      this.links = this.links.filter(item => {
        return this.showlinks.includes(item.value)
      })
    }
  },
  watch: {
    type (val) {
      this.current = val
    }
  },
  methods: {
    handleClick (val) {
      this.$emit('onClick', val)
      this.current = val
    }
  },
  mounted () {
    if (this.$route.path === '/store/storemanager/marketingdistributor') {
      let index = this.links.findIndex((n) => n.value == 'custom_page')
      this.links.splice(index, 1)
    }
    this.current = this.type
  }
}
</script>

<style scoped lang="scss">
.links {
  padding-right: 20px;
  width: 180px;
  border-right: 1px solid #efefef;
  .links-item {
    padding: 10px 0;
    text-align: center;
    cursor: pointer;
    &.active {
      background: #ecf5ff;
      border: 1px solid #b4d9fd;
      border-radius: 60px;
      color: #1f82e0;
    }
  }
}
</style>
