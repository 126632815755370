<template>
  <div class="component-wrap" :class="{'component-padded' : base.padded, 'active': active}">
    <div class="current-active"></div>
    <div class="current-active"></div>
    <div v-if="base.title" class="component-header">
      <div class="component-title">
        <div>{{ base.title }}</div>
        <div class="subtitle">{{ base.subtitle }}</div>
      </div>
    </div>
    <div class="rule-list">
      <div class="rule-item" v-for="(item, index) of [{},{}]" :key="index">
        <div class="rule-item-left">
          <div class="bg"></div>
        </div>
        <div class="rule-item-right">
          <p class="store-name">店铺名称</p>
          <p class="address">店铺位置</p>
          <p class="distance_text">店铺距离</p>
          <p class="rule-item-label">
            <span>活动标签</span>
            <span>活动标签</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    res: {
      type: Object,
      default: {}
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      base: {},
      data: []
    }
  },
  watch: {
    res: {
      deep: true,
      handler (value) {
        this.setData(value)
      }
    }
  },
  methods: {
    setData (val) {
      this.base = val.base
      this.data = val.data
    }
  },
  mounted () {
    this.setData(this.res)
  }
}
</script>
<style lang="scss" scoped>
.component-wrap {
  width: 320px;
  margin: 0 auto;
  .component-header {
    font-size: 18px;
    font-weight: bold;
    padding: 0;
    margin-bottom: 5px;
  }
}
.rule-list{
  .rule-item{
    display: flex;
    margin-bottom: 10px;
    &-left{
      width: 80px;
      .bg{
        width: 60px;
        height: 60px;
        margin: 10px;
        background: #929292;
      }
    }
    &-right{
      flex: 1;
      p{

      }
      .store-name{
        color: #000;
        font-size: 18px;
      }
      .address{
        color: #929292;
        line-height: 14px;
      }
      .distance_text{
        color: #000;
        font-size: 12px;
      }
      .rule-item-label{
        span{
          display: inline-block;
          height: 20px;
          line-height: 20px;
          border: 1px solid #1d1d1f;
          color: #1d1d1f;
          font-size: 10px;
        }
      }
    }
  }
}
</style>
