<template>
  <div class="pii-info" v-if="login_type == 'distributor'">
    <el-button type="text" @click="onPrivacyVisible()">查看</el-button>
    <el-dialog v-loading="loading" :modal="false" element-loading-background="rgba(0, 0, 0, 0.5)"
      :title="dialogPrivacyTitle" :visible.sync="dialogPrivacyVisible" width="20%">
      <el-alert type="info" :closable="false" center>{{ member_type_text }}</el-alert>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getPiInfo } from '@/api/piiInfo'

export default {
  props: {
    pageType: {
      type: String,
      default: ''
    },
    associatedId: {
      type: String,
      default: ''
    },
    memberType: {
      type: String,
      default: ''
    },
    optionScene: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters(['login_type']),
  },
  data() {
    return {
      dialogPrivacyVisible: false,
      dialogPrivacyTitle: '',
      imfo: [],
      loading: false,
      member_type_text: '',
    }
  },
  methods: {
    onPrivacyVisible() {
      if (this.memberType == 'receiver_mobile') {
        this.dialogPrivacyTitle = '查看联系方式'
      } else if (this.memberType == 'receiver_address') {
        this.dialogPrivacyTitle = '查看地址'
      } else if (this.memberType == 'receiver_name') {
        this.dialogPrivacyTitle = '查看用户名'
      }
      this.dialogPrivacyVisible = true
      this.getPiiInfo()
    },
    getPiiInfo() {
      this.loading = true
      let params = {
        associated_id: this.associatedId,
        option_scene: this.optionScene,
        type: this.pageType
      }
      getPiInfo(params).then(res => {
        let info = res.data.data
        this.imfo = info
        this.memberTypeText(info)
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.dialogPrivacyVisible = false
      })
    },
    memberTypeText(list) {
      let text = ''
      list.forEach((item) => {
        if (this.memberType == 'receiver_mobile' && (item.fieldName == 'receiver_mobile' || item.fieldName == 'MOBILE')) {
          text = item.fieldPlaintext
        } else if (this.memberType == 'receiver_name' && (item.fieldName == 'receiver_name' || item.fieldName == 'USERNAME')) {
          text = item.fieldPlaintext
        } else if (this.memberType == 'receiver_address' && (item.fieldName == 'receiver_address' || item.fieldName == 'ADDRESS')) {
          text = item.fieldPlaintext
        } else if (this.memberType == 'receiver_address' && item.fieldName == 'house_number')
          text += text = item.fieldPlaintext
      })
      this.member_type_text = text
    }
  },
  watch: {
    type(newVal, oldVal) {

    }
  }
}
</script>

<style scoped lang="scss">
.pii-info {
  display: inline-block;

  .el-button {
    padding: 0;
  }
}
</style>
