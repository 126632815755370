<template>
  <div class="co-fold-block">
    <div class="co-fold-block-title">
      <img
          src="./fold-expand.png"
          :style="{ transform: fold ? 'rotate(0deg)' : 'rotate(-90deg)' }"
          @click="fold = !fold"
          alt
      />
      <span>{{ title }}</span>
    </div>
    <div v-show="fold" class="co-fold-block-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'co-fold-block',
  props: {
    title: String
  },
  data () {
    return {
      fold: true
    }
  }
}
</script>

<style lang="scss" scoped>
.co-fold-block {
  background: #ffffff;
  border-radius: 2px;
  box-sizing: border-box;
  margin-bottom: 24px;
  box-shadow: 0 2px 4px 0 #dfe7f3;
  &-title {
    height: 54px;
    box-sizing: border-box;
    border-bottom: 1px solid #e8e8e8;
    display: flex;
    align-items: center;
    padding-left: 4px;
    img {
      cursor: pointer;
      width: 20px;
      margin-right: 5px;
    }
    span {
      color: #111111;
      font-size: 14px;
      font-weight: 500;
    }
  }
  &-content {
    box-sizing: border-box;
    padding: 24px;
  }
}
</style>
