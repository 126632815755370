<template>
  <section v-if="name === 'film'" class="section comp-film">
    <div class="section-header with-border">设置</div>
    <div class="section-body">
      <el-form label-width="100px">
        <el-form-item label="标题">
          <el-input v-model="base.title"></el-input>
        </el-form-item>
        <el-form-item label="副标题">
          <el-input v-model="base.subtitle"></el-input>
        </el-form-item>
        <el-form-item label="资源位名称">
          <el-input type="text" v-model="base.flowName_var"></el-input>
        </el-form-item>
        <el-form-item label="楼层">
          <el-input type="text" v-model="base.floor_var"></el-input>
        </el-form-item>
        <el-form-item label="组件间距">
          <el-switch
            v-model="base.padded"
            active-color="#27cc6a"
            inactive-color="#efefef"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="视频封面" v-if="config">
          <img v-if="base.video_cover" :src="base.video_cover" class="video-uploader" @click="handleImgChange('backImg')" />
          <div v-else class="icon-uploader" @click="handleImgChange('backImg')">
            <i class="iconfont icon-camera"></i>
          </div>
        </el-form-item>
        <el-form-item label="自动播放" v-if="config">
          <el-switch
            v-model="config.autoplay"
            active-color="#27cc6a"
            inactive-color="#efefef"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="选择视频">
          <Videoselect
            @change="handleVideoChange"
            :data="data[0]"
          ></Videoselect>
        </el-form-item>
      </el-form>
    </div>
  </section>
</template>

<script>
import Videoselect from '@/components/videoselect/index'

export default {
  props: {
    res: {
      type: Object,
      default: {}
    }
  },
  watch: {
    res: {
      deep: true,
      handler (value) {
        if (value) {
          this.setData(value)
        }
      }
    }
  },
  data () {
    return {
      name: '',
      base: {},
      config: {},
      data: []
    }
  },
  components: {
    Videoselect
  },
  methods: {
    setData (val) {
      this.name = val.name
      this.base = val.base
      this.data = val.data
      this.config = val.config
    },
    handleVideoChange (data) {
      this.$emit('change', data)
    },
    handleImgChange (index) {
      this.$emit('bindImgs', index)
    },
  },
  mounted () {
    this.setData(this.res)
  }
}
</script>

<style scoped lang="scss">
.comp-film {
  .video-uploader {
    width: 120px;
    height: 120px;
  }
  .icon-uploader {
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
    border-radius: 6px;
    border: 1px dashed #c0ccda;
    .icon-camera {
      font-size: 30px;
      color: #ccc;
    }
  }
}
</style>
