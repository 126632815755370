<style lang="scss">
.picker-lottery {
  .sp-filter-form {
    padding: 8px 8px 0px 8px;
  }

  .sp-finder-hd {
    display: none;
  }

  .disableheadselection {
    >.cell .el-checkbox__inner {
      display: none;
    }
  }

  .el-pagination {
    margin: 0;
    padding: 10px;
  }
}
</style>
<template>
  <div class="picker-lottery">
    <SpFinder ref="finder" :other-config="{
      'max-height': 460,
      'header-cell-class-name': cellClass
    }" row-key="marketing_id" reserve-selection :data="data" :fixed-row-action="true" :setting="{
      columns: [
        { name: 'ID', key: 'id', width: 80 },
        { name: '标题名称', key: 'title' }
      ]
    }" @select="onSelect" @selection-change="onSelectionChange" />
  </div>
</template>

<script>
import BasePicker from './base'
import PageMixin from '../mixins/page'
export default {
  name: 'PickerMember',
  extends: BasePicker,
  mixins: [PageMixin],
  config: {
    title: '选择抽奖'
  },
  props: ['value'],
  data() {
    return {
      multiple: this.value?.multiple ?? true,
      data: [{
        title: '我的订单',
        id: 'order'
      }, {
        title: '我的卡券',
        id: 'coupon'
      }, {
        title: '我的配送地址',
        id: 'shippingAdress',
      }, {
        title: '客服咨询',
        id: 'customerService',
      }, {
        title: '设置',
        id: 'setting',
      }, {
        title: '我的活动',
        id: 'myActive',
      }, {
        title: '我的私教',
        id: 'myCoach',
      }, {
        title: '我的服务',
        id: 'myService',
      }, {
        title: '关于我们',
        id: 'customPage',
      }]
    }
  },
  mounted() {
    if (this.value.data) {
      const selectRows = this.data.filter((item) => this.value.data.includes(item.id))
      const { finderTable } = this.$refs.finder.$refs
      setTimeout(() => {
        finderTable.$refs.finderTable.setSelection(selectRows)
      })
    }
   },
  methods: {
    onSelect(selection, row) {
        const { finderTable } = this.$refs.finder.$refs
        console.log('finderTable:', finderTable)
        finderTable.clearSelection()
        setTimeout(() => {
          finderTable.$refs.finderTable.setSelection([row])
        })
    },
    onSelectionChange(selection) {
      this.updateVal(selection)
    }
  }
}
</script>
