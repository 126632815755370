<template>
  <section v-if="name === 'heading'" class="section">
    <div class="section-header with-border">
      设置
    </div>
    <div class="section-body">
      <el-form label-width="100px">
        <el-form-item label="组件间距">
          <el-switch
            v-model="base.padded"
            active-color="#27cc6a"
            inactive-color="#efefef">
          </el-switch>
        </el-form-item>
        <el-form-item label="加粗">
          <el-switch
            v-model="config.bold"
            active-color="#27cc6a"
            inactive-color="#efefef">
          </el-switch>
        </el-form-item>
        <el-form-item label="颜色">
          <el-color-picker v-model="config.color"></el-color-picker>
        </el-form-item>
        <el-form-item label="布局">
          <el-radio-group v-model="config.align">
            <el-radio label="left">居左</el-radio>
            <el-radio label="center">居中</el-radio>
            <el-radio label="right">居右</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="字号">
          <el-select v-model="config.size" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item"
              :label="item + '号'"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="斜体">
          <el-switch
            v-model="config.italic"
            active-color="#27cc6a"
            inactive-color="#efefef">
          </el-switch>
        </el-form-item>
        <el-form-item label="标题">
          <el-input type="text" placeholder="请输入内容" v-model="data[0].content"></el-input>
        </el-form-item>
      </el-form>
    </div>
  </section>
</template>

<script>
  export default {
    props: {
      res: {
        type: Object,
        default: {}
      }
    },
    watch: {
      res: {
        deep: true,
        handler (value) {
          if (value) {
            this.setData(value)
          }
        }
      }
    },
    data () {
      return {
        name: '',
        base: {},
        config: {},
        data: [],
        options: ['24','18','16','14']
      }
    },
    methods: {
      setData(val) {
        this.name = val.name
        this.base = val.base
        this.config = val.config
        this.data = val.data
      }
    },
    mounted() {
      this.setData(this.res)
    }
  }
</script>

<style scoped lang="scss">

</style>
