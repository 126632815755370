export const dragIssuesOptions = {
  animation: 300,
  forceFallback: false,
  scroll: true,
  handle: ".icon-arrows-alt",
  draggable: ".goodspic",
};

export const LINK_PATH = {
  goods: "商品",
  // 'store': '店铺',
  // 'sale_category': '销售分类',
  category: "分类",
  tag: "标签",
  article: "文章",
  planting: "软文",
  // 'link': '页面',
  marketing: "营销",
  regactivity: "活动报名",
  seckill: "秒杀",
  link: "页面",
  custom_page: "自定义页面",
  coupon: "优惠券",
  liverooms: "直播",
  modal: "弹窗",
  redpackage: "红包封面",
  offlineactivity: "门店活动",
  special: "特殊功能",
  compare: "机型对比",
  lottery: "抽奖",
  member: "我的",
  classification: "产品分类",
  subscribeMessage: "订阅消息",
  ordertogift: '订单兑礼'
  // 'other_wxapp': '外部小程序'
};
export const CARD_TYPE = {
  discount: "折扣券",
  cash: "满减券",
  new_gift: "兑换券",
};

export const SECKILL_ACTIVITY_STATUS = {
  waiting: "待开始",
  in_the_notice: "预热中",
  in_sale: "进行中",
  it_has_ended: "已结束",
};

export const SALES_STATUS = [
  { title: "前台可销售", value: "onsale" },
  { title: "前台不展示", value: "offline_sale" },
  { title: "前台仅展示", value: "only_show" },
  { title: "不可销售", value: "instock" },
];

export const EXAMINE_STATUS_TYPE = {
  1: "info",
  2: "",
  3: "info",
  4: "success",
};
export const EXAMINE_STATUS = {
  1: "待提交",
  2: "待审核",
  3: "审核未通过",
  4: "审核通过",
  5: "取消审核",
};
export const CUSTOMPAGE_TYPE = [
  {
    value: "useterm",
    label: "使用条款",
  },
  {
    value: "private",
    label: "隐私政策",
  },
  {
    value: "ecprivate",
    label: "员工购优惠规则",
  },
  {
    value: "register_vip_private",
    label: "会员隐私协议",
  },
];
export const PAGE_TYPE = [
  {
    label: "我的",
    value: "member",
  },
  {
    label: "分类",
    value: "category",
  }, {
    label: "找帮助",
    value: "findHelp",
  },
  // {
  //   label: "购物袋",
  //   value: "cart",
  // },
];

export const PAGE_SENCE = {
  category: 1009,
  member: 1010,
  // cart: 1011
};

export const PAGE_SENCE_NUMBER = {
  1009: "category",
  1010: "member",
  // 1011: "cart"
};
export const proportions = [
  {
    label: 0,
    name: '16:9',
    ratio: 16 / 9
  },
  {
    label: 1,
    name: '9:16',
    ratio: 9 / 16
  },
  {
    label: 2,
    name: '4:3',
    ratio: 4 / 3
  },
  {
    label: 3,
    name: '3:4',
    ratio: 3 / 4
  },
  {
    label: 4,
    name: '1:1',
    ratio: 1
  }
]
export const sliderproportion = [
  {
    label: 0,
    name: '自适应',
  },
  {
    label: 1,
    name: '全屏',
  }
]

export const MODULETYPE = {
  is_ab: '内容分区模板',
  is_abtest: '内容分区模板',
  is_contentpart: 'ab实验模板',
  is_common: '内容分区模板、ab实验模板',
}

export const DEVICEOPTION = [
  {
    label: 'iPhone',
    value: 'iPhone'
  },
  {
    label: 'iPad',
    value: 'iPad'
  },
  {
    label: 'Mac',
    value: 'Mac'
  },
  {
    label: 'Watch',
    value: 'Watch'
  },
]

export const PATHOPTIONS = [{
  value: '/pages/index',
  name: 'home',
  label: '首页'
},
{
  value: '/pages/cart/espier-index',
  name: 'cart',
  label: '购物车'
},
{
  value: '/pages/classification/index',
  name: 'category',
  label: '分类'
},
{
  value: '/pages/member/index',
  name: 'member',
  label: '我的'
},
{
  value: '/pages/store/map-store',
  name: 'store-map',
  label: '门店'
},
{
  value: '/pages/recommend/list',
  name: 'article',
  label: '软文'
},
{
  value: '/pages/item/list?isTabBar=true',
  name: 'allGoods',
  label: '全部商品'
},
{
  value: '/pages/findhelp/index',
  name: 'findHelp',
  label: '找帮助'
}]
export const DYPATHOPTIONS = [{
  value: '/pages/index/index',
  name: 'home',
  label: '首页'
},
{
  value: '/pages/member/index',
  name: 'member',
  label: '我的'
},
{
  value: '/pages/store/map-store',
  name: 'store-map',
  label: '门店'
}]


export const MARKETTYPE = [{
  label: 1,
  name: '气泡'
}]

export const PLATFORM = [{
  name: '微信',
  label: 'wxapp'
}, {
  name: '抖音',
  label: 'byte_mini'
}]

export const DEFAULTTABBAR = [{
  "pagePath": "/pages/index",
  "text": "首页",
  "name": "home",
  "iconPath": "https://cdn-image.tjtjshengtu.com/image/1/2024/01/16/5107759d4d6a1f88eab472f3e3bcc91eJBmEDWMTU6WogQqtc0seuF2ZZemZnTxU",
  "selectedIconPath": "https://cdn-image.tjtjshengtu.com/image/1/2024/02/18/52b4e8e2e9470e5cf32b2130f1ded149c1HORMhy96E58jR9WKFS6s0TkTPAZCWK",
  "platformType": []
},
{
  "pagePath": "/pages/classification/index",
  "text": "产品分类",
  "name": "category",
  "iconPath": "https://cdn-image.tjtjshengtu.com/image/1/2024/01/16/5107759d4d6a1f88eab472f3e3bcc91eS01OHt2U8R8kD0EGwWHvzhNvSspfxPlm",
  "selectedIconPath": "https://cdn-image.tjtjshengtu.com/image/1/2024/02/18/52b4e8e2e9470e5cf32b2130f1ded149BID7qofeBJhRddReYROmGzrvhjgqPNWU",
  "platformType": []
},
{
  "pagePath": "/pages/store/map-store",
  "text": "探索门店",
  "name": "store-map",
  "iconPath": "https://cdn-image.tjtjshengtu.com/image/1/2024/08/01/f811fbbbe267d513ab0c1a370ba57738c7CdMvAA1XLJjh04pajR6fJXVSFmEHWX",
  "selectedIconPath": "https://cdn-image.tjtjshengtu.com/image/1/2024/08/01/f811fbbbe267d513ab0c1a370ba57738Oj1gD8KlkC7v1t9DVYM6a2gjog1tdjds"
},
{
  "pagePath": "/pages/member/index",
  "text": "我的",
  "name": "member",
  "iconPath": "https://cdn-image.tjtjshengtu.com/image/1/2024/08/01/f811fbbbe267d513ab0c1a370ba57738vrTKktLYX0nyzUzkeX1sbkk2DZWZy0Eo",
  "selectedIconPath": "https://cdn-image.tjtjshengtu.com/image/1/2024/08/01/f811fbbbe267d513ab0c1a370ba577386xNTCHyoNtMNeHkw5AdlY6noJ8fuxhFu"
}]

export const DEFAULTDYTABBAR = [{
  "pagePath": "/pages/index/index",
  "text": "首页",
  "name": "home",
  "iconPath": "https://cdn-image.tjtjshengtu.com/image/1/2024/01/16/5107759d4d6a1f88eab472f3e3bcc91eJBmEDWMTU6WogQqtc0seuF2ZZemZnTxU",
  "selectedIconPath": "https://cdn-image.tjtjshengtu.com/image/1/2024/02/18/52b4e8e2e9470e5cf32b2130f1ded149c1HORMhy96E58jR9WKFS6s0TkTPAZCWK",
  "platformType": []
},
{
  "pagePath": "/pages/store/map-store",
  "text": "探索门店",
  "name": "store-map",
  "iconPath": "https://cdn-image.tjtjshengtu.com/image/1/2024/08/01/f811fbbbe267d513ab0c1a370ba57738c7CdMvAA1XLJjh04pajR6fJXVSFmEHWX",
  "selectedIconPath": "https://cdn-image.tjtjshengtu.com/image/1/2024/08/01/f811fbbbe267d513ab0c1a370ba57738Oj1gD8KlkC7v1t9DVYM6a2gjog1tdjds"
},
{
  "pagePath": "/pages/member/index",
  "text": "我的",
  "name": "member",
  "iconPath": "https://cdn-image.tjtjshengtu.com/image/1/2024/08/01/f811fbbbe267d513ab0c1a370ba57738vrTKktLYX0nyzUzkeX1sbkk2DZWZy0Eo",
  "selectedIconPath": "https://cdn-image.tjtjshengtu.com/image/1/2024/08/01/f811fbbbe267d513ab0c1a370ba577386xNTCHyoNtMNeHkw5AdlY6noJ8fuxhFu"
}]