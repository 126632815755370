<template>
  <section v-if="name === 'oldEvaluate'" class="section">
    <div class="section-header with-border">设置</div>
    <div class="section-body">
      <el-form label-width="100px">
        <el-form-item label="楼层">
          <el-input type="text" v-model="base.floor_var"></el-input>
        </el-form-item>
        <el-form-item label="组件间距">
          <el-switch v-model="base.padded" active-color="#27cc6a" inactive-color="#efefef">
          </el-switch>
        </el-form-item>
        <el-form-item :label="item.label" v-for="(item, index) in data" :key="index + 'label'">
          <div class="setting-item">
            <div class="setting-remove" @click="removeItem(index)">
              <i class="iconfont icon-trash-alt"></i>
            </div>
            <img v-if="item.imgUrl" :src="wximageurl + item.imgUrl" class="banner-uploader square"
              @click="handleImgChange(index)" />
            <div class="banner-uploader square" v-else @click="handleImgChange(index)">
              <div class="content-center">
                <i class="iconfont icon-camera"></i><br />
                上传图片
              </div>
            </div>
            <div class="uploader-setting">


              <div v-if="item.id === 'CopyLink'" class="Copy-Link">
                <el-input v-model="item.CopyUrl" placeholder="请输入链接"></el-input>
                <!-- <el-input v-model="item.CopyTitle" placeholder="请输入复制文案"></el-input> -->
                <el-button icon="iconfont icon-link" class="set" @click="handleGoodsChange(index)">设置路径</el-button>
              </div>
              <div v-else-if="item.id === 'thirdpage'" class="thirdpage-Link">
                <el-input v-model="item.linkTitle" placeholder="请输入跳转路径"></el-input>
                <el-input v-model="item.linkAppid" placeholder="请输入对应小程序APPID"></el-input>
                <el-form-item label="是否半屏跳转">
                  <el-radio-group v-model="item.linkProgram">
                    <el-radio label="1">否</el-radio>
                    <el-radio label="2">是</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-button icon="iconfont icon-link" class="set" @click="handleGoodsChange(index)">设置路径</el-button>
              </div>
              <div v-else class="goods-select" @click="handleGoodsChange(index)">
                <div class="link-content" v-if="item.id">
                  <template v-if="item.linkPage === 'goods'">商品：</template>
                  <template v-if="item.linkPage === 'category'">分类：</template>
                  <template v-if="item.linkPage === 'article'">文章：</template>
                  <template v-if="item.linkPage === 'planting'">软文：</template>
                  <template v-if="item.linkPage === 'link'">页面：</template>
                  <template v-if="item.linkPage === 'marketing'">营销：</template>
                  {{ item.title }}
                </div>
                <div v-else class="content-center">
                  <i class="iconfont icon-link" @click="handleGoodsChange(index)"></i>设置路径
                </div>
              </div>
            </div>
          </div>
          <div class="frm-tips">
            只能上传jpg/png文件，且不超过2M （建议尺寸：375px * 200px）
          </div>
          <!-- <el-button
            :disabled="data.length >= 5"
            type="default"
            class="iconfont icon-plus-circle banner-button-uploader"
            @click="addItem"
          >
            添加项目</el-button
          > -->
        </el-form-item>
      </el-form>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    res: {
      type: Object,
      default: () => { return {} }
    }
  },
  watch: {
    res: {
      deep: true,
      handler(value) {
        if (value) {
          this.setData(value)
        }
      }
    }
  },
  data() {
    return {
      name: '',
      base: {},
      config: {},
      data: []
    }
  },
  methods: {
    setData(val) {
      this.name = val.name
      this.base = val.base
      this.config = val.config
      this.data = val.data
    },
    removeItem(index) {
      this.data.splice(index, 1)
    },
    addItem() {
      if (!this.data) {
        this.data = []
      }
      let item = {
        imgUrl: '',
        linkPage: '',
        id: ''
      }
      if (this.data.length > 4) {
        this.$message({
          message: '轮播图最多添加5个图片',
          type: 'error',
          duration: 5 * 1000
        })
      } else {
        this.data.push(item)
      }
    },
    handleImgChange(index) {
      this.$emit('bindImgs', index)
    },
    handleGoodsChange(index) {
      this.$emit('bindLinks', index)
    }
  },
  mounted() {
    this.setData(this.res)
  }
}
</script>

<style scoped lang="scss"></style>
