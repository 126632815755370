<template>
  <el-dialog width="75%" class="store-dialog" :title="isSynchronize ? '同步模板到店铺' : '选择店铺'" :visible.sync="showDialog" :close-on-click-modal="false" :before-close="cancelAction" append-to-body >
    <div style="margin-bottom: 15px;">
      <el-input
        style="width: 200px"
        placeholder="AppleId"
        v-model="shop_code"
        clearable>
      </el-input>
      <el-input
        style="width: 200px"
        :placeholder="'输入店铺名称'"
        v-model="name"
        clearable>
      </el-input>
      <el-button icon="el-icon-search" @click="handleIconClick" type="primary">搜索</el-button>
    </div>
    <el-table ref="multipleTable" :data="storeData" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange" v-loading="loading" :row-key="getRowKeys">
      <el-table-column type="selection" :reserve-selection="true" width="50"></el-table-column>
      <el-table-column prop="distributor_id" label="ID" width="90"></el-table-column>
      <el-table-column prop="shop_code" label="门店AppleId" width="120"></el-table-column>
      <el-table-column prop="mobile" label="联系方式" width="120"></el-table-column>
      <el-table-column prop="name" label="店铺名称"></el-table-column>
      <el-table-column v-if="showStatus" prop="is_valid" label="门店状态">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.is_valid == 'true'">启用</el-tag>
          <el-tag type="warning" v-else-if="scope.row.is_valid == 'false'">禁用</el-tag>
          <el-tag type="error" v-else>废弃</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="地址" show-overflow-tooltip></el-table-column>
    </el-table>
    <div v-if="total_count > params.pageSize" style="text-align: center; margin-top: 20px" class="tr">
      <el-pagination
        layout="sizes, prev, pager, next"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[100, 200, 300, 400, 500]"
        :total="total_count"
        :page-size="params.pageSize">
      </el-pagination>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancelAction">取 消</el-button>
      <el-button type="primary" v-if="!isSynchronize" @click="saveStoreAction">确 定</el-button>
      <el-button type="primary" v-else @click="saveStoreAction">确 定 同 步</el-button>
      <el-button type="primary" v-if="isSynchronize" @click="saveAllStoreAction">同 步 所 有 门 店</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getDistributorList } from '@/api/marketing'
  export default {
    props: {
      relDataIds: {
        type: Array,
        default: function(){
          return []
        }
      },
      getStatus: {
        type: Boolean,
        default: false
      },
      showStatus: {
        type: Boolean,
        default: false
      },
      isValid: {
        type: Boolean,
        default: false
      },
      storeVisible: {
        type: Boolean,
        default: false
      },
      sourceType: {
        type: String,
        default: ''
      },
      platform: {
        type: String,
        default: ''
      },
      distributorValid: {
        type: String,
        default: ''
      },
      isSynchronize: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        dataType: 'distributor',
        loading: false,
        storeData: [],
        multipleSelection: [],
        pageLimit: 100,
        total_count: '',
        params: {
          page: 1,
          pageSize: 100,
          platform: '',
          is_valid: 'true'
        },
        name: '',
        shop_code: '',
        selectRows: []
      }
    },
    methods: {
      getDistributor() {
        this.loading = true
        this.params.platform = this.platform
        if (this.distributorValid) {
          this.params.is_valid = this.distributorValid
        }
        console.log(this.distributorValid,this.params)
        getDistributorList(this.params).then( response => {
          this.storeData = response.data.data.list
          this.total_count = parseInt(response.data.data.total_count)
          this.loading = false
          this.multipleSelection = []
          this.$refs.multipleTable.clearSelection()
          if (this.selectRows) {
            this.selectRows.forEach(item => {
              this.$refs.multipleTable.toggleRowSelection(item)
            })
          }
        })
      },
      getRowKeys (row) {
          return row.distributor_id
      },
      handleSizeChange (page_size) {
        this.params.pageSize = page_size
        this.getDistributor()
      },
      handleCurrentChange (page_num) {
        this.params.page = page_num
          this.getDistributor()
      },
      handleIconClick () {
        this.params.page = 1
        this.params.name = this.name
        this.params.shop_code = this.shop_code
        this.getDistributor()
      },
      toggleSelection (rows) {
        if (rows) {
          rows.forEach(row => {
            this.$refs.multipleTable.toggleRowSelection(row)
          })
        } else {
          this.$refs.multipleTable.clearSelection()
        }
      },
      handleSelectionChange (val) {
        if (val) {
          this.multipleSelection= val
          val.forEach(item => {
            let isInArr = this.selectRows.findIndex((n) => n.distributor_id == item.distributor_id)
            if (isInArr == -1) {
              this.selectRows.push(item)
            }
          })
        }
      },
      cancelAction () {
        this.$emit('closeStoreDialog')
      },
      saveStoreAction () {
        this.$emit('chooseStore', this.multipleSelection)
      },
      saveAllStoreAction () {
        this.$emit('chooseAllStore')
      }
    },
    computed: {
      showDialog () {
        return this.storeVisible
      }
    },
    watch: {
      sourceType (newVal, oldVal) {
        this.dataType = this.sourceType
      },
      relDataIds (newVal, oldVal){
        this.selectRows = newVal
      },
      getStatus (newVal, oldVal) {
        if (newVal) {
          this.getDistributor()
        }
      },
      storeVisible (newVal) {
        if (newVal) {
          this.getDistributor()
        }
      }
    }
  }
</script>

<style type="text/css">
  .store-dialog .el-checkbox {
    display: inline;
  }
</style>
