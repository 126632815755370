import { render, staticRenderFns } from "./tabsCut.vue?vue&type=template&id=1e3fb65b&scoped=true"
import script from "./tabsCut.vue?vue&type=script&lang=js"
export * from "./tabsCut.vue?vue&type=script&lang=js"
import style0 from "./tabsCut.vue?vue&type=style&index=0&id=1e3fb65b&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e3fb65b",
  null
  
)

export default component.exports