<template>
  <el-dialog
    width="600px"
    :title="title"
    :visible="visible"
    @close="closeDialogChange"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
    destroy-on-close
    class="export-dialog"
  >
    <div class="export-dialog-subtitle">{{ subtitle }}</div>
    <div class="export-dialog-body">
      <div class="left">
        <div class="title-box">
          <div class="title">导出字段</div>
          <div class="num">{{leftkeywords ? leftFilterList.length : leftList.length}}</div>
        </div>
        <div class="export-input">
          <el-input
            placeholder="请输入内容"
            v-model="leftkeywords"
            prefix-icon="el-icon-search"
            @input="leftInputChange"
            clearable>
          </el-input>
        </div>
          <ul class="left-list">
            <draggable
              handle='.icon-bars'
              :animation="300"
              :scroll = "true"
              :v-model="leftkeywords ? leftFilterList : leftList"
              chosenClass='chosenClass'
              @end="onEnd"
            >
              <li v-for="(item, index) in leftkeywords ? leftFilterList : leftList " :key="item.name_en" class="li-view">
                <div>
                  <i v-if='!leftkeywords' class='iconfont icon-bars'></i>
                  <span>{{item.name_cn}}</span>
                </div>
                <span v-if="!item.required" class='el-icon-remove' @click="leftDelChange(item, index)"></span>
              </li>
            </draggable>
          </ul>
      </div>
      <div class="right">
        <div class="title-box">
          <div class="title">添加字段</div>
          <div class="num">{{rightkeywords ? rightFilterList.length : rightList.length}}</div>
        </div>
        <div class="export-input">
          <el-input
            placeholder="请输入内容"
            v-model="rightkeywords"
            prefix-icon="el-icon-search"
            @input="rightInputChange"
            clearable>
          </el-input>
          <el-button v-if="selectAll" type="text" class="select-all-btn" @click="onSelectAllChange">全选</el-button>
        </div>
          <ul class="right-list">
            <li v-for="(item, index) in rightkeywords ? rightFilterList : rightList" :key="index" class="li-view">
              <div>
                <span>{{item.name_cn}}</span>
              </div>
              <span class='el-icon-circle-plus' @click="rightAddChange(item, index)"></span>
            </li>
          </ul>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialogChange">取消</el-button>
      <el-button type="primary" @click="saveDataActionChange">导出</el-button>
    </span>
  </el-dialog>
</template>

<script>
import draggable from "vuedraggable"
export default {
  props: {
    title: {
      type: String,
      default: '导出'
    },
    subtitle: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    },
    leftData: {
      type: Array,
      default: []
    },
    rightData: {
      type: Array,
      default: []
    },
    saveDataAction: {
      type: Function,
      default: null
    },
    closeDialog: {
      type: Function,
      default: null
    },
    selectAll: {
      type: Boolean,
      default: false
    }
  },
  components: { draggable },
  data () {
    return {
      leftkeywords: '',
      rightkeywords: '',
      leftFilterList: [],
      rightFilterList: [],
      leftList: [],
      rightList: [],
    }
  },
  methods: {
    closeDialogChange () {
      this.leftList = []
      this.rightList = []
      this.leftFilterList = []
      this.rightFilterList = []
      this.leftkeywords = ''
      this.rightkeywords = ''
      this.$emit('closeDialog')
    },
    saveDataActionChange () {
      this.$emit('saveDataAction', this.leftList)
    },
    leftInputChange (value) {
      this.rightkeywords = ''
      if (value) {
        this.leftFilterList = this.leftList.filter(item=>item.name_cn.indexOf(value) > -1)
      } else {
        this.leftFilterList = []
      }
    },
    rightInputChange (value) {
      this.leftkeywords = ''
      if (value) {
        this.rightFilterList = this.rightList.filter(item=>item.name_cn.indexOf(value) > -1)
      } else {
        this.rightFilterList = []
      }
    },
    leftDelChange (item, index) {
      const findObj = this.leftList.find(obj =>obj.name_en == item.name_en)
      this.leftList = this.leftList.filter(obj => obj.name_en != item.name_en)
      this.leftFilterList = this.leftFilterList.filter(obj => obj.name_en != item.name_en)
      this.rightList.push(findObj)
    },
    rightAddChange (item, index) {
      const findObj = this.rightList.find(obj =>obj.name_en == item.name_en)
      this.rightList = this.rightList.filter(obj => obj.name_en != item.name_en)
      this.rightFilterList = this.rightFilterList.filter(obj => obj.name_en != item.name_en)
      this.leftList.push(findObj)
    },
    onSelectAllChange () {
      if (this.rightkeywords) {
        this.rightList = this.rightList.filter(obj1 => !this.rightFilterList.find(obj2 => obj2.name_en == obj1.name_en))
        this.leftList.push(...this.rightFilterList)
        this.rightFilterList = []
      } else {
        this.leftList.push(...this.rightList)
        this.rightList = []
      }
    },
    onEnd (event) {
      const newObj = this.leftList[event.oldIndex]
      this.leftList.splice(event.oldIndex, 1)
      this.leftList.splice(event.newIndex, 0, newObj)
    },
  },
  watch: {
    leftData(newVal, oldVal) {
      this.leftList = newVal
    },
    rightData(newVal, oldVal) {
      this.rightList = newVal
    },
  }
}
</script>

<style lang="scss">
.export-dialog {
  &-subtitle {
    font-size: 14px;
    color: #999;
  }
  &-body {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    .left,.right {
      width: 48%;
      border: 1px solid rgba(228, 231, 237, 1);
      height: 400px;
      border-radius: 5px;
      overflow: hidden;
      .title-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 15px;
        background: rgba(245, 245, 245, 1);
        border-bottom: 1px solid rgba(228, 231, 237, 1);
        .title {
          font-size: 16px;
          color: #666666;
        }
      }
      .export-input {
        margin: 10px 15px;
        display: flex;
        justify-content: space-between;
        .el-input__inner {
          border-radius: 50px;
          padding-left: 40px;
        }
        .el-icon-search {
          font-size: 18px;
          padding-left: 10px;
        }
      }
      .select-all-btn {
        margin-left: 5px;
        padding: 0px;
      }
      .left-list, .right-list {
        height: 290px;
        overflow-y: scroll;
        padding: 10px 15px;
        padding-right: 25px;
        .icon-bars {
          font-size: 16px;
          color: rgba(204, 204, 204, 1);
          margin-right: 10px;
        }
        .li-view {
          padding: 5px 0px;
          display: flex;
          align-items: center;
          color: #666666;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .el-icon-remove, .el-icon-circle-plus {
          font-size: 18px;
        }
      }
      .right-list {
        padding-left: 25px;
      }
    }
  }
  .el-dialog__header {
    padding-left: 30px;
  }
  .el-dialog__body {
    padding-top: 0px;
    padding: 0px 30px 20px;
  }
  .chosenClass {
    background: rgba(245, 245, 245, 1);
  }
}
</style>
