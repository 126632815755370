<template>
  <el-select
    class="input-m"
    v-model="selected_params.channel_rtm"
    placeholder="请选择经销商RTM"
    filterable
    remote
    clearable>
    <el-option
      v-for="item in list"
      :key="item.channel_rtm"
      :label="item.channel_rtm"
      :value="item.channel_rtm"
    >
    </el-option>
  </el-select>
</template>

<script>
import { getDealerRtmList } from '@/api/marketing'
export default {
  props: {
    dealerRtmDefault: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      list: [],
      params: {
        channel_rtm: '',
      },
      total: '',
      selected_params: {
        channel_rtm: this.dealerRtmDefault,
      }
    }
  },

  watch: {
    'selected_params.channel_rtm': {
      handler (newVal, oldVal) {
        this.$emit('update', this.selected_params)
      },
      deep: true
    },
    dealerRtmDefault: {
      handler (newVal, oldVal) {
        this.selected_params.channel_rtm = newVal
      }
    }
  },
  mounted () {
    console.log('dealerRtmSelect mounted')
    this.getList(true)
  },

  methods: {
    init () {
      this.params = {
        channel_rtm: '',
      }
      this.selected_params.channel_rtm = ''
      this.getList(true)
      this.$emit('init')
    },
    async getList (refresh) {
      const { data } = await getDealerRtmList(this.params)
      const list = data.data
      this.list = refresh ? list : [...this.list, ...list]
      this.total = data.data.total_count
    }
  }
}
</script>
