<template>
  <section v-if="name === 'slider'" class="section">
    <div class="section-header with-border">设置</div>
    <div class="section-body">
      <el-form label-width="115px">
        <template v-if="type == 'wxapp'">
          <el-form-item label="标题">
            <el-input v-model="base.title"></el-input>
          </el-form-item>
          <el-form-item label="副标题">
            <el-input v-model="base.subtitle"></el-input>
          </el-form-item>
          <el-form-item label="楼层">
            <el-input type="text" v-model="base.floor_var"></el-input>
          </el-form-item>
          <el-form-item label="abTest">
            <el-switch
              v-model="base.abtest"
              active-text="打开"
              inactive-text="关闭"
              active-color="#27cc6a"
              inactive-color="#efefef"
            >
            </el-switch>
          </el-form-item>
          <el-form-item label="字体颜色">
            <el-color-picker v-model="base.WordColor"></el-color-picker>
          </el-form-item>
          <el-form-item label="是否自动切换">
            <el-switch v-model="config.autoplay" active-color="#27cc6a" inactive-color="#efefef">
            </el-switch>
          </el-form-item>
          <el-form-item label="轮播时间" v-if="config.autoplay">
            <el-input-number
              v-model="config.interval"
              :min="3000"
              :step="1000"
              label=""
            ></el-input-number>
          </el-form-item>
          <el-form-item label="指示点样式控制">
            <el-radio-group v-model="config.pages_type" @input="changePagesType">
                <el-radio label="">不可控</el-radio>
                <el-radio label="custom">可控</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="挂件背景色" v-if="config.pages_type === 'custom'">
            <el-color-picker v-model="config.back_color"></el-color-picker>
          </el-form-item>
          <el-form-item label="指示点颜色" v-if="config.pages_type === 'custom'">
            <el-color-picker v-model="config.dot_noCover"></el-color-picker>
            <el-color-picker v-model="config.dot_cover"></el-color-picker>
          </el-form-item>
          <el-form-item label="指示点颜色" v-if="config.pages_type === ''">
            <el-radio-group v-model="config.dotColor">
              <el-radio label="dark">深色</el-radio>
              <el-radio label="light">亮色</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="组件间距">
            <el-switch
              v-model="base.padded"
              active-color="#27cc6a"
              inactive-color="#efefef"
            >
            </el-switch>
          </el-form-item>
          <el-form-item label="圆角图片">
            <el-switch
              v-model="config.rounded"
              active-color="#27cc6a"
              inactive-color="#efefef"
            >
            </el-switch>
          </el-form-item>
          <el-form-item label="图片间隔">
            <el-switch
              v-model="config.padded"
              active-color="#27cc6a"
              inactive-color="#efefef"
            >
            </el-switch>
          </el-form-item>
          <el-form-item label="指示点样式">
            <el-switch
              v-model="config.dot"
              active-text="点"
              inactive-text="数字"
              active-color="#27cc6a"
              inactive-color="#ffa100"
            >
            </el-switch>
          </el-form-item>
          <el-form-item label="指示点覆盖">
            <el-switch
              v-model="config.dotCover"
              active-color="#27cc6a"
              inactive-color="#efefef"
            >
            </el-switch>
          </el-form-item>
          <el-form-item label="指示点位置">
            <el-radio-group v-model="config.dotLocation">
              <el-radio label="left">居左</el-radio>
              <el-radio label="center">居中</el-radio>
              <el-radio label="right">居右</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="指示点形状">
            <el-radio-group v-model="config.shape">
              <el-radio label="circle">圆形</el-radio>
              <el-radio label="rectangle">长方型</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="轮播项间距">
            <el-switch
              v-model="config.padded"
              active-color="#27cc6a"
              inactive-color="#efefef"
            >
            </el-switch>
          </el-form-item>
          <el-form-item label="开启图片描述">
            <el-switch
              v-model="config.content"
              active-color="#27cc6a"
              inactive-color="#efefef"
            >
            </el-switch>
          </el-form-item>
        </template>

        <el-form-item label="轮播项">
          <div
            class="setting-item slider"
            v-for="(item, index) in data"
            :key="index"
          >
            <div class="setting-remove" @click="removeItem(index)">
              <i class="iconfont icon-trash-alt"></i>
            </div>
            <template style="display: flex;flex-direction: column;">
              <div class="media_content">
                <div v-if="item.media_type === 'img' || item.media_type === 'video' ">
                  <img
                    v-if="item.imgUrl"
                    :src="wximageurl + item.imgUrl"
                    class="banner-uploader"
                    @click="handleImgChange(index)"
                  />
                  <div
                    class="banner-uploader"
                    v-else
                    @click="handleImgChange(index)"
                  >
                    <i class="iconfont icon-camera"></i>
                    上传图片
                  </div>
                </div>
                <div class="item-video" v-if="item.media_type === 'video'"  style="margin-left:10px;">
                  <Videoselect
                    @change="handleVideoChange"
                    :data="item.videoUrl"
                    :k="index"
                  ></Videoselect>
                </div>
              </div>

              <div class="uploader-setting">
                <el-input
                  v-if="config.content"
                  type="textarea"
                  placeholder="文字描述"
                  v-model="item.content"
                ></el-input>
                <el-form-item label="实验数值" v-if="base.abtest">
                  <el-input v-model="item.testGroup" placeholder="多个用','号隔开"></el-input>
                </el-form-item>
                <el-form-item label="标题">
                  <el-input v-model="item.mainTitle"></el-input>
                </el-form-item>
                <el-form-item label="副标题">
                  <el-input v-model="item.subtitle"></el-input>
                </el-form-item>
                <el-form-item label="副标题2" v-if="item.template != 'four'">
                  <el-input v-model="item.subtitleTow"></el-input>
                </el-form-item>
                <el-form-item label="按钮">
                  <el-input v-model="item.button"></el-input>
                </el-form-item>
                <el-form-item label="图片名称">
                  <el-input v-model="item.imageName"></el-input>
                </el-form-item>
                <el-form-item label="标题模板选择">
                  <el-radio-group v-model="item.template">
                    <el-radio label="one">模板一</el-radio>
                    <el-radio label="two">模板二</el-radio>
                    <el-radio label="three">模板三</el-radio>
                    <el-radio label="four">模板四</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="标题模板选择">
                  <el-radio-group v-model="item.media_type" @input="changeMediaType(index)">
                    <el-radio label="img">图片</el-radio>
                    <el-radio label="video">视频</el-radio>
                  </el-radio-group>
                </el-form-item>
                <!-- <el-form-item label="字体颜色">
                  <el-color-picker v-model="item.WordColor"></el-color-picker>
                </el-form-item> -->

                <div class="goods-select" @click="handleGoodsChange(index)" v-if="item.media_type === 'img'">
                  <div class="link-content" v-if="item.id">
                    <template v-if="item.linkPage === 'goods'">商品：</template>
                    <template v-if="item.linkPage === 'category'"
                      >分类：</template
                    >
                    <template v-if="item.linkPage === 'article'"
                      >文章：</template
                    >
                    <template v-if="item.linkPage === 'planting'"
                      >软文：</template
                    >
                    <template v-if="item.linkPage === 'link'">页面：</template>
                    <template v-if="item.linkPage === 'marketing'"
                      >营销：</template
                    >
                    {{ item.title }}
                  </div>
                  <div v-else class="content-center">
                    <i
                      class="iconfont icon-link"
                      @click="handleGoodsChange(index)"
                    ></i
                    >设置路径
                  </div>
                </div>
              </div>
            </template>
            <template v-if="type === 'pc'">
              <img
                v-if="item.url"
                :src="item.url"
                class="banner-uploader"
                @click="handleImgChange(index)"
              />
              <div
                v-else
                class="banner-uploader"
                @click="handleImgChange(index)"
              >
                <i class="iconfont icon-camera"></i>
                上传图片
              </div>
              <div class="uploader-setting">
                <el-input
                  v-if="config.content"
                  type="textarea"
                  placeholder="文字描述"
                  v-model="item.content"
                ></el-input>
                <div class="goods-select" @click="handleGoodsChange(index)">
                  <div class="link-content" v-if="item.goodsId">
                    商品：{{ item.title }}
                  </div>
                  <div v-else class="content-center">
                    <i
                      class="iconfont icon-link"
                      @click="handleGoodsChange(index)"
                    ></i
                    >设置路径
                  </div>
                </div>
              </div>
            </template>
          </div>
        </el-form-item>
      </el-form>
      <div class="content-center">
        <div class="frm-tips">
          只能上传jpg/png文件，且不超过2M （建议尺寸：375px * 200px） (max10)
        </div>
        <el-button
          :disabled="data.length >= 10"
          type="default"
          class="iconfont icon-plus-circle banner-button-uploader"
          @click="addItem"
        >
          添加轮播图</el-button
        >
      </div>
    </div>
  </section>
</template>

<script>

import Videoselect from '@/components/videoselect/blok-scroll-film'

export default {
  props: {
    res: {
      type: Object,
      default: {}
    },
    type: {
      type: String, // pc 传'pc'即可
      default: 'wxapp'
    }
  },
  components: {
    Videoselect
  },
  watch: {
    res: {
      deep: true,
      handler (value) {
        if (value) {
          this.setData(value)
        }
      }
    }
  },
  data () {
    return {
      name: '',
      base: {},
      config: {},
      data: []
    }
  },
  methods: {
    handleVideoChange (data) {
      this.data[data.index].videoUrl = data.data
      this.$emit('change', data)
    },
    setData (val) {
      this.name = val.name
      this.base = val.base
      this.config = val.config
      this.data = val.data
    },
    removeItem (index) {
      this.data.splice(index, 1)
    },
    addItem () {
      if (!this.data) {
        this.data = []
      }
      let item = {
        imgUrl: '',
        linkPage: '',
        content: '',
        id: '',
        template: 'one',
        media_type: 'img', // video
        videoUrl: {}
      }
      if (this.data.length > 9) {
        this.$message({
          message: '轮播图最多添加10个图片',
          type: 'error',
          duration: 5 * 1000
        })
      } else {
        this.data.push(item)
      }
    },
    handleImgChange (index) {
      this.$emit('bindImgs', index)
    },
    handleGoodsChange (index) {
      this.$emit('bindLinks', index)
    },
    changePagesType(value) {
      if (value === '') {
        this.config.back_color = ''
        this.config.dot_noCover = ''
        this.config.dot_cover = ''
      }
    },
    changeMediaType(value) {
      if (this.data[value].media_type === 'video') {
        this.data[value].title = ''
        this.data[value].id = ''
        this.data[value].linkPage = ''
      }
    }
  }
}
</script>

<style>
.setting-item{
  flex-direction: column
}
.media_content{
  display: flex;
  margin-bottom: 10px;
}
</style>
