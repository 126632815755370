<template>
  <div class="component-wrap" :class="{'component-padded' : base.padded, 'active': active}">
    <div class="current-active"></div>
    <div v-if="base.title" class="component-header">
      <div class="component-title">
        <div>{{base.title}}</div>
        <div class="subtitle">{{base.subtitle}}</div>
      </div>
      <div class="component-more">
        <div class="three-dot"></div>
      </div>
    </div>
    <div v-if="data[0]">
      <video-player class="vjs-custom-skin"
        :options="{
          aspectRatio: '16:9',
          sources: [{
            // mp4
            type: 'video/mp4',
            src: data[0].url
          }],
          notSupportedMessage: '无可播放媒体资源',
          controlBar: false
        }">
      </video-player>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      res: {
        type: Object,
        default: {}
      },
      active: {
        type: Boolean,
        default: false
      }
    },
    watch: {
      res (value) {
        if (value) {
          this.setData(value)
        }
      }
    },
    data () {
      return {
        base: {},
        data: []
      }
    },
    methods: {
      setData(val) {
        this.base = val.base
        this.data = val.data
      }
    },
    mounted() {
      this.setData(this.res)
    }
  }
</script>

<style scoped lang="scss">

</style>
