<template>
  <el-dialog
    title="选择优惠券"
    class="select-goods-box"
    :append-to-body="true"
    :modal-append-to-body="false"
    :visible="couponsVisible"
    @close="closeDialog"
    width="1000px"
  >
    <template>
      <el-transfer
        v-loading="loading"
        v-model="selectedCoupons"
        :titles="['优惠券列表', '已选中']"
        :button-texts="['移除选择', '添加选择']"
        @change="couponsSelector"
        :data="couponList"
      >
        <div class="transfer-footer" slot="left-footer">
          <el-pagination
            v-if="total_count > params.page_size"
            small
            layout="prev, pager, next"
            @current-change="pageChange"
            :total="total_count"
            :page-size="params.page_size"
          >
          </el-pagination>
        </div>
      </el-transfer>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="couponsComfirm">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>


<script>
import { getCardList } from '@/api/cardticket'

export default {
  props: {
    searchFirstOrder: {
      type: Boolean,
      default: true
    },
    visible: {
      type: Boolean,
      default: false
    },
    receive: {
      type: String,
      default: ''
    },
    //===true 过滤已过期优惠券
    statusIn: {
      type: Boolean,
      default: false
    },
    maxlength: {
      type: Number,
      default: 3
    }
  },
  computed: {
  },
  watch: {
    // firstOrder (val) {
    //   this.searchFirstOrder = val
    // },
    visible (val) {
      if (val) {
        this.couponsVisible = val
        this.getCouponList()
      }
    },

    selectedCoupons(val) {
      if (val.length) {
        let list = []
        this.couponList.forEach(item => {
          val.forEach(key => {
            if (item.key == key) {
              list.push(item)
            }
          })
        })
        this.selectList = list
      } else {
      }
    }
  },
  data () {
    return {
      couponsVisible: false,
      loading: false,
      params: {
        page_no: 1,
        page_size: 10,
        end_date: 1,
        first_order: ''
      },
      total_count: 0,
      couponList: [],
      selectedCoupons: [],
      selectList: []
    }
  },
  methods: {
    // 选择商品分页
    pageChange (val) {
      this.params.page_no = val
      this.getCouponList()
    },
    // 选择商品触发事件
    couponsSelector (value, direction, movedKeys) {
      if (value.length > this.maxlength) {
        this.$message({
          message: `最多选择${this.maxlength}张优惠券`,
          type: 'error',
          duration: 5 * 1000
        })
        this.selectedCoupons.splice(this.maxlength)
      }
    },
    // 选择商品确认
    couponsComfirm () {
      let values = []
      if (this.selectedCoupons.length > 0) {
        this.couponList.forEach(item => {
          console.log(item);
          this.selectedCoupons.forEach(key => {
            if (item.key == key) {
              var max = item.get_limit
              // var max = item.quantity - item.get_num - item.lock_num
              let obj = {
                imgUrl: item.imgUrl,
                title: item.label,
                id: item.key,
                card_id: item.key,
                card_type: item.type,
                date_type: item.date_type,
                begin_date: item.begin_date,
                end_date: item.end_date,
                desc: item.desc,
                amount: item.amount,
                type: item.type,
                lock_num: item.lock_num,
                get_num: item.get_num,
                quantity: item.quantity,
                gift_type: 'coupon',
                max_num: max,
                takeEffect: item.takeEffect,
                begin_time: item.begin_time,
                end_time: item.end_time
              }
              values.push(obj)
            }
          })
        })
      } else {
        this.$message({
          message: '请选择优惠券',
          type: 'error'
        })
        return
      }
      this.selectedCoupons = []
      this.couponsVisible = false
      this.$emit('pickCoupon', values)
    },
    getCouponList () {
      this.loading = true
      this.params.first_order = this.searchFirstOrder
      this.params.receive = this.receive
      getCardList(this.params).then(res => {
        let list = []
        res.data.data.list.forEach(item => {
          if (this.statusIn === true && Date.parse(new Date()) > item.end_date * 1000) {
            // console.log(item);
          } else {
            list.push({
              type: item.card_type,
              key: item.card_id,
              label: item.title,
              date_type: item.date_type,
              begin_date: item.begin_date,
              end_date: item.end_date,
              imgUrl: '',
              desc: item.description,
              lock_num: item.lock_num,
              get_num: item.get_num,
              get_limit: item.get_limit,
              quantity: item.quantity,
              amount: item.card_type === 'discount' ? (100 - item.discount) / 10 : item.reduce_cost / 100,
              takeEffect: item.takeEffect,
              begin_time: item.begin_time,
              end_time: item.end_time
            })
          }
        })

        let arr = list.filter(item => {
          let xx = this.selectList.find(row => {
            return row.key === item.key
          })
          if (!xx) {
            return item
          }
        })

        this.couponList = arr.concat(this.selectList)
        this.total_count = res.data.data.pagers.total
        this.loading = false
      })
        .catch(error => {
          this.loading = false
        })
    },
    closeDialog () {
      this.selectedCoupons = []
      this.couponsVisible = false
      this.$emit('closeDialog', 'coupon')
    }
  }
}
</script>

<style>
</style>
