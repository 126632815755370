<template>
  <section v-if="name === 'aroundService'" class="section">
    <div class="section-header with-border">设置</div>
    <div class="current-form">
      <el-form>
        <el-form-item label="标题">
          <el-input v-model="base.title" type="text"></el-input>
        </el-form-item>
        <el-form-item label="副标题">
          <el-input v-model="base.subtitle"></el-input>
        </el-form-item>
        <el-form-item label="楼层">
          <el-input type="text" v-model="base.floor_var"></el-input>
        </el-form-item>
        <el-form-item label="组件间距">
          <el-switch
            v-model="base.padded"
            active-color="#27cc6a"
            inactive-color="#efefef"
          >
          </el-switch>
        </el-form-item>
      </el-form>
      <div class="config-list">
        <div class="form-item" v-for="(item, index) of data" :key="index">
          <div class="avatar-uploader" @click="handleSelectImg(index)">
            <img
              class="previewimg"
              v-if="item.imgUrl"
              :src="item.imgUrl"
              alt=""
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </div>
          <div class="group-items">
            <div class="float-l">
              <div class="input-group">
                <el-input
                  placeholder="请输入标题"
                  v-model="item.title"
                ></el-input>
              </div>
              <div class="input-group">
                <el-input
                  placeholder="请输入介绍"
                  v-model="item.subtitle"
                ></el-input>
              </div>
              <div class="input-group">
                <el-input
                  placeholder="请输入按钮名称"
                  v-model="item.moreLink.name"
                ></el-input>
                <el-button
                  @click="handleMoreLink(index)"
                  style="margin-top: 10px"
                >
                  {{
                    item.moreLink.id
                      ? "设置链接-" + item.moreLink.title
                      : "设置链接"
                  }}
                </el-button>
              </div>
            </div>
            <el-button
              circle
              @click="handleDelConfig(index)"
              icon="el-icon-minus"
            ></el-button>
          </div>
        </div>
      </div>
      <el-button @click="handleAddConfig">添加</el-button>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    res: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      name: '',
      base: {},
      data: []
    }
  },
  watch: {
    res: {
      deep: true,
      handler (value) {
        this.setData(value)
      }
    }
  },
  methods: {
    handleSelectImg (index) {
      this.$emit('bindImgs', index)
    },
    handleAddConfig () {
      this.data.push({
        imgUrl: '',
        title: '',
        subtitle: '',
        moreLink: {
          id: '',
          title: '',
          linkPage: ''
        }
      })
    },
    handleMoreLink (index) {
      this.$emit('bindLinks', index)
    },
    handleDelConfig (index) {
      this.data.splice(index, 1)
    },
    setData (val) {
      console.log(val)
      this.name = val.name
      this.base = val.base
      this.data = val.data
    }
  },
  mounted () {
    this.setData(this.res)
  }
}
</script>

<style lang="scss" scoped>
.section {
  .section-header {
    position: static;
  }
  .current-form {
    padding: 30px;
    .config-list {
      .form-item {
        display: flex;
        align-items: flex-start;
        .avatar-uploader {
          margin-bottom: 10px;
          margin-right: 10px;
          .previewimg {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
        .group-items {
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          flex: 1;
          .float-l {
            flex: 1;
            margin-right: 10px;
            .input-group {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
