<template>
  <section v-if="name === 'tabsCut'" class="section">
    <div class="section-header with-border">设置</div>
    <div class="section-body">
      <el-form label-width="100px">
        <el-form-item label="标题">
          <el-input v-model="base.title"></el-input>
        </el-form-item>
        <el-form-item label="副标题">
          <el-input v-model="base.subtitle"></el-input>
        </el-form-item>
        <el-form-item label="楼层">
          <el-input type="text" v-model="base.floor_var"></el-input>
        </el-form-item>
        <el-form-item label="组件间距">
          <el-switch v-model="base.padded" active-color="#27cc6a" inactive-color="#efefef">
          </el-switch>
        </el-form-item>

        <el-form-item label="切换项">

          <div v-for="(item, index) in data" :key="'content' + index" class="tabs-li">
            <div class="setting-remove" @click="removeItem(index)">
              <i class="iconfont icon-trash-alt"></i>
            </div>
            <div v-for="(it, ix) in item.hotzoneList" :key="ix" class="hotzone-li">
              <div class="hotzone-remove" @click="removeHotzoneItem(index, ix)">
                <i class="iconfont icon-trash-alt"></i>
              </div>
              <el-form-item label="展示图">
                <div class="setting-item slider">
                  <img v-if="it.imgUrl" :src="wximageurl + it.imgUrl" class="banner-uploader"
                    @click="itemImgChange(index, ix)" />
                  <div class="banner-uploader" v-else @click="itemImgChange(index, ix)">
                    <i class="iconfont icon-camera"></i>上传图片
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="热区">
                <hotzone class="hotzone" :image="it.imgUrl" :zonesInit="it.list" @add="(e) => handleAdd(e, index, ix)"
                  @change="(e) => handleChange(e, index, ix)" @remove="(i) => handleRemove(i, index, ix)">
                </hotzone>
                <div class="install" v-for="(em, ex) in it.list" :key="ex">
                  <el-form-item>
                    <el-radio-group v-model="em.hotzoneType">
                      <el-radio label="link">自定义配置</el-radio>
                      <el-radio label="tabs">Tabs切换</el-radio>
                      <el-radio label="goods">滑动到货架</el-radio>
                    </el-radio-group>
                    <el-form-item label="选择切换Tabs" label-width="130px" v-if="em.hotzoneType == 'tabs'">
                      <el-radio-group v-model="em.hotzoneTypeIndex">
                        <el-radio v-for="(i, x) in data" :key="'typeIndex' + x" :label="x">Tabs{{ x + 1 }}</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <div v-else-if="em.hotzoneType == 'link'">
                      <div v-if="em.id === 'CopyLink'" class="Copy-Link">
                        <el-input v-model="em.CopyUrl" placeholder="请输入链接"></el-input>
                        <!-- <el-input v-model="em.CopyTitle" placeholder="请输入复制文案"></el-input> -->
                        <el-button icon="iconfont icon-link" class="set"
                          @click="handleGoodsChange(index, ix, ex)">设置路径</el-button>
                      </div>
                      <div v-else-if="em.id === 'thirdpage'" class="thirdpage-Link">
                        <el-input v-model="em.linkTitle" placeholder="请输入跳转路径"></el-input>
                        <el-input v-model="em.linkAppid" placeholder="请输入对应小程序APPID"></el-input>
                        <el-form-item label="是否半屏跳转">
                          <el-radio-group v-model="em.linkProgram">
                            <el-radio label="1">否</el-radio>
                            <el-radio label="2">是</el-radio>
                          </el-radio-group>
                        </el-form-item>
                        <el-button icon="iconfont icon-link" class="set"
                          @click="handleGoodsChange(index, ix, ex)">设置路径</el-button>
                      </div>
                      <div v-else :class="em.id == 'drawer-shop' ? 'uploader-setting flex' : 'uploader-setting'">
                        <div class="goods-select" @click="handleGoodsChange(index, ix, ex)">
                          <div class="link-content" v-if="em.id">
                            <template v-if="em.linkPage === 'goods'">商品：</template>
                            <template v-if="em.linkPage === 'category'">分类：</template>
                            <template v-if="em.linkPage === 'article'">文章：</template>
                            <template v-if="em.linkPage === 'planting'">软文：</template>
                            <template v-if="em.linkPage === 'link'">页面：</template>
                            <template v-if="em.linkPage === 'marketing'">营销：</template>
                            <template v-if="em.linkPage === 'custom_page'">自定义页面：</template>
                            <template v-if="em.linkPage === 'redpackage'">红包封面：</template>
                            <template v-if="em.linkPage === 'platform_order_marketing'">平台下单营销：
                            </template>
                            {{ em.title }} : {{ em.ctoken || em.id }}
                          </div>
                          <div v-else class="content-center">
                            <i class="iconfont icon-link" @click="handleGoodsChange(index, ix, ex)"></i>设置路径
                          </div>
                        </div>
                        <div class="cusdrawer-shop"
                          v-if="em.id == 'drawer-shop' && $route.path.indexOf('custompage') > -1">
                          <el-select v-model="em.rule_id" placeholder="请选择">
                            <el-option v-for="selectitem in selectShopOptions" :key="selectitem.rule_id"
                              :label="selectitem.rule_title" :value="selectitem.rule_id">
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </div>
                  </el-form-item>
                </div>
              </el-form-item>
            </div>
            <el-form-item label="">
              <el-button type="default" class="iconfont icon-plus-circle banner-button-uploader"
                @click="addHotzone(index)">添加热区</el-button>
            </el-form-item>
            <el-form-item label="关联商品">
              <el-radio-group v-model="item.goodsType">
                <el-radio label="show">展示</el-radio>
                <el-radio label="hide">隐藏</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item v-if="item.goodsType == 'show'" label="商品排序位置">
              <el-radio-group v-model="item.goodsSort">
                <el-radio v-for="(a, s) in item.hotzoneList" :label="s" :key="s">热区{{ s + 1 }}</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="锚点位置">
              <el-radio-group v-model="item.anchorType">
                <el-radio label="tabs-goods">关联商品</el-radio>
                <el-radio v-for="(q, w) in item.hotzoneList" :label="'anchor' + w" :key="w">热区图{{ w + 1 }}</el-radio>
              </el-radio-group>
            </el-form-item>


            <el-form-item label="底部悬浮按钮">

              <div class="setting-item slider">
                <img v-if="item.fixedBtnImg" :src="wximageurl + item.fixedBtnImg" class="banner-uploader"
                  @click="itemImgChange(index, 'fixed')" />
                <div class="banner-uploader" v-else @click="itemImgChange(index, 'fixed')">
                  <i class="iconfont icon-camera"></i>上传图片
                </div>
              </div>

              <div>
                <div v-if="item.fixedBtnData.id === 'CopyLink'" class="Copy-Link">
                  <el-input v-model="item.fixedBtnData.CopyUrl" placeholder="请输入链接"></el-input>
                  <!-- <el-input v-model="item.fixedBtnData.CopyTitle" placeholder="请输入复制文案"></el-input> -->
                  <el-button icon="iconfont icon-link" class="set"
                    @click="handleGoodsChange(index, 'fixed')">设置路径</el-button>
                </div>
                <div v-else-if="item.fixedBtnData.id === 'thirdpage'" class="thirdpage-Link">
                  <el-input v-model="item.fixedBtnData.linkTitle" placeholder="请输入跳转路径"></el-input>
                  <el-input v-model="item.fixedBtnData.linkAppid" placeholder="请输入对应小程序APPID"></el-input>
                  <el-form-item label="是否半屏跳转">
                    <el-radio-group v-model="item.fixedBtnData.linkProgram">
                      <el-radio label="1">否</el-radio>
                      <el-radio label="2">是</el-radio>
                    </el-radio-group>
                  </el-form-item>

                  <el-button icon="iconfont icon-link" class="set"
                    @click="handleGoodsChange(index, 'fixed')">设置路径</el-button>
                </div>
                <div v-else :class="item.fixedBtnData.id == 'drawer-shop' ? 'uploader-setting flex' : 'uploader-setting'">
                  <div class="goods-select" @click="handleGoodsChange(index, 'fixed')">
                    <div class="link-content" v-if="item.fixedBtnData.id">
                      <template v-if="item.fixedBtnData.linkPage === 'goods'">商品：</template>
                      <template v-if="item.fixedBtnData.linkPage === 'category'">分类：</template>
                      <template v-if="item.fixedBtnData.linkPage === 'article'">文章：</template>
                      <template v-if="item.fixedBtnData.linkPage === 'planting'">软文：</template>
                      <template v-if="item.fixedBtnData.linkPage === 'link'">页面：</template>
                      <template v-if="item.fixedBtnData.linkPage === 'marketing'">营销：</template>
                      <template v-if="item.fixedBtnData.linkPage === 'custom_page'">自定义页面：</template>
                      <template v-if="item.fixedBtnData.linkPage === 'redpackage'">红包封面：</template>
                      <template v-if="item.fixedBtnData.linkPage === 'platform_order_marketing'">平台下单营销：
                      </template>
                      {{ item.fixedBtnData.title }} : {{ item.fixedBtnData.ctoken || item.fixedBtnData.id }}
                    </div>
                    <div v-else class="content-center">
                      <i class="iconfont icon-link" @click="handleGoodsChange(index, 'fixed')"></i>设置路径
                    </div>
                  </div>
                  <div class="cusdrawer-shop"
                    v-if="item.fixedBtnData.id == 'drawer-shop' && $route.path.indexOf('custompage') > -1">
                    <el-select v-model="item.fixedBtnData.rule_id" placeholder="请选择">
                      <el-option v-for="selectitem in selectShopOptions" :key="selectitem.rule_id"
                        :label="selectitem.rule_title" :value="selectitem.rule_id">
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </div>


            </el-form-item>


          </div>
          <el-button type="default" class="iconfont icon-plus-circle banner-button-uploader"
            @click="addItem">添加切换项</el-button>

        </el-form-item>
        <!-- <el-form-item label="使用规则">
          <div class="setting-item slider">
            <img v-if="config.ruleImgUrl" :src="wximageurl + config.ruleImgUrl" class="banner-uploader"
              @click="itemImgChange('ruleImgUrl')" />
            <div class="banner-uploader" v-else @click="itemImgChange('ruleImgUrl')">
              <i class="iconfont icon-camera"></i>上传图片
            </div>
          </div>
        </el-form-item> -->
      </el-form>
    </div>
  </section>
</template>

<script>
import hotzone from 'vue-hotzone'
import { getShowRuleList } from '@/api/template'
import { isProxy } from 'vue';

export default {
  props: {
    res: {
      type: Object,
      default: {}
    }
  },
  watch: {
    res: {
      deep: true,
      handler(value) {
        if (value) {
          this.setData(value)
        }
      }
    },
  },
  components: {
    hotzone: hotzone
  },
  data() {
    return {
      name: '',
      base: {},
      config: {},
      data: [],
      zones: [],
      selectShopid: '',
      selectShopOptions: [],
      currentIndex: null
    }
  },
  methods: {
    setData(val) {
      this.name = val.name
      this.base = val.base
      this.config = val.config
      this.data = val.data
      const filter = val && val.data && val.data.filter(el => el.id == 'drawer-shop') || []
      if (filter.length > 0) {
        this.getShopList()
      }
    },
    handleGoodsChange(index, ix, ex) {
      this.$emit('bindLinks', index, ix, ex)
    },
    itemImgChange(index, ix) {
      this.$emit('bindImgs', index, ix)
    },
    // 添加热区
    handleAdd(e, index, ix) {
      let item = {
        linkPage: '',
        title: '',
        linkTitle: '',
        linkProgram: '1',
        id: '',
        linkAppid: '',
        card_type: '',
        goods_id: '',
        rule_id: '0',
        CopyUrl: '',
        // CopyTitle: '',
        hotzoneType: 'link',
        hotzoneTypeIndex: 0,
      }
      this.data[index].hotzoneList[ix].list.push(item)
    },
    handleChange(zone, idx, ix) {
      setTimeout(() => {
        zone.forEach((item, index) => {
          if (item.leftPer) {
            let obj = {
              heightPer: item.heightPer,
              leftPer: item.leftPer,
              topPer: item.topPer,
              widthPer: item.widthPer
            }
            Object.assign(this.data[idx].hotzoneList[ix].list[index], obj)
          }
        })
      }, 500)
    },
    handleRemove(i, index, ix) {
      this.data[index].hotzoneList[ix].list.splice(i, 1)
    },
    async getShopList() {
      if (this.$route.path.indexOf('custompage') == -1) return
      const res = await getShowRuleList({ page: 1, page_size: -1 })
      const data = [
        { rule_id: '0', rule_title: '全部门店' },
        ...res.data.data.list
      ]
      this.selectShopOptions = data
    },
    // 添加tabs切换项
    addItem() {
      let item = {
        hotzoneList: [{
          imgUrl: '',
          list: [],
        }],
        goodsType: 'show',
        anchorType: 'tabs-goods',
        goodsSort: '',
        fixedBtnImg: '',
        fixedBtnData: {
          linkPage: '',
          title: '',
          id: '',
          card_type: '',
          linkTitle: '',
          linkProgram: '1',
          linkAppid: '',
          CopyUrl: '',
          // CopyTitle: '',
          goods_id: '',
          rule_id: '0',
        }
      }
      this.data.push(item)
    },
    removeItem(i) {
      this.data.splice(i, 1)
    },
    removeHotzoneItem(index, ix) {
      this.data[index].hotzoneList.splice(ix, 1)
    },
    addHotzone(index) {
      let item = {
        imgUrl: '',
        list: [],
      }
      this.data[index].hotzoneList.push(item)
    }
  },
  mounted() {
    this.setData(this.res)
  }
}
</script>

<style scoped lang="scss">
.hotzone {
  max-width: 500px;
  margin-bottom: 10px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
}

.uploader-setting {
  padding: 0;

  .cusdrawer-shop,
  .goods-select {
    flex: 1
  }

  .cusdrawer-shop {
    margin-left: 20px;

    .el-select {
      width: 100%;
    }
  }
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Copy-Link {
  width: 100%;
  display: flex;

  .set {
    margin-left: 20px;
  }
}

.thirdpage-Link {
  .el-input {
    margin-bottom: 10px;
  }
}

.tabs-li {
  padding: 40px 20px 20px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  position: relative;

  .el-form-item {
    margin-bottom: 10px;
  }

  .install {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 0 20px;
  }

  .goods {
    margin-top: 20px
  }

  .hotzone-li {
    position: relative;

    .hotzone-remove {
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 100;
    }
  }

  .setting-remove {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 100;
  }
}
</style>
