var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"picker-lottery"},[_c('SpFinder',{ref:"finder",attrs:{"other-config":{
    'max-height': 460,
    'header-cell-class-name': _vm.cellClass
  },"row-key":"marketing_id","reserve-selection":"","data":_vm.data,"fixed-row-action":true,"setting":{
    columns: [
      { name: 'ID', key: 'id', width: 80 },
      { name: '标题名称', key: 'title' }
    ]
  }},on:{"select":_vm.onSelect,"selection-change":_vm.onSelectionChange}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }