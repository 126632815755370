<template>
  <div class="tab-bar-one">
    <div class="tab-bar-one__item tab-bar-one__item_inline">
      <div class="tab-bar-one__item__label">是否实验</div>
      <el-select v-model="tabInfo.abId" placeholder="请选择" size="small" @change="handleChangeAbid">
        <el-option v-for="item in experimentList" :key="item.value" :label="item.title" :value="item.value" />
      </el-select>
    </div>
    <div class="tab-bar-one__item" v-if="tabInfo.abId">
      <div class="tab-bar-one__item__label">选择实验</div>
      <el-radio-group v-model="abGroundId" size="mini">
        <el-radio-button v-for="item of tabInfo.abData" :key="item.value" :label="item.value" style="margin-top: 5px;">
          {{ item.label }}
        </el-radio-button>
      </el-radio-group>
    </div>
    <div v-for="(item, index) of tabInfo.abData" :key="`${item.value}__item`" v-if="item.value == abGroundId">
      <div class="tab-bar-one__item">
        <div class="tab-bar-one__item__label">导航预览</div>
        <div class='nav-priview' :style="`background: ${item.config.backgroundColor}`">
          <div class='nav-priview-item' :key="`nav-priview-${index}_${key}`" v-for="(navitem, key) in item.data"
            @click="handleChangeCurIndex(key)">
            <SpImage :src="curIndex == key ? navitem.selectedIconPath : navitem.iconPath" width="30" height="30"
              fit="'fill'" />
            <span class='nav-priview-item-text'
              :style='`color: ${curIndex == key ? item.config.selectedColor : item.config.color}`'>{{ navitem.text
              }}</span>
          </div>
        </div>
      </div>
      <div class="tab-bar-one__item">
        <div class="tab-bar-one__item__label">导航配色设置</div>
        <div class="tab-bar-one__item__nav_setting">
          <div class="tab-bar-one__item__nav_setting__item">
            <div class="tab-bar-one__item__nav_setting__item__label">背景色</div>
            <el-color-picker v-model="item.config.backgroundColor" size="mini"></el-color-picker>
          </div>
          <div class="tab-bar-one__item__nav_setting__item">
            <div class="tab-bar-one__item__nav_setting__item__label">导航项名称色</div>
            <div class="tab-bar-one__item__nav_setting__item__content">
              <div class="tab-bar-one__item__nav_setting__item__content__item">
                <el-color-picker v-model="item.config.color" size="mini"></el-color-picker>
                <span>未选中</span>
              </div>
              <div class="tab-bar-one__item__nav_setting__item__content__item">
                <el-color-picker v-model="item.config.selectedColor" size="mini"></el-color-picker>
                <span>选中</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-bar-one__item">
        <div class="tab-bar-one__item__label">导航项设置</div>
        <div class='nav-setting'>
          <draggable handle='.icon-bars' :animation='300' :scroll="true" v-model="item.data" chosenClass='chosenClass'>
            <div class='nav-setting-item' :key="`nav-setting-item${key}`" v-for="(ele, key) in item.data">
              <div class='nav-setting-item-header'>
                <span>导航{{ key + 1 }}</span>
                <div>
                  <i class="iconfont icon-bars mover" />
                  <i class="iconfont icon-trash-alt" @click="handleDelNav(index, key)" />
                </div>
              </div>
              <div class='drag-content'>
                <div class='nav-setting-item-default'>
                  <div class='default_item'>
                    <span class='default_item_text'>未选中icon</span>
                    <SpImagePicker size="small" class="video-link" text="" v-model="ele.iconPath" />
                  </div>
                  <div class='default_item'>
                    <span class='default_item_text'>名称</span>
                    <el-input size='small' v-model="ele.text" />
                  </div>
                </div>
                <div class='nav-setting-item-default'>
                  <div class='default_item'>
                    <span class='default_item_text'>选中icon</span>
                    <SpImagePicker size="small" class="video-link" text="" v-model="ele.selectedIconPath" />
                  </div>
                  <div class='default_item'>
                    <span class='default_item_text'>页面</span>
                    <el-select v-model="ele.pagePath">
                      <el-option v-for="option in pathoptions" :key="option.value" :label="option.label"
                        :value="option.value"></el-option>
                    </el-select>
                  </div>
                </div>
              </div>
            </div>
          </draggable>
          <div class='btns'>
            <el-button size='small' type='primary' plain @click="addNav(index)">
              添加导航
            </el-button>
            <span class='max-text'>最多可添加5个</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PATHOPTIONS } from '@/consts'
import draggable from "vuedraggable"
import { pickBy } from "@/utils";
import { isUndefined, cloneDeep } from "lodash";

const resetData = [{
  "pagePath": "/pages/index",
  "text": "首页",
  "name": "home",
  "iconPath": "https://cdn-image.tjtjshengtu.com/image/1/2024/01/16/5107759d4d6a1f88eab472f3e3bcc91eJBmEDWMTU6WogQqtc0seuF2ZZemZnTxU",
  "selectedIconPath": "https://cdn-image.tjtjshengtu.com/image/1/2024/02/18/52b4e8e2e9470e5cf32b2130f1ded149c1HORMhy96E58jR9WKFS6s0TkTPAZCWK",
  "platformType": []
},
{
  "pagePath": "/pages/classification/index",
  "text": "产品分类",
  "name": "category",
  "iconPath": "https://cdn-image.tjtjshengtu.com/image/1/2024/01/16/5107759d4d6a1f88eab472f3e3bcc91eS01OHt2U8R8kD0EGwWHvzhNvSspfxPlm",
  "selectedIconPath": "https://cdn-image.tjtjshengtu.com/image/1/2024/02/18/52b4e8e2e9470e5cf32b2130f1ded149BID7qofeBJhRddReYROmGzrvhjgqPNWU",
  "platformType": []
},
{
  "pagePath": "/pages/store/map-store",
  "text": "探索门店",
  "name": "store-map",
  "iconPath": "https://cdn-image.tjtjshengtu.com/image/1/2024/08/01/f811fbbbe267d513ab0c1a370ba57738c7CdMvAA1XLJjh04pajR6fJXVSFmEHWX",
  "selectedIconPath": "https://cdn-image.tjtjshengtu.com/image/1/2024/08/01/f811fbbbe267d513ab0c1a370ba57738Oj1gD8KlkC7v1t9DVYM6a2gjog1tdjds"
},
{
  "pagePath": "/pages/member/index",
  "text": "我的",
  "name": "member",
  "iconPath": "https://cdn-image.tjtjshengtu.com/image/1/2024/08/01/f811fbbbe267d513ab0c1a370ba57738vrTKktLYX0nyzUzkeX1sbkk2DZWZy0Eo",
  "selectedIconPath": "https://cdn-image.tjtjshengtu.com/image/1/2024/08/01/f811fbbbe267d513ab0c1a370ba577386xNTCHyoNtMNeHkw5AdlY6noJ8fuxhFu"
}]

const FILEDTYPE = {
  'text': '名称',
  'iconPath': '未选中icon',
  'selectedIconPath': '选中icon',
  'pagePath': '页面'
}
export default {
  components: { draggable },
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      experimentList: [],
      abtetsList: [],
      abGroundId: '',
      pathoptions: PATHOPTIONS,
      tabInfo: {
        name: 'tabs',
        abId: '',
        abName: '',
        abData: [],
      },
      curIndex: 0
    }
  },
  mounted() {
    this.getExperimentList()
  },
  methods: {
    async getExperimentList() {
      const { experiments } = await this.$api.abtest.getExperimentList({ name: '' })
      this.experimentList = [{ title: '无实验', value: '' }, ...pickBy(experiments, { title: 'name', value: 'id' })]
    },
    async handleChangeAbid(e) {
      if (!e) {
        this.$set(this.tabInfo, 'abName', '')
        this.$set(this.tabInfo, 'abData', [{
          label: "",
          value: "",
          config: {
            backgroundColor: "#fff",
            color: "#000",
            selectedColor: "#687BF2",
          },
          data: cloneDeep(resetData)
        }])
        return
      }
      const result = await this.getAbDetails(e)
      const abName = result[result.length - 1].name
      result.map(item => {
        item.data = cloneDeep(resetData)
      })
      this.$set(this.tabInfo, 'abData', result)
      this.$set(this.tabInfo, 'abName', abName)
    },
    async getAbDetails(id) {
      if (!id) return []
      const { experiment } = await this.$api.abtest.getExperimentDetail({ id })
      let options = pickBy(experiment.experiment_groups, {
        label: 'cname',
        value: 'group_variables[0].value',
        name: 'group_variables[0].name',
      })
      options = [{ label: '兜底组', value: '' }, ...options]
      const data = options.map(item => {
        return {
          label: item.label,
          value: item.value,
          name: item.name,
          config: {
            backgroundColor: "#fff",
            color: "#000",
            selectedColor: "#687BF2",
          },
          data: []
        }
      })
      return data
    },
    addNav(index) {
      if (this.tabInfo.abData[index].data.length >= 5) {
        this.$message.error('最多可添加5个')
        return
      }
      this.tabInfo.abData[index].data.push({
        pagePath: "",
        text: "",
        name: "",
        iconPath: "",
        selectedIconPath: "",
      });
    },
    handleDelNav(index, key) {
      this.tabInfo.abData[index].data.splice(key, 1)
    },
    handleChangeCurIndex(index) {
      this.curIndex = index
    },
    checkEmptyFields(abData) {
      let isEmpty = false
      for (let abIndex = 0; abIndex < abData.length; abIndex++) {
        const item = abData[abIndex];
        for (let dataIndex = 0; dataIndex < item.data.length; dataIndex++) {
          const dataItem = item.data[dataIndex];
          const fields = ['pagePath', 'text', 'iconPath', 'selectedIconPath'];
          for (let field of fields) {
            if (!dataItem[field]) {
              isEmpty = true;
              this.$message.warning(`${item.label}导航${dataIndex + 1}的${FILEDTYPE[field]}字段不能为空`)
              return isEmpty;
            }
          }
        }
      }
      return isEmpty
    },
    getInfo() {
      const { abData, ...item } = this.tabInfo
      const isEmpty = this.checkEmptyFields(abData)
      if (isEmpty) throw new Error('请填写完整')
      return {
        abData: abData,
        data: abData[0].data,
        config: abData[0].config,
        ...item
      }
    }
  },
  watch: {
    value: {
      async handler(val) {
        const { config, data, abData, abId, abName, name } = val
        if (isUndefined(abId)) {
          //兼容老版本
          this.$set(this.tabInfo, 'name', name)
          this.$set(this.tabInfo, 'abId', '')
          this.$set(this.tabInfo, 'abName', '')
          this.$set(this.tabInfo, 'abData', [{
            label: "",
            value: "",
            config,
            data
          }])
        } else {
          if (!abId) {
            this.tabInfo = cloneDeep(val)
          } else {
            const result = await this.getAbDetails(abId)
            const abName = result[result.length - 1].name
            result.map(item => {
              const ele = abData.find(ele => ele.value == item.value)
              if (ele) {
                item.data = ele.data
                item.config = ele.config
              }
              return item
            })
            this.$set(this.tabInfo, 'name', name)
            this.$set(this.tabInfo, 'abId', abId)
            this.$set(this.tabInfo, 'abName', abName)
            this.$set(this.tabInfo, 'abData', result)
          }
        }
      },
      deep: true
    }
  },

}
</script>

<style lang="scss" scoped>
.tab-bar-one {
  .icon-trash-alt {
    margin-left: 10px;
    cursor: pointer;
  }

  &__item {
    margin-bottom: 15px;

    &_inline {
      display: flex;
      align-items: center;
    }

    &__label {
      width: 120px;
      color: #000;
      font-size: 14px;
    }

    &__nav_setting {
      background-color: #fff;
      padding: 0 15px 15px;
      margin-top: 15px;

      &__item {
        padding-top: 15px;
        display: flex;
        align-items: center;

        &__label {
          width: 120px;
        }

        &__content {
          display: flex;
          align-items: center;

          &__item {
            display: flex;
            align-items: center;
            margin-right: 10px;

            span {
              margin-left: 10px;
            }
          }
        }
      }

    }
  }

  .nav-priview {
    display: flex;
    align-items: flex-start;
    padding: 10px 0;
    margin-top: 15px;

    &-item {
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &-text {
        font-size: 12px;
        line-height: 12px;
        padding-top: 5px;
      }
    }

  }
}
</style>
<style lang="scss">
.nav-setting {
  margin-top: 15px;

  .btns {
    margin-top: 10px;
  }

  .nav-setting-item {
    background-color: #fff;
    margin-bottom: 15px;

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #9f9f9f;
      padding: 5px 20px;

    }

    .drag-content {
      padding: 10px 20px;
    }


    &-default {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .default_item {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        &_text {
          display: inline-block;
          width: 100px;
        }

      }
    }

    .max-text {
      font-size: 11px;
    }

    .sp-image-picker {
      .image-item {
        height: 35px;
        width: 35px;

        .icon-camera {
          font-size: 15px;
        }
      }
    }

    .add-text {
      display: none;
    }
  }
}
</style>