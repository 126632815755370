<template>
  <div class="component-wrap" :class="{ 'component-padded': base.padded, 'active': active }">
    <div class="current-active"></div>
    <div v-if="base.title" class="component-header">
      <div class="component-title">
        <div>{{ base.title }}</div>
        <div class="subtitle">{{ base.subtitle }}</div>
      </div>
      <div class="component-more">
        <div class="three-dot"></div>
      </div>
    </div>

    <div v-for="(item, index) in data" :key="index">
      <div class="img-hotzone" v-for="(em, ex) in item.hotzoneList" :key="'index' + ex" v-if="tabsIndex == index">
        <img :src="em.imgUrl" alt="" />
        <div class="zone" v-for="(ie, ix) in em.list" :key="ix" @click="onTabsIndex(ie.hotzoneTypeIndex, ie.hotzoneType)"
          :style="{
            width: getZoneStyle(ie.widthPer),
            height: getZoneStyle(ie.heightPer),
            top: getZoneStyle(ie.topPer),
            left: getZoneStyle(ie.leftPer)
          }"></div>
      </div>
      <div class="img-hotzone">
        <img :src="item.fixedBtnImg" alt="" />
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    res: {
      type: Object,
      default: {}
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    res(value) {
      if (value) {
        this.setData(value)
      }
    }
  },
  data() {
    return {
      base: {},
      config: {},
      data: [],
      tabsIndex: 0
    }
  },
  methods: {
    setData(val) {
      this.base = val.base
      this.config = val.config
      this.data = val.data
    },
    getZoneStyle(val) {
      return `${(val || 0) * 100}%`
    },
    onTabsIndex(index, type) {
      if (type != 'tabs') return
      this.tabsIndex = index
    }
  },
  mounted() {
    this.setData(this.res)
  }
}
</script>

<style scoped lang="scss">
.img-hotzone {
  position: relative;

  img {
    width: 100%;
    user-select: none;
    -webkit-user-drag: none;
    -webkit-touch-callout: none;
    display: block
  }

  .zone {
    position: absolute;
    cursor: pointer;
  }
}
</style>
