<template>
  <section v-if="name === 'oldGoods'" class="section">
    <div class="section-header with-border">设置</div>
    <div class="section-body">
      <el-form label-width="100px" :label-position="usage == 'mobile' ? 'left' : 'top'">
        <el-form-item label="标题">
          <el-input v-model="base.title" type="text"></el-input>
        </el-form-item>
        <el-form-item label="副标题" v-if="show_subtitle">
          <el-input v-model="base.subtitle"></el-input>
        </el-form-item>
        <el-form-item label="楼层">
          <el-input type="text" v-model="base.floor_var"></el-input>
        </el-form-item>
        <el-form-item label="组件间距" v-if="show_space">
          <el-switch v-model="base.padded" active-color="#27cc6a" inactive-color="#efefef">
          </el-switch>
        </el-form-item>
        <el-form-item label="组件间距" v-if="usage == 'pc'">
          <el-input type="number" v-model="base.padded"></el-input>
        </el-form-item>
        <!-- <el-form-item label="样式" v-if="show_style">
          <el-radio-group v-model="config.style" @change="styleChange">
            <el-radio :label="'grid'"><i class="style-icon iconfont icon-th-large"></i></el-radio>
            <el-radio :label="'grids'"><i class="style-icon iconfont icon-th"></i></el-radio>
          </el-radio-group>
        </el-form-item> -->
        <!-- <el-form-item label="显示价格" v-if="show_price">
          <el-switch v-model="config.showPrice" active-color="#27cc6a" inactive-color="#efefef">
          </el-switch>
        </el-form-item> -->
        <!-- <el-form-item v-if="config.style !== 'grids' && show_brand" label="显示品牌">
          <el-switch v-model="config.brand" active-color="#27cc6a" inactive-color="#efefef">
          </el-switch>
          <span class="muted content-padded">显示品牌需配置商品品牌logo</span>
        </el-form-item> -->
        <el-form-item label="列表标题">
          <el-input v-model="base.listTitle"></el-input>
        </el-form-item>
        <el-form-item label="选择商品">
          <draggable v-if="items.length > 0" v-model="items" class="content-bottom-padded" :options="dragItemsOptions"
            @end="onEnd">
            <div class="setting-item item-selected" v-for="(item, index) in items" :key="index">
              <img class="thumbnail" :src="wximageurl + item.imgUrl" alt="" />
              <div class="">{{ item.title }}</div>
              <div class="setting-remove" @click="removeItem(index)">
                <i class="iconfont icon-trash-alt"></i>
              </div>
            </div>
          </draggable>
          <el-button type="default" class="iconfont icon-cog banner-button-uploader" @click="setGoods"
            v-if="!limit_num || items.length < limit_num">
            设置商品</el-button>
          <span v-if="limit_num"> * 最多上传{{ limit_num }}个商品</span>
        </el-form-item>
        <el-form-item v-if="config.moreLink" label="设置更多链接">
          <div class="goods-select" @click="handleMoreLink">
            <div class="link-content" v-if="config.moreLink.title">
              <template v-if="config.moreLink.linkPage === 'goods'">商品：</template>
              <template v-if="config.moreLink.linkPage === 'category'">分类：</template>
              <template v-if="config.moreLink.linkPage === 'article'">文章：</template>
              <template v-if="config.moreLink.linkPage === 'planting'">软文：</template>
              <template v-if="config.moreLink.linkPage === 'link'">页面：</template>
              <template v-if="config.moreLink.linkPage === 'marketing'">营销：</template>
              <template v-if="config.moreLink.linkPage === 'custom_page'">自定义页面：</template>
              <template v-if="config.moreLink.linkPage === 'redpackage'">红包封面：</template>
              {{ config.moreLink.title }}
            </div>
            <div v-else class="content-center">
              <i class="iconfont icon-link"></i>设置路径
            </div>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </section>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  props: {
    res: {
      type: Object,
      default: {}
    },
    show_space: {
      type: Boolean,
      default: true
    },
    show_style: {
      type: Boolean,
      default: true
    },
    show_brand: {
      type: Boolean,
      default: true
    },
    show_subtitle: {
      type: Boolean,
      default: true
    },
    show_price: {
      type: Boolean,
      default: true
    },
    limit_num: {
      // 限制上传商品的数量
      type: Number,
      default: 0
    },
    usage: {
      type: String,
      default: 'mobile'
    }
  },
  components: {
    draggable
  },
  watch: {
    res: {
      deep: true,
      handler(value) {
        if (value) {
          this.setData(value)
        }
      }
    }
  },
  data() {
    return {
      name: '',
      base: {},
      config: {},
      data: [],

      items: [],
      temp: '',
      dragItemsOptions: {
        animation: 300,
        forceFallback: false,
        scroll: true
      }
    }
  },
  methods: {
    setData(val) {
      this.name = val.name
      this.base = val.base
      this.config = val.config
      this.data = val.data
      this.items = val.data
    },

    removeItem(index) {
      this.data.splice(index, 1)
    },
    setGoods() {
      this.$emit('bindGoods')
    },
    styleChange(val) {
      if (val === 'grids') {
        this.config.brand = false
      }
    },
    onEnd(evt) {
      this.temp = this.data[evt.oldIndex]
      this.data.splice(evt.oldIndex, 1)
      this.data.splice(evt.newIndex, 0, this.temp)
    },
    handleMoreLink() {
      this.$emit('bindLinks')
    }
  },
  mounted() {
    this.setData(this.res)
  }
}
</script>

<style scoped lang="scss">
.style-icon {
  font-size: 20px;
  color: #aeaeae;
  vertical-align: middle;
}

.tab-list {
  padding-right: 30px;
  display: flex;
  flex-direction: column;
}
</style>
