var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"component-wrap",class:{'component-padded' : _vm.base.padded, 'active': _vm.active}},[_c('div',{staticClass:"current-active"}),(_vm.base.title)?_c('div',{staticClass:"component-header"},[_c('div',{staticClass:"component-title"},[_c('div',[_vm._v(_vm._s(_vm.base.title))]),_c('div',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.base.subtitle))])]),_vm._m(0)]):_vm._e(),(_vm.data[0])?_c('div',[_c('video-player',{staticClass:"vjs-custom-skin",attrs:{"options":{
        aspectRatio: '16:9',
        sources: [{
          // mp4
          type: 'video/mp4',
          src: _vm.data[0].url
        }],
        notSupportedMessage: '无可播放媒体资源',
        controlBar: false
      }}})],1):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"component-more"},[_c('div',{staticClass:"three-dot"})])
}]

export { render, staticRenderFns }