<template>
  <div class="component-wrap" :class="{'component-padded' : base.padded, 'active': active}">
    <div class="current-active"></div>
    <div v-if="base.title" class="component-header">
      <div class="component-title">
        <div>{{ base.title }}</div>
        <div class="subtitle">{{ base.subtitle }}</div>
      </div>
    </div>
    <ul class="list">
      <li class="item" v-for="(item, index) of data" :key="index">
        <div v-if='item.longImgUrl' class="poster">
          <img :src="item.longImgUrl" alt="" />
          <div class="progess">
            <div class="progess-bck" style="width:50%"></div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    res: {
      type: Object,
      default: {}
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      base: {},
      data: []
    }
  },
  watch: {
    res: {
      deep: true,
      handler (value) {
        this.setData(value)
      }
    }
  },
  methods: {
    setData (val) {
      this.base = val.base
      this.data = val.data
    }
  },
  mounted () {
    this.setData(this.res)
  }
}
</script>

<style lang="scss" scoped>
.component-wrap {
  width: 320px;
  margin: 0 auto;
  .component-header {
    font-size: 18px;
    font-weight: bold;
    padding: 0;
    margin-bottom: 5px;
  }
  .wrap {
      display: flex;
      position: sticky;
      top:0;
      left:0;
      z-index: 99;
      width:320px;
      height: 45px;
      max-height: 45px;
      background: #141415;
      overflow-x: auto;
      .title {
        flex-shrink: 0;
        padding: 0 18px;
        box-sizing: border-box;
        color: #C9C9CB;
        height: 45px;
        line-height: 45px;
        font-size: 13px;
      }
    }
  .list {
    .item {
      // display: flex;
      // align-items: center;
      overflow: hidden;
      .poster {
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .progess {
    width: 50%;
    height: 4px;
    margin: 10px auto 10px;
    border-radius: 5px;
    background: #979797;
    position: relative;
    overflow: hidden;
  }
  .progess-bck {
    height: 4px;
    border-radius: 5px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    background: #1D1D1F;
  }
  .progess-tips {
    text-align: center;
    height: 16px !important;
    width: 100%;
  }
}
</style>
