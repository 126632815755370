<template>
  <section v-if="name === 'marquees'" class="section sect-w">
    <div class="section-header with-border">设置</div>
    <div class="section-body">
      <el-form label-width="100px">
        <el-form-item label="轮播时间">
          <el-input-number
            v-model="config.interval"
            :min="3000"
            :step="1000"
            label=""
          ></el-input-number>
        </el-form-item>
        <el-form-item label="组件间距">
          <el-switch
            v-model="base.padded"
            active-color="#27cc6a"
            inactive-color="#efefef"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="文本">
          <div
            class="setting-item slider"
            v-for="(item, index) in data"
            :key="index"
          >
            <div class="uploader-setting">
              <div class="set-w" @click="removeItem(index)">
                <i class="iconfont icon-trash-alt"></i>
              </div>
            </div>
            <img
              v-if="item.imgUrl"
              :src="wximageurl + item.imgUrl"
              class="icon-uploader"
              @click="handleImgChange(index)"
            />
            <div v-else class="icon-uploader" @click="handleImgChange(index)">
              <i class="iconfont icon-camera"></i>
              <i class="icon-sm-text">上传图标</i>
            </div>
            <div class="uploader-setting">
              <el-input v-model="item.text" class="input-w"></el-input>
              <el-color-picker v-model="item.fontcolor"></el-color-picker>
            </div>
            <div class="uploader-setting">
              <div class="goods-select" @click="handleGoodsChange(index)">
                <div class="link-content" v-if="item.id">
                  <template v-if="item.linkPage === 'goods'">商品：</template>
                  <template v-if="item.linkPage === 'category'"
                    >分类：</template
                  >
                  <template v-if="item.linkPage === 'article'">文章：</template>
                  <template v-if="item.linkPage === 'planting'"
                    >软文：</template
                  >
                  <template v-if="item.linkPage === 'link'">页面：</template>
                  <template v-if="item.linkPage === 'marketing'"
                    >营销：</template
                  >
                  {{ item.title }}
                </div>
                <div v-else class="content-center">
                  <i
                    class="iconfont icon-link"
                    @click="handleGoodsChange(index)"
                  ></i
                  >设置路径
                </div>
              </div>
            </div>
          </div>
          **模板实际效果请以小程序为准**
        </el-form-item>
      </el-form>
      <div class="content-center">
        <el-button
          type="default"
          class="iconfont icon-plus-circle banner-button-uploader"
          @click="addItem"
        >
          添加文本</el-button
        >
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    res: {
      type: Object,
      default: {},
    },
  },
  watch: {
    res: {
      deep: true,
      handler(value) {
        if (value) {
          this.setData(value);
        }
      },
    },
  },
  data() {
    return {
      name: "",
      base: {},
      config: {},
      data: [],
      // 文章选择器
      articleVisible: false,
      setArticleStatus: false,
      relArticles: [],
      // 拖拽参数
      list: [],
      temp: '',
      dragItemsOptions: {
        animation: 300,
        forceFallback: false,
        scroll: true,
        handle: '.drag-handler'
      }
    }
  },
  mounted () {
    this.setData(this.res)
  },
  methods: {
    setData(val) {
      this.name = val.name;
      this.base = val.base;
      this.config = val.config;
      this.data = val.data;
    },
    removeItem(index) {
      this.data.splice(index, 1);
    },
    addItem() {
      if (!this.data) {
        this.data = [];
      }
      let item = {
        imgUrl: "",
        fontcolor: "",
        linkPage: "",
        text: "",
        id: "",
      };
      this.data.push(item);
    },
    handleGoodsChange(index) {
      this.$emit("bindLinks", index);
    },
    handleImgChange(index) {
      this.$emit("bindImgs", index);
    },
  },
};
</script>
<style lang="scss" scoped>
.sect-w {
  .uploader-setting {
    padding-left: 30px;
    display: flex;
    align-items: center;
    flex: 0;
    .input-w {
      width: 200px;
      margin-right: 30px;
    }
    .goods-select {
      width: 200px;
    }
  }
  .set-w {
    left: 0;
    color: rgba(231, 39, 39, 0.842);
  }
  .content-center {
    text-align: left;
    margin-left: 100px;
  }
  .goods-select {
    .content-center {
      text-align: center;
      margin-left: 0;
    }
  }
}

.icon-uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  color: #dadadd;
  border: 1px dashed #ddd;
  cursor: pointer;
  line-height: 1.3;
  margin-left: 30px;
  .icon-sm-text {
    font-size: 11px;
    margin-top: 6px;
  }
}
</style>
