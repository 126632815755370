<template>
    <el-dialog class="store-dialog" title="选择自定义页面" :visible.sync="showDialog" :close-on-click-modal="false"
               :before-close="cancelAction">
        <div style="margin-bottom: 15px;">
            <el-input placeholder="输入自定义页面名称" v-model="name" clearable>
                <el-button slot="append" icon="el-icon-search" @click="handleIconClick"></el-button>
            </el-input>
        </div>
        <el-table ref="multipleTable" :data="storeData" tooltip-effect="dark" style="width: 100%" v-loading="loading">
            <el-table-column label="选择">
                <template slot-scope="scope">
                    <el-radio :label="scope.row.id" v-model="templateRadio"
                              @change.native="getTemplateRow(scope.$index,scope.row)">&nbsp;
                    </el-radio>
                </template>
            </el-table-column>
            <el-table-column prop="id" label="页面id"></el-table-column>
            <el-table-column prop="page_name" label="页面名称"></el-table-column>
            <el-table-column prop="page_description" label="页面描述"></el-table-column>
            <el-table-column label="是否启用">
          <template slot-scope="scope">
            <el-tag type="info" v-if="scope.row.is_open == '0'">禁用</el-tag>
            <el-tag type="warning" v-else>启用</el-tag>
          </template>
        </el-table-column>
        </el-table>
        <div v-if="total_count > params.pageSize" class="tr">
            <el-pagination
                    layout="prev, pager, next"
                    @current-change="handleCurrentChange"
                    :total="total_count" :page-size="pageLimit">
            </el-pagination>
        </div>
        <span slot="footer" class="dialog-footer">
      <el-button @click="cancelAction">取 消</el-button>
      <el-button type="primary" @click="saveStoreAction">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getCustomizeList } from '@/api/promotions'
import { getByteMiniCustomPageList } from '@/api/bytemini'


  export default {
    props: {
      relDataIds: {
        type: Array,
        default: function () {
          return []
        }
      },
      getStatus: {
        type: Boolean,
        default: false
      },
      isValid: {
        type: Boolean,
        default: false
      },
      storeVisible: {
        type: Boolean,
        default: false
      },
      sourceType: {
        type: String,
        default: ''
      },
      isCleanSelect: {
        type: Boolean,
        default: false
      },
      platForm: {
        type: String,
        default: 'order'
      }
    },
    data() {
      return {
        templateRadio: '',
        dataType: 'distributor',
        loading: false,
        storeData: [],
        multipleSelection: [],
        pageLimit: 10,
        total_count: '',
        params: {
          page: 1,
          pageSize: 10,
          is_valid: 'true'
        },
        name: '',
      }
    },
    methods: {
      getTemplateRow(index, row) {
        this.multipleSelection = new Array(row)
      },
      getCustomize() {
        if (this.platForm == 'order') {
          getCustomizeList(this.params).then(response => {
            this.storeData = response.data.data.list
            this.total_count = parseInt(response.data.data.total_count)
            this.loading = false
            this.multipleSelection = []
          })
        } else {
          // 抖音模版
          let params = {
            page: this.params.page,
            pageSize: this.params.pageSize,
            platform: 'byte_mini',
            getAll: true,
            template_name: 'disti',
          }
          getByteMiniCustomPageList(params).then((response) => {
            this.storeData = response.data.data.list
            this.total_count = parseInt(response.data.data.total_count)
            this.loading = false
            this.multipleSelection = []
          })
        }
      },
      handleCurrentChange(page_num) {
        this.params.page = page_num
        this.getCustomize()
      },
      handleIconClick() {
        this.params.name = this.name
        this.getCustomize()
      },
      cancelAction() {
        this.$emit('closeStoreDialog')
        if (this.isCleanSelect) {
          this.templateRadio = ''
        }
      },
      saveStoreAction() {
        this.$emit('chooseStore', this.multipleSelection)
        if (this.isCleanSelect) {
          this.templateRadio = ''
        }
      }
    },
    computed: {
      showDialog() {
        return this.storeVisible
      }
    },
    watch: {
      storeVisible(newVal, oldVal) {
        if (newVal && this.isCleanSelect) {
          this.templateRadio = this.relDataIds[0].customize_id
        }
      },
      sourceType(newVal, oldVal) {
        this.dataType = this.sourceType
      },
      relDataIds(newVal, oldVal) {
        this.selectRows = newVal
      },
      getStatus(newVal, oldVal) {
        this.getCustomize()
      },
    }
  }
</script>

<style type="text/css">
    .store-dialog .el-checkbox {
        display: inline;
    }
</style>
