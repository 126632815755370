<template>
  <div class="content-wrap">
    <section class="section section-white view-warp">
      <draggable v-model="initData" :options="dragIssuesOptions" @start="onStart" @end="onEnd" class="components-view">
        <div v-for="(item, index) in initData" class="component-control" :key="index">
          <template v-if="item.name === 'coupon'">
            <svg class="svg-icon" aria-hidden="true">
              <use xlink:href="#icon-tag"></use>
            </svg>
            优惠券
          </template>
          <template v-if="item.name === 'film'">
            <svg class="svg-icon" aria-hidden="true">
              <use xlink:href="#icon-video"></use>
            </svg>
            视频
          </template>
          <template v-if="item.name === 'goodsGrid'">
            <svg class="svg-icon" aria-hidden="true">
              <use xlink:href="#icon-grid"></use>
            </svg>
            商品栅格
          </template>
          <template v-if="item.name === 'goodsScroll'">
            <svg class="svg-icon" aria-hidden="true">
              <use xlink:href="#icon-scroll"></use>
            </svg>
            商品滚动
          </template>
          <template v-if="item.name === 'blokScroll'">
            <svg class="svg-icon" aria-hidden="true">
              <use xlink:href="#icon-scroll"></use>
            </svg>
            区块滚动
          </template>
          <template v-if="item.name === 'imgHotzone'">
            <svg class="svg-icon" aria-hidden="true">
              <use xlink:href="#icon-hotzone"></use>
            </svg>
            热区图
          </template>
          <template v-if="item.name === 'marquees'">
            <svg class="svg-icon" aria-hidden="true">
              <use xlink:href="#icon-marquees"></use>
            </svg>
            文字轮播
          </template>
          <template v-if="item.name === 'navigation'">
            <svg class="svg-icon" aria-hidden="true">
              <use xlink:href="#icon-navigation"></use>
            </svg>
            图片导航
          </template>
          <template v-if="item.name === 'search'">
            <svg class="svg-icon" aria-hidden="true">
              <use xlink:href="#icon-search"></use>
            </svg>
            搜索
          </template>
          <template v-if="item.name === 'showcase'">
            <svg class="svg-icon" aria-hidden="true">
              <use xlink:href="#icon-showcase"></use>
            </svg>
            橱窗
          </template>
          <template v-if="item.name === 'slider'">
            <svg class="svg-icon" aria-hidden="true">
              <use xlink:href="#icon-slider"></use>
            </svg>
            轮播
          </template>
          <template v-if="item.name === 'footer_nav'">
            <svg class="svg-icon" aria-hidden="true">
              <use xlink:href="#icon-slider"></use>
            </svg>
            底部导航
          </template>
          <template v-if="item.name === 'stickyLift'">
            <svg class="svg-icon" aria-hidden="true">
              <use xlink:href="#icon-slider"></use>
            </svg>
            电梯
          </template>
          <template v-if="item.name === 'imgHorizontal'">
            <svg class="svg-icon" aria-hidden="true">
              <use xlink:href="#icon-slider"></use>
            </svg>
            长热区
          </template>
          <template v-if="item.name === 'storeRuleList'">
            <svg class="svg-icon" aria-hidden="true">
              <use xlink:href="#icon-slider"></use>
            </svg>
            门店规则列表
          </template>
          <template v-if="item.name === 'tabsCut'">
            <svg class="svg-icon" aria-hidden="true">
              <use xlink:href="#icon-slider"></use>
            </svg>
            Tabs切换
          </template>
          <template v-if="item.name === 'goodsLift'">
            <svg class="svg-icon" aria-hidden="true">
              <use xlink:href="#icon-slider"></use>
            </svg>
            商品电梯
          </template>
          <template v-if="item.name === 'oldGoods'">
            <svg class="svg-icon" aria-hidden="true">
              <use xlink:href="#icon-grid"></use>
            </svg>
            以旧换新商品
          </template>
          <template v-if="item.name === 'oldEvaluate'">
            <svg class="svg-icon" aria-hidden="true">
              <use xlink:href="#icon-grid"></use>
            </svg>
            旧机评估
          </template>
          <!-- <template v-if="item.name === 'bottomFixed'">
            <svg class="svg-icon" aria-hidden="true">
              <use xlink:href="#icon-slider"></use>
            </svg>
            底部固定
          </template> -->
        </div>
      </draggable>
      <div class="template-view">
        <div class="template">
          <div class="template-header">
            <div class="template-title" :style="'background: #fff url(' +
              headerBg +
              ') no-repeat bottom; background-size: 100% auto;'
              ">
              <span>标题</span>
            </div>
          </div>
          <draggable v-model="components" :options="dragViewOptions" @start="onStart" @end="onEnd"
            class="components-wrap">
            <div class="component-item" v-for="(item, index) in components" @click="setCurrent(index)" :key="index">
              <transition name="el-fade-in-linear">
                <div v-if="editorIndex === index" class="iconfont icon-trash-alt" @click="removeCurrent"></div>
              </transition>
              <coupon v-if="item.name === 'coupon'" :res="item" :active="index == editorIndex"></coupon>
              <film v-if="item.name === 'film'" :res="item" :active="index == editorIndex"></film>
              <goodsGrid v-if="item.name === 'goodsGrid'" :res="item" :active="index == editorIndex"></goodsGrid>
              <blokScroll v-if="item.name === 'blokScroll'" :res="item" :active="index == editorIndex" />
              <goodsScroll v-if="item.name === 'goodsScroll'" :res="item" :active="index == editorIndex"></goodsScroll>
              <imgHotzone v-if="item.name === 'imgHotzone'" :res="item" :active="index == editorIndex"></imgHotzone>
              <marquees v-if="item.name === 'marquees'" :res="item" :active="index == editorIndex"></marquees>
              <navigation v-if="item.name === 'navigation'" :res="item" :active="index == editorIndex"></navigation>
              <search v-if="item.name === 'search'" :res="item" :active="index == editorIndex"></search>
              <showcase v-if="item.name === 'showcase'" :res="item" :active="index == editorIndex"></showcase>
              <slider v-if="item.name === 'slider'" :res="item" :active="index == editorIndex"></slider>
              <footerNav v-if="item.name === 'footer_nav'" :res="item" :active="index == editorIndex"></footerNav>
              <stickyLift v-if="item.name === 'stickyLift'" :res="item" :active="index == editorIndex" />
              <imgHorizontal v-if="item.name === 'imgHorizontal'" :res="item" :active="index == editorIndex" />
              <storeRuleList v-if="item.name === 'storeRuleList'" :res="item" :active="index == editorIndex" />
              <tabsCut v-if="item.name === 'tabsCut'" :res="item" :active="index == editorIndex" />
              <oldGoods v-if="item.name === 'oldGoods'" :res="item" :active="index == editorIndex" />
              <oldEvaluate v-if="item.name === 'oldEvaluate'" :res="item" :active="index == editorIndex" />
              <bottomFixed v-if="item.name === 'bottomFixed'" :res="item" :active="index == editorIndex" />
              <goodsLift v-if="item.name === 'goodsLift'" :res="item" :active="index == editorIndex" />
            </div>
          </draggable>
        </div>
      </div>
      <div class="setting-view">
        <div class="view-placeholder" v-if="editorIndex === null">
          <i class="iconfont icon-shapes"></i>
          请拖选左侧挂件
        </div>
        <couponEditor :res="editorData" @bindCoupons="showCoupons" @bindImgs="showImgs"></couponEditor>
        <filmEditor :res="editorData" @change="getVideo" @bindImgs="showImgs"></filmEditor>
        <goodsGridEditor :res="editorData" @bindGoods="showGoods"></goodsGridEditor>
        <goodsScrollEditor :res="editorData" @bindGoods="showGoods"></goodsScrollEditor>
        <imgHotzoneEditor :res="editorData" @bindImgs="showImgs" @bindLinks="showLinks" @change="getImgHotzoneVideo" :editorIndex="editorIndex"></imgHotzoneEditor>
        <marqueesEditor :res="editorData" @change="updataArticle" @bindLinks="showLinks"></marqueesEditor>
        <navigationEditor :res="editorData" @bindImgs="showImgs" @bindLinks="showLinks"></navigationEditor>
        <searchEditor :res="editorData"></searchEditor>
        <showcaseEditor :res="editorData" @bindImgs="showImgs" @bindLinks="showLinks"></showcaseEditor>
        <sliderEditor :res="editorData" @bindImgs="showImgs" @bindLinks="showLinks"></sliderEditor>
        <blokScrollEditor :res="editorData" @bindGoods="showGoods" @bindSelling="showSelling" @bindService="showService"
          @bindWelfare="showWelfare" @bindActivity="showActivity" @bindLinks="showLinks" @bindImgs="showImgs" />
        <footerNavEditor :res="editorData" @bindImgs="showImgs" @bindLinks="showLinks"></footerNavEditor>
        <stickyLiftEditor @bindImgs="showImgs" @bindLinks="showLinks" :res="editorData" />
        <imgHorizontalEditor @bindImgs="showImgs" @bindLinks="showLinks" @change="getImgHotzontalVideo" :res="editorData" />
        <storeRuleListEditor :res="editorData" />
        <tabsCutEditor :res="editorData" @bindImgs="showImgs" @bindLinks="showLinks" />
        <oldGoodsEditor :res="editorData" @bindGoods="showGoods" />
        <oldEvaluateEditor :res="editorData" @bindImgs="showImgs" @bindLinks="showLinks" />
        <bottomFixedEditor :res="editorData" @bindImgs="showImgs" @bindLinks="showLinks" />
        <goodsLiftEditor :res="editorData" @bindImgs="showImgs" @bindLinks="showLinks" @bindSelling="showEcPurchase" />
      </div>
    </section>
    <section slot="footer" class="content-center">
      <el-button class="btn-save" type="primary" @click="saveConfig">保存</el-button>
    </section>
    <imgPicker :dialog-visible="imgsVisible" :sc-status="isGetImage" @chooseImg="pickImg"
      @closeImgDialog="closeimgsVisible"></imgPicker>
    <linkSetter :visible="linksVisible" @setLink="setLink" @closeDialog="closeDialog" :template_name="template_name"
      :editorData="editorData" :editorIndex="editorIndex"></linkSetter>
    <goodsSelect :items-visible="goodsVisible" :get-status="setItemStatus" :rel-items-ids="relItemsIds"
      @chooseStore="pickGoods" @closeStoreDialog="closeDialog"></goodsSelect>
    <couponPicker :visible="couponsVisible" @pickCoupon="pickCoupon" @closeDialog="closeDialog"></couponPicker>
    <activitySelect v-if="activityVisible" :res="editorData" :activityVisible="activityVisible" @closeDialog="closeDialog"
      @chooseStore="pickActivity"></activitySelect>
    <welfareSelect v-if="welfareVisible" :res="editorData" :welfareVisible="welfareVisible"
      @closeDialog="closeDialog('welfare')" @chooseStore="pickWelfare"></welfareSelect>
    <sellingSelect v-if="sellingVisible" :res="editorData" :sellingVisible="sellingVisible"
      @closeDialog="closeDialog('selling')" @chooseStore="pickSelling"></sellingSelect>
    <serviceSelect v-if="serviceVisible" :res="editorData" :serviceVisible="serviceVisible"
      @closeDialog="closeDialog('service')" @chooseStore="pickSelling"></serviceSelect>
    <ecPurchaseSelect v-if="ecPurchaseVisible" :res="editorData" :ecPurchaseVisible="ecPurchaseVisible"
      @closeDialog="closeDialog('ecPurchase')" @chooseStore="pickSelling"></ecPurchaseSelect>
  </div>
</template>

<script>
const img = require('@/../static/img/demo-img.jpg')
const header = require('@/../static/img/topbar.png')
// 组件
import imgPicker from '@/components/imageselect'
import linkSetter from '@/components/template_links'
import goodsSelect from '@/components/goodsSelect'
import couponPicker from '@/components/coupon_picker'
import activitySelect from '@/components/activitySelect'
import welfareSelect from '@/components/welfareSelect'
import sellingSelect from '@/components/sellingSelect'
import serviceSelect from '@/components/serviceSelect'
import ecPurchaseSelect from '@/components/ecPurchaseSelect'

// 店铺装修组件
// view层组件
import coupon from '@/components/template/coupon'
import film from "@/components/template/film"
import goodsGrid from '@/components/template/goods_grid'
import goodsScroll from '@/components/template/goods_scroll'
import imgHotzone from '@/components/template/img_hotzone'
import marquees from '@/components/template/marquees'
import navigation from '@/components/template/navigation'
import search from '@/components/template/search'
import showcase from '@/components/template/showcase'
import slider from '@/components/template/slider'
import footerNav from '@/components/template/footer-nav'
import blokScroll from '@/components/template/blok-scroll'
import stickyLift from '@/components/template/sticky-lift'
import storeRuleList from '@/components/template/store-rule-list'
import tabsCut from '@/components/template/tabsCut'
import bottomFixed from '@/components/template/bottom-fixed'
import goodsLift from '@/components/template/goods-lift'
import oldGoods from '@/components/template/old_goods'
import oldEvaluate from '@/components/template/old_evaluate'
import imgHorizontal from '@/components/template/img-horizontal'


// control层组件
import couponEditor from '@/components/template_editor/coupon'
import filmEditor from "@/components/template_editor/film"
import goodsGridEditor from '@/components/template_editor/goods_grid'
import goodsScrollEditor from '@/components/template_editor/goods_scroll'
import imgHotzoneEditor from '@/components/template_editor/img_hotzone'
import marqueesEditor from '@/components/template_editor/marquees'
import navigationEditor from '@/components/template_editor/navigation'
import searchEditor from '@/components/template_editor/search'
import showcaseEditor from '@/components/template_editor/showcase'
import sliderEditor from '@/components/template_editor/slider'
import blokScrollEditor from '@/components/template_editor/blok-scroll'
import footerNavEditor from '@/components/template_editor/footer-nav'
import stickyLiftEditor from '@/components/template_editor/sticky-lift'
import storeRuleListEditor from '@/components/template_editor/store-rule-list'
import tabsCutEditor from '@/components/template_editor/tabsCut'
import bottomFixedEditor from '@/components/template_editor/bottom-fixed'
import goodsLiftEditor from '@/components/template_editor/goods-lift'
import oldGoodsEditor from '@/components/template_editor/old_goods'
import oldEvaluateEditor from '@/components/template_editor/old_evaluate'
import imgHorizontalEditor from '@/components/template_editor/img-horizontal'

// 第三方组件
import draggable from 'vuedraggable'

// api
import { savePageParams, getParamByTempName } from '@/api/wxa'
import { getItemsList } from '@/api/goods'

export default {
  props: {
    id: {
      type: String,
      default: ''
    },
    control: {
      type: Array,
      default: () => {
        return []
      }
    },
    usage: {
      type: String,
      default: 'template' // usage=='page'为页面编辑
    },
    template_name: {
      type: String
    }
  },
  components: {
    coupon,
    film,
    goodsGrid,
    goodsScroll,
    imgHotzone,
    marquees,
    navigation,
    search,
    showcase,
    slider,
    blokScroll,
    footerNav,
    stickyLift,
    stickyLiftEditor,
    imgHorizontal,
    imgHorizontalEditor,
    storeRuleList,
    storeRuleListEditor,
    couponEditor,
    filmEditor,
    goodsGridEditor,
    goodsScrollEditor,
    imgHotzoneEditor,
    marqueesEditor,
    navigationEditor,
    searchEditor,
    showcaseEditor,
    sliderEditor,
    footerNavEditor,
    tabsCut,
    tabsCutEditor,
    bottomFixed,
    bottomFixedEditor,
    oldGoods,
    oldGoodsEditor,
    goodsLift,
    goodsLiftEditor,
    oldEvaluate,
    oldEvaluateEditor,
    // 其他组件
    blokScrollEditor,

    imgPicker,
    linkSetter,
    goodsSelect,
    couponPicker,
    activitySelect,
    welfareSelect,
    sellingSelect,
    serviceSelect,
    ecPurchaseSelect,
    // 第三方组件
    draggable
  },
  data() {
    return {
      // 福利
      welfareVisible: false,
      // 热卖
      sellingVisible: false,
      // 服务
      serviceVisible: false,
      // 员工购
      ecPurchaseVisible: false,
      componentHeight: '',
      editorIndex: null,
      editorData: {},
      editorDataIndex: null,
      editorDatahotzoneList: null,
      editorZoneIndex: null,
      editorVideoIndex: null,
      editorDataType: null,
      dragIssuesOptions: {
        group: {
          name: 'easyview',
          put: false
        },
        sort: false,
        fallbackClass: 'fallback-class',
        chosenClass: 'chosen-class',
        forceFallback: false
      },
      dragViewOptions: {
        group: {
          name: 'easyview'
        },
        animation: 300,
        forceFallback: false,
        scroll: true
      },
      saveInit: '',
      initData: [{
        name: 'coupon',
        base: {
          title: '到店优惠',
          subtitle: '游客专享福利',
          padded: true
        },
        data: []
      },
      {
        name: "film",
        base: {
          title: "",
          subtitle: "",
          flowName_var: "",
          floor_var: "",
          padded: true,
          video_cover: '',
        },
        config: {
          autoplay: false
        },
        data: [
          {
            media_id: "",
            name: "",
            update_time: "",
            url: "",
          },
        ],
      },
      {
        name: 'goodsGrid',
        base: {
          title: '爆品直邮',
          subtitle: '宅家买遍全法',
          padded: true
        },
        config: {
          brand: true,
          showPrice: false,
          style: 'grid'
        },
        data: [{
          imgUrl: '',
          title: '',
          brand: '',
          goodsId: ''
        }]
      },
      {
        name: 'goodsScroll',
        base: {
          title: '当地必买',
          subtitle: '看看大家都在买什么',
          padded: true
        },
        config: {
          seckillId: '',
          leaderboard: true,
          showPrice: false,
          type: 'goods'
        },
        data: [{
          imgUrl: '',
          title: '',
          brand: '',
          goodsId: ''
        }]
      },
      {
        name: 'blokScroll',
        base: {
          title: '当地必买',
          subtitle: '看看大家都在买什么',
          padded: true,
          backgroundImg: '',
          description: '123'
        },
        config: {
          seckillId: '',
          leaderboard: true,
          showPrice: false,
          type: 'welfare',
          desc_type: 'describe',
          moreLink: {
            id: '',
            title: '',
            linkPage: ''
          }
        },
        data: []
      },
      {
        name: 'footer_nav',
        base: {
          padded: true
        },
        config: {
          imgUrl: ''
        },
        data: {
          wrap: {
            background: '#fff'
          },
          btn: {
            text: '查看身边的门店',
            linkPage: '',
            id: '',
            title: '',
            style: {
              color: '#fff',
              width: 200,
              background: '#2d69fb'
            }
          }
        }
      },
      {
        name: 'imgHotzone',
        base: {
          title: '热区图',
          subtitle: '图上随意画块块',
          padded: true
        },
        config: {
          imgUrl: ''
        },
        data: []
      },
      {
        name: 'navigation',
        base: {
          padded: true
        },
        data: [{
          imgUrl: '../../images/jichuhufu.png',
          linkPage: '',
          content: '基础护肤',
          title: '',
          id: ''
        },
        {
          imgUrl: '../../images/caizhuangxiangshui.png',
          linkPage: '',
          content: '彩妆香水',
          title: '',
          id: ''
        },
        {
          imgUrl: '../../images/yingyangbaojian.png',
          linkPage: '',
          content: '营养保健',
          title: '',
          id: ''
        },
        {
          imgUrl: '../../images/manjianyouhui.png',
          linkPage: '',
          content: '满减优惠',
          title: '',
          id: ''
        },
        {
          imgUrl: '../../images/fenxiangpindan.png',
          linkPage: '',
          content: '分享拼单',
          title: '',
          id: ''
        }]
      },
      {
        name: 'search',
        base: {
          padded: false
        },
        config: {
          fixTop: false,
          scanCode: true
        }
      },
      {
        name: 'showcase',
        base: {
          title: '旅游资讯',
          subtitle: '',
          padded: true
        },
        config: {
          style: 1
        },
        data: [{
          imgUrl: '../../images/demo01.jpg',
          linkPage: '',
          content: '',
          title: '',
          id: ''
        },
        {
          imgUrl: '../../images/demo02.jpg',
          linkPage: '',
          content: '',
          title: '',
          id: ''
        },
        {
          imgUrl: '../../images/demo03.jpg',
          linkPage: '',
          content: '',
          title: '',
          id: ''
        }]
      },
      {
        name: 'slider',
        base: {
          title: '分享拼单',
          subtitle: '分享有好礼，拼单更便宜',
          padded: true
        },
        config: {
          current: 0,
          interval: 3000,
          spacing: 0,
          dot: 'true', // 是否显示导航
          dotLocation: 'right', // 导航位置
          dotColor: 'dark', // 导航颜色
          shape: 'circle', // 导航形状 circle rectangle dot-size-switch
          numNavShape: 'rect', // 'circle' 'rectangle' 数字导航的形状
          dotCover: true, // 导航是否覆盖轮播
          rounded: false, // 轮播是否有圆角
          padded: false, // 轮播的内间距
          content: true,
          back_color: '', //背景颜色
          dot_noCover: '', //指示点未选中颜色
          dot_cover: '', //指示点选中颜色
          autoplay: true, //是否自动切换
          pages_type: '' //指示点和背景色是否可配置
        },
        data: []
      },
      {
        name: 'stickyLift',
        base: {
          title: '电梯',
          subtitle: '电梯小标题',
          floor_var: '',
          padded: true
        },
        data: [{
          imgUrl: '',
          title: '',
          data: [],
          longImgUrl: '',
          longData: [],
          shortBotImgUrl: '',
          shortBotData: [],
        }]
      },
      {
        name: 'imgHorizontal',
        base: {
          title: '长热区',
          subtitle: '长热区小标题',
          floor_var: '',
          padded: true
        },
        data: [{
          imgUrl: '',
          data: [],
          longImgUrl: '',
          longData: []
        }]
      },
      {
        name: 'marquees',
        base: {
          title: '',
          subtitle: '',
          padded: true
        },
        config: {
          direction: 'vertical',
          bgcolor: '',
          fontcolor: ''
        },
        data: []
      },
      {
        name: 'storeRuleList',
        base: {
          title: '门店规则列表',
          subtitle: '',
          padded: true
        },
        config: {
          rule_id: 0,
          num: 100
        },
        data: []
      },
      {
        name: 'tabsCut',
        base: {
          title: 'Tabs切换',
          subtitle: '可设置切换不同的展示内容',
          padded: true
        },
        config: {
          ruleImgUrl: ''
        },
        data: []
      },
      {
        name: 'goodsLift',
        base: {
          title: '商品电梯',
          subtitle: '商品电梯小标题',
          floor_var: '',
          padded: true
        },
        data: []
      },
      {
        name: 'oldGoods',
        base: {
          title: '以旧换新',
          subtitle: '',
          padded: true,
          listTitle: ''
        },
        config: {
          // brand: false,
          // showPrice: false,
          // style: 'grid'
        },
        data: [{
          imgUrl: '',
          title: '',
          brand: '',
          goodsId: ''
        }]
      },
      {
        name: 'oldEvaluate',
        base: {
          padded: true
        },
        data: [
          {
            label: '未评估',
            old_type:'on',
            imgUrl: '',
            linkPage: '',
            id: ''
          },
          {
            label: '已评估',
            old_type:'yes',
            imgUrl: '',
            linkPage: '',
            id: ''
          }
        ]
      },
      {
        name: 'bottomFixed',
        base: {
          title: '底部固定',
          subtitle: '',
          padded: true
        },
        config: {
          imgUrl: '',
          moreLink: {
            linkPage: '',
            title: '',
            id: '',
            goods_id: '',
            rule_id: '0',
            CopyUrl: '',
            CopyTitle: '',
            linkTitle: '',
            linkAppid: ''
          }
        },
        data: []
      }
      ],
      faverite: {
        name: 'faverite_type',
        base: {
          title: '猜你喜欢',
          subtitle: '',
          padded: true
        },
        config: {
          brand: false,
          showPrice: false,
          style: 'grid'
        },
        data: [{
          imgUrl: '',
          title: '',
          brand: '',
          goodsId: ''
        }]
      },
      components: [],
      currentTab: 0,
      tabIcon: '',
      tabs: {
        name: 'tabs',
        config: {
          color: '#333333',
          selectedColor: '#1f82e0',
          backgroundColor: '#ffffff'
        },
        data: [{
          pagePath: '/pages/index',
          text: '首页',
          name: 'home',
          iconPath: '',
          selectedIconPath: ''
        }, {
          pagePath: '/pages/category/index',
          text: '分类',
          name: 'category',
          iconPath: '',
          selectedIconPath: ''
        }, {
          pagePath: '/pages/cart/espier-index',
          text: '购物车',
          name: 'cart',
          iconPath: '',
          selectedIconPath: ''
        }, {
          pagePath: '/pages/member/index',
          text: '我的',
          name: 'member',
          iconPath: '',
          selectedIconPath: ''
        }]
      },
      headerBg: header,
      // 图片选择器
      isGetImage: false,
      imgsVisible: false,
      // 商品选择器
      goodsVisible: false,
      setItemStatus: false,
      activityVisible: false,
      relItemsIds: [],
      // 优惠券选择器
      couponsVisible: false,
      // 路径设置组件
      linksVisible: false,
      // 图标选择器
      iconsVisible: false,
      // 开启小程序定位
      isOpenLocation: true,
      // 开启猜你喜欢
      isOpenFaverite: true,
      // 开启扫码功能
      isOpenScancode: true
    }
  },
  mounted() {
    if (this.system_mode === 'platform') {
      let index = this.initData.findIndex((n) => n.name == 'search')
      this.initData.splice(index, 1)
    }
  },
  computed: {
    page_template() {
      return this.usage === 'page'
    }
  },
  watch: {
    id: {
      handler(newName, oldName) {
        let filter = { template_name: 'yykweishop', version: `shop_${newName}`, page_name: 'shop_home' }
        this.page_template && Object.assign(filter, { version: 'v1.0.1', page_name: `custom_${newName}`, template_name: this.template_name })
        getParamByTempName(filter).then(res => {
          res.data.data.config.forEach((item) => {
            if (item.name === "slider") {
              if (!item.config.pages_type) {
                item.config.pages_type = ''
              }
              if (item.config.autoplay == null || item.config.autoplay == undefined) {
                item.config.autoplay = true
              }
              item.data.forEach((i) => {
                if (!i.media_type) {
                  i.media_type = 'img'
                }
              })
            }
          })
          this.components = res.data.data.config
        })
      },
      immediate: true
    }
  },
  methods: {
    // 拖拽绑定事件
    onStart(evt) {
      if (evt.target.className === 'components-view') {
        this.saveInit = JSON.stringify(this.initData[evt.oldIndex])
      } else {
        this.setCurrent(evt.oldIndex)
      }
    },
    onEnd(evt) {
      this.setCurrent(evt.newIndex)
      if (evt.target.className === 'components-view' && evt.to.className === 'components-wrap') {
        this.initData.splice(evt.oldIndex, 0, JSON.parse(this.saveInit))
      }
    },
    // 设置当前编辑的组件
    setCurrent(val) {
      this.editorIndex = val
      if (val === 'tabs') {
        this.editorData = { ...this.tabs }
      } else {
        this.editorData = { ...this.components[val] }
      }
    },
    // 删除当前组件
    removeCurrent() {
      this.$confirm('确认删除当前组件？')
        .then(_ => {
          this.editorData = {}
          this.components.splice(this.editorIndex, 1)
          this.editorIndex = null
          this.editorDataIndex = null
        })
        .catch(_ => { })
    },
    // 图片选择器绑定事件
    showImgs(index, tabIcon, type, zindex) {
      debugger
      this.imgsVisible = true
      this.isGetImage = true
      if (this.components[this.editorIndex].name === 'tabsCut') {
        this.editorDataIndex = index
        this.editorDatahotzoneList = tabIcon
        return
      }
      if (typeof index !== undefined) {
        this.editorDataIndex = index
        this.editorDataType = type
      }
      if (typeof type !== undefined) { // 热区-上传视频封面
        this.editorVideoIndex = type
      }
      if (typeof zindex !== undefined) { // 热区
        this.editorZoneIndex = zindex;
      }
      if (tabIcon) {
        this.tabIcon = tabIcon
      }
    },
    pickImg(data) {
      if (this.editorIndex === 'tabs') {
        if (this.tabIcon === 'default') {
          this.editorData.data[this.editorDataIndex].iconPath = data.url
          this.tabs.data[this.editorDataIndex].iconPath = data.url
        } else {
          this.editorData.data[this.editorDataIndex].selectedIconPath = data.url
          this.tabs.data[this.editorDataIndex].selectedIconPath = data.url
        }
      } else {
        if (
          this.components[this.editorIndex].name === 'imgHotzone' ||
          this.components[this.editorIndex].name === 'bottomFixed'
        ) {
          if (this.editorVideoIndex == 'video_cover') { // 热区-视频-上传视频封面
            this.$set(
              this.components[this.editorIndex].data[this.editorDataIndex],
              "video_cover",
              data.url
            )
          } else {
            this.components[this.editorIndex].config.imgUrl = data.url;
          }
        } else if (this.components[this.editorIndex].name == "film") {
          this.components[this.editorIndex].base.video_cover = data.url;
        } else if (this.components[this.editorIndex].name === 'stickyLift' || this.components[this.editorIndex].name === 'imgHorizontal') {
          if (this.editorVideoIndex == 'video_cover') { // 热区-视频-上传视频封面
            console.log(this.components, this.editorIndex, this.editorDataIndex, this.editorZoneIndex)
            this.$set(
              this.components[this.editorIndex].data[this.editorDataIndex].longData[this.editorZoneIndex], // 长热区只有一个
              "video_cover",
              data.url
            )
            this.imgsVisible = false;
            return
          }
          if (this.editorDataType == 'long' || this.editorDataType == 'shortBot') {
            this.$set(
              this.components[this.editorIndex].data[this.editorDataIndex],
              this.editorDataType == 'long' ? "longImgUrl" : 'shortBotImgUrl',
              data.url
            )
            this.$set(
              this.editorData.data[this.editorDataIndex],
              this.editorDataType == 'long' ? "longImgUrl" : 'shortBotImgUrl',
              data.url
            )
            this.$set(
              this.editorData.data[this.editorDataIndex],
              this.editorDataType == 'long' ? "longData" : 'shortBotData',
              this.editorDataType == 'long' ? (this.editorData.data[this.editorDataIndex].longData || []) : (this.editorData.data[this.editorDataIndex].shortBotData || [])
            )
            this.$set(
              this.components[this.editorIndex].data[this.editorDataIndex],
              this.editorDataType == 'long' ? "longData" : 'shortBotData',
              this.editorDataType == 'long' ? (this.components[this.editorIndex].data[this.editorDataIndex].longData || []) : (this.components[this.editorIndex].data[this.editorDataIndex].shortBotData || [])
            )
          } else {
            this.$set(
              this.components[this.editorIndex].data[this.editorDataIndex],
              "imgUrl",
              data.url
            )
            this.$set(
              this.editorData.data[this.editorDataIndex],
              "imgUrl",
              data.url
            )
          }
        } else if (this.components[this.editorIndex].name === 'tabsCut') {
          // tabsCut挂件添加使用规则图片
          if (this.editorDataIndex == 'ruleImgUrl') {
            this.components[this.editorIndex].config.ruleImgUrl = data.url
          } else if (this.editorDatahotzoneList == 'fixed') {
            this.components[this.editorIndex].data[this.editorDataIndex].fixedBtnImg = data.url
          } else if (this.editorDatahotzoneList != null) {
            //tabsCut挂件添加切换项图片
            this.components[this.editorIndex].data[this.editorDataIndex].hotzoneList[this.editorDatahotzoneList].imgUrl = data.url
          }
        } else if (this.components[this.editorIndex].name === 'footer_nav') {
          this.components[this.editorIndex].config.imgUrl = data.url
        } else {
          this.$set(
            this.components[this.editorIndex].data[this.editorDataIndex],
            "imgUrl",
            data.url
          )
          this.$set(
            this.editorData.data[this.editorDataIndex],
            "imgUrl",
            data.url
          )
        }
      }
      this.imgsVisible = false
    },
    closeimgsVisible() {
      this.imgsVisible = false
    },
    showSelling() {
      this.sellingVisible = true
    },
    showEcPurchase() {
      this.ecPurchaseVisible = true
    },
    pickSelling(selection) {
      this.components[this.editorIndex].data = selection
      this.editorData.data = selection
      this.sellingVisible = false
      this.serviceVisible = false
      this.ecPurchaseVisible = false
    },
    showService() {
      this.serviceVisible = true
    },
    showWelfare() {
      this.welfareVisible = true
    },
    showActivity() {
      this.activityVisible = true
    },
    pickActivity(selection) {
      this.components[this.editorIndex].data = selection
      this.editorData.data = selection
      this.activityVisible = false
    },
    pickWelfare(selection) {
      this.components[this.editorIndex].data = selection
      this.editorData.data = selection
      this.welfareVisible = false
    },

    // 商品选择器绑定事件
    showGoods(index) {
      if (this.editorData.data.length > 0) {
        let ids = []
        this.editorData.data.forEach(item => {
          ids.push(item.key || item.goodsId)
        })
        let itemParams = { item_id: ids, page: 1, pageSize: 100, item_type: 'normal' }
        getItemsList(itemParams).then(res => {
          this.relItemsIds = res.data.data.list
          setTimeout(() => {
            this.setItemStatus = true
            this.goodsVisible = true
          }, 500)
        })
      } else {
        this.setItemStatus = true
        this.goodsVisible = true
      }
    },
    pickGoods(data) {
      this.relItemsIds = data
      let values = []
      if (data.length > 0) {
        data.forEach(item => {
          let obj = {
            // imgUrl: item.pics[0],
            imgUrl: item.home_pic,
            title: item.itemName,
            goodsId: item.itemId,
            brand: item.brand_logo,
            price: item.price
          }
          values.push(obj)
        })
      }
      this.editorData.data = values
      this.components[this.editorIndex].data = values
      this.goodsVisible = false
    },
    // 更新跑马灯数据
    updataArticle(data) {

    },
    // 优惠券选择器绑定事件
    showCoupons() {
      this.couponsVisible = true
    },
    pickCoupon(data) {
      this.editorData.data = data
      this.components[this.editorIndex].data = data
      this.couponsVisible = false
    },
    getImgHotzoneVideo(d) {
      const { data, index } = d
      this.editorData.data[index].video=data
      this.components[this.editorIndex].data[index].video=data
    },
    getImgHotzontalVideo(d) {
      const { data, index, zindex } = d
      this.editorData.data[index].longData[zindex].video=data
      this.components[this.editorIndex].data[index].longData[zindex].video=data
    },
    showLinks(index, _dataIndex, editorDataType) {
      this.linksVisible = true
      this.editorDataIndex = index
      if (_dataIndex !== undefined) {
        this.editorZoneIndex = _dataIndex
        this.editorDataType = editorDataType
      } else {
        this.editorZoneIndex = null
        this.editorDataType = null
      }
    },
    // 链接选择器绑定事件
    setLink(data, type) {
      const curComName = this.components[this.editorIndex].name
      if (type === 'redpackage' && (curComName !== 'stickyLift' ||curComName !== 'imgHorizontal')) {
        this.editorData.data[this.editorDataIndex].id = data.id
        this.editorData.data[this.editorDataIndex].title = data.title
        this.editorData.data[this.editorDataIndex].ctoken = data.ctoken
        this.editorData.data[this.editorDataIndex].goods_id = data.goods_id
        this.editorData.data[this.editorDataIndex].linkPage = type
        this.components[this.editorIndex].data[this.editorDataIndex].id =
          data.id
        this.components[this.editorIndex].data[this.editorDataIndex].title =
          data.title
        this.components[this.editorIndex].data[this.editorDataIndex].ctoken =
          data.ctoken
        this.components[this.editorIndex].data[this.editorDataIndex].linkPage = type
        this.components[this.editorIndex].data[this.editorDataIndex].goods_id = data.goods_id
        if (type == 'platform_order_marketing') {
          this.editorData.data[this.editorDataIndex].card_id = data.card_id
          this.components[this.editorIndex].data[this.editorDataIndex].card_id = data.card_id
        }
        this.linksVisible = false
      } else if (curComName === 'stickyLift' || curComName === 'imgHorizontal') {
        let curdata = {}
        if (this.editorDataType == 'long') {
          curdata = this.components[this.editorIndex].data[this.editorDataIndex].longData[this.editorZoneIndex]
        } else if (this.editorDataType == 'shortBot') {
          curdata = this.components[this.editorIndex].data[this.editorDataIndex].shortBotData[this.editorZoneIndex]
        } else {
          curdata = this.components[this.editorIndex].data[this.editorDataIndex].data[this.editorZoneIndex]
        }
        const { id, title, ctoken, card_type: cardType, goods_id: goodsId } = data
        curdata.id = id
        curdata.title = title
        curdata.card_type = cardType
        curdata.linkPage = type
        curdata.goods_id = goodsId
        if (type === 'redpackage') {
          let editData = {}
          if (this.editorDataType == 'long') {
            editData = this.editorData.data[this.editorDataIndex].longData[this.editorZoneIndex]
          } else if (this.editorDataType == 'shortBot') {
            curdata = this.editorData.data[this.editorDataIndex].shortBotData[this.editorZoneIndex]
          } else {
            editData = this.editorData.data[this.editorDataIndex].data[this.editorZoneIndex]
          }
          editData.id = id
          editData.title = title
          editData.ctoken = ctoken
          editData.goods_id = goodsId
          editData.linkPage = type
        }
        if (type == 'platform_order_marketing') {
          this.editorData.data[this.editorDataIndex].card_id = data.card_id
          this.components[this.editorIndex].data[this.editorDataIndex].card_id = data.card_id
        }
        this.linksVisible = false
      } else if (this.editorData.name === 'footer_nav') {
        this.editorData.data.btn.linkPage = type
        this.editorData.data.btn.title = data.title
        this.editorData.data.btn.id = data.id
        this.components[this.editorIndex].data.btn.linkPage = type
        this.components[this.editorIndex].data.btn.title = data.title
        this.components[this.editorIndex].data.btn.id = data.id
      } else if (curComName === 'tabsCut') {
        if (this.editorZoneIndex == 'fixed') {
          this.components[this.editorIndex].data[this.editorDataIndex].fixedBtnData.linkPage = type
          this.components[this.editorIndex].data[this.editorDataIndex].fixedBtnData.title = data.title
          this.components[this.editorIndex].data[this.editorDataIndex].fixedBtnData.id = data.id
          this.components[this.editorIndex].data[this.editorDataIndex].fixedBtnData.goods_id = data.goods_id
          this.components[this.editorIndex].data[this.editorDataIndex].fixedBtnData.card_type = data.card_type
        } else {
          this.components[this.editorIndex].data[this.editorDataIndex].hotzoneList[this.editorZoneIndex].list[this.editorDataType].linkPage = type
          this.components[this.editorIndex].data[this.editorDataIndex].hotzoneList[this.editorZoneIndex].list[this.editorDataType].title = data.title
          this.components[this.editorIndex].data[this.editorDataIndex].hotzoneList[this.editorZoneIndex].list[this.editorDataType].id = data.id
          this.components[this.editorIndex].data[this.editorDataIndex].hotzoneList[this.editorZoneIndex].list[this.editorDataType].goods_id = data.goods_id
          this.components[this.editorIndex].data[this.editorDataIndex].hotzoneList[this.editorZoneIndex].list[this.editorDataType].card_type = data.card_type
        }
      } else if (curComName === 'bottomFixed') {
        this.components[this.editorIndex].config.moreLink.linkPage = type
        this.components[this.editorIndex].config.moreLink.title = data.title
        this.components[this.editorIndex].config.moreLink.id = data.id
        this.components[this.editorIndex].config.moreLink.goods_id = data.goods_id
        this.components[this.editorIndex].config.moreLink.card_type = data.card_type
      } else {
        this.editorData.data[this.editorDataIndex].id = data.id
        this.editorData.data[this.editorDataIndex].title = data.title
        this.editorData.data[this.editorDataIndex].goods_id = data.goods_id
        this.editorData.data[this.editorDataIndex].linkPage = type
        this.components[this.editorIndex].data[this.editorDataIndex].id = data.id
        this.components[this.editorIndex].data[this.editorDataIndex].title = data.title
        this.components[this.editorIndex].data[this.editorDataIndex].card_type = data.card_type
        this.components[this.editorIndex].data[this.editorDataIndex].linkPage = type
        this.components[this.editorIndex].data[this.editorDataIndex].goods_id = data.goods_id
        if (type == 'platform_order_marketing') {
          this.editorData.data[this.editorDataIndex].card_id = data.card_id
          this.components[this.editorIndex].data[this.editorDataIndex].card_id = data.card_id
        }
      }
      this.linksVisible = false
    },
    // 选择器公用关闭事件
    closeDialog(key) {
      switch (key) {
        case 'coupon':
          this.couponsVisible = false
          break
        case 'link':
          this.linksVisible = false
          break
        case 'icon':
          this.iconsVisible = false
          break
        case 'activity':
          this.activityVisible = false
          break
        case 'welfare':
          this.welfareVisible = false
          break
        case 'selling':
          this.sellingVisible = false
          break
        case 'service':
          this.serviceVisible = false
          break
        case 'ecPurchase':
          this.ecPurchaseVisible = false
          break
        default:
          this.goodsVisible = false
      }
    },
    // 保存配置
    saveConfig() {
      let filterList = this.components.filter(el => el.name == "stickyLift" || el.name == "imgHorizontal")
      const noImgList = []
      filterList.forEach(el => {
        el.data.forEach(item => {
          if (!item.imgUrl && !item.longImgUrl) {
            noImgList.push(item)
            this.$message.error('缺少热区图，无法保存!')
            return
          }
        })
      })

      let filterStoreList = this.components.filter(el => el.name == "storeRuleList")

      if (filterStoreList.length > 1) {
        this.$message.error('门店规则列表，只能设置一个!')
        return
      }
      if (filterStoreList.length && filterStoreList[0].config.num < 0) {
        this.$message.error('门店规则列表，高度应大于0!')
        return
      }

      if (noImgList.length > 0) return

      let filter = { template_name: 'yykweishop', version: `shop_${this.id}`, config: JSON.stringify(this.components), page_name: 'shop_home' }
      this.page_template && Object.assign(filter, { version: 'v1.0.1', page_name: `custom_${this.id}`, template_name: this.template_name })
      savePageParams(filter).then(res => {
        if (res.data.data.status) {
          this.$message({
            message: '保存成功',
            type: 'success',
            onClose: () => {
              this.$emit('saved', false)
            }
          })
        }
      })
    },
    // 视频选择器绑定事件
    getVideo(data) {
      Object.assign(this.editorData.data[0], data);
      Object.assign(this.components[this.editorIndex].data[0], data);
    },
  }
}
</script>

<style scoped lang="scss">
.content-wrap {
  position: relative;
  height: 100%;
}

.content-view {
  height: 100%;
  overflow-y: overlay;

  .content {
    width: 320px;
    margin: 0 auto;

    .component-padded {
      padding: 10px 0;
    }

    .edit-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 50%;
      width: 340px;
      bottom: 0;
      transform: translateX(-50%);
      transition: all 0.3s ease;

      button {
        width: 200px;
      }
    }

    &.is-edit {
      .edit-btn {
        background: rgba(31, 130, 224, 0.3);
        z-index: 999;
        opacity: 0;
      }

      &:hover {
        .edit-btn {
          opacity: 1;
        }
      }
    }
  }
}

.fallback-class {
  width: 50px;
  height: 50px;
}

.chosen-class {
  position: relative;
  height: 50px;
  text-align: center;

  .svg-icon {
    width: 26px;
    height: 26px;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 50px;
    right: 50px;
    bottom: 0;
    background: #ffb28b;
    box-shadow: inset 0 0 0 2px #ff5000;
    content: "";
  }
}

.view-warp {
  position: relative;
}

.components-view {
  position: absolute;
  top: 0;
  left: 0;
  width: 70px;
  height: 100%;
  overflow: auto;

  .component-control {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    text-align: center;
    background: #fff;
    font-size: 12px;
    color: #999;

    .svg-icon {
      width: 26px;
      height: 26px;
      margin-bottom: 5px;
    }
  }
}

.components-view::-webkit-scrollbar {
  width: 4px;
  height: 10px;
}

.components-view::-webkit-scrollbar-track {
  background-color: #fff;
}

.components-view::-webkit-scrollbar-thumb {
  background-color: #efefef;
}

.template-view {
  width: 460px;
  margin-left: 80px;
  overflow-y: overlay;
  background: #efefef;

  .template {
    position: relative;

    .template-header {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      padding-top: 20px;
      z-index: 99;
      background: #efefef;

      .template-title {
        width: 320px;
        height: 60px;
        margin: 0 auto;
        text-align: center;

        span {
          display: inline-block;
          padding-top: 30px;
        }
      }
    }

    .components-wrap {
      height: calc(100vh - 170px);
    }

    .section {
      margin-bottom: 10px;
    }
  }
}

.setting-view {
  position: absolute;
  left: 540px;
  top: 0;
  right: 0;
  height: 100%;

  .view-placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #dadadd;
    font-size: 14px;

    .iconfont {
      font-size: 42px;
    }
  }
}

.global-setting {
  display: flex;
  align-items: center;
}

.btn-save {
  width: 200px;
  border-radius: 60px;
}
</style>

<style lang="scss">
.content-view {
  height: 100%;
  overflow-y: overlay;

  .content {
    .component-header {
      display: flex;
      align-items: center;
      padding: 0 10px 5px 10px;

      .component-title {
        display: flex;
        align-items: flex-end;
        flex: 1;
        font-size: 16px;

        &.middle {
          align-items: center;
        }

        .subtitle {
          padding-left: 5px;
          font-size: 12px;
          color: #999;
        }
      }
    }
  }
}
</style>
