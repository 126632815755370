<template>
  <div v-loading="loading">
    <div v-if="type !== 'category'">
      <el-table
        :data="list"
        width="100%"
        highlight-current-row
        @current-change="handleCurrentChange"
      >
        <el-table-column v-if="type != 'platform_order_marketing'" prop="id" label="ID" :width="type == 'marketing' ? 200 : 100" />
        <el-table-column v-if="type != 'platform_order_marketing'" prop="title" label="标题名称" />
        <el-table-column
          v-if="type == 'redpackage'"
          property="ctoken"
          label="ctoken"
        />
        <el-table-column v-if="type == 'platform_order_marketing'" prop="title" label="活动名称" />
        <el-table-column v-if="type == 'platform_order_marketing'" label="活动状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.activity_begin_time >= new Date().getTime()/1000" type="warning" effect="light" size="mini">未开始</el-tag>
            <el-tag v-else-if="scope.row.activity_begin_time <= new Date().getTime()/1000 && scope.row.activity_end_time >= new Date().getTime()/1000" type="success" effect="light" size="mini">进行中</el-tag>
            <el-tag v-else type="danger"  size="mini" effect="light">已结束</el-tag>
          </template>
        </el-table-column>
        <el-table-column v-if="type == 'platform_order_marketing'" label="有效时间">
          <template slot-scope="scope">
            {{ scope.row.activity_begin_time | datetime('yyyy-MM-dd') }}~{{ scope.row.activity_end_time | datetime('yyyy-MM-dd') }}
          </template>
        </el-table-column>

        <el-table-column v-if="type == 'liverooms'" label="图片" :width="100">
          <template slot-scope="scope">
            <!-- class="avatar" -->
          <img :src="scope.row.cover_img"   height="60"  /><a :href="scope.row.cover_img" style="font-size: 20px;" target="_blank">+</a>
          </template>
        </el-table-column>


        <el-table-column v-if="type == 'liverooms'" label="直播时间" :width="200">
          <template slot-scope="scope">
            开始：{{ scope.row.start_time | datetime('yyyy-MM-dd hh:mm:ss') }}</br>
            结束：{{ scope.row.end_time | datetime('yyyy-MM-dd hh:mm:ss') }}
          </template>
        </el-table-column>

      </el-table>
      <el-pagination
        class="pager"
        :page-size="params.pageSize"
        layout="prev, pager, next"
        :total="total"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>
    <el-table
      :data="list"
      width="100%"
      height="420"
      highlight-current-row
      @current-change="handleCurrentChange"
      v-else-if="type === 'modal'"
    >
    </el-table>
    <el-table
      v-else
      :data="list"
      height="420"
      row-key="id"
      default-expand-all
      highlight-current-row
      @current-change="handleCurrentChange"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column prop="id" label="ID" width="180"> </el-table-column>
      <el-table-column prop="title" label="分类名"> </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/api'

export default {
  props: {
    type: {
      type: String,
      default: ''
    },
    keywords: {
      type: String,
      default: ''
    },
    store: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      list: [],
      params: {
        pageSize: 10,
        page_size: 10,
        page: 1
      },
      total: 0,
      loading: false
    }
  },
  computed: {
    ...mapGetters([
      'template_name',
      'wxapp_id'
    ])
  },
  watch: {
    type (val) {
      if (val) {
        this.params.page = 1
        this.fetch(val)
      }
    },
    keywords (val) {
      this.params.page = 1
      this.fetch(this.type)
    },
    store (val) {
      this.params.page = 1
      this.fetch(this.type)
    }
  },
  methods: {
    fetch (type) {
      this.loading = true
      let query = JSON.parse(JSON.stringify(this.params))
      switch (type) {
        case 'goods':
          Object.assign(query, {
            item_type: 'normal',
            keywords: this.keywords,
            distributor_id: this.store.id || ''
          })
          if (this.system_mode == 'platform' || query.distributor_id == '0' || !query.distributor_id) {
            api.goods.getItemsList(query).then(res => {
              let list = []
              res.data.data.list.map(item => {
                list.push({
                  id: item.itemId,
                  title: item.itemName,
                  imgUrl: item.pics[0],
                  goods_id: item.goods_id
                })
              })
              this.list = list
              this.total = res.data.data.total_count
              this.loading = false
            })
          } else {
            api.marketing.getDistributorItems(query).then(res => {
              let list = []
              res.data.data.list.map(item => {
                list.push({
                  id: item.itemId,
                  title: item.itemName,
                  imgUrl: item.pics[0]
                })
              })
              this.list = list
              this.total = res.data.data.total_count
              this.loading = false
            })
          }
          break
        case 'regactivity':
          Object.assign(query, {
            is_valid: true
          })
          api.selfhelpform.regActivityEasylist(query).then(res => {
            let list = []
            res.data.data.list.map(item => {
              list.push({
                id: item.activity_id,
                title: item.activity_name
              })
            })
            this.list = list
            this.total = res.data.data.total_count
            this.loading = false
          })
          break
        case 'store':
          Object.assign(query, {
            is_valid: true,
            name: this.keywords
          })
          api.marketing.getDistributorList(query).then(res => {
            let list = []
            res.data.data.list.map(item => {
              list.push({
                id: item.distributor_id,
                title: item.name
              })
            })
            this.list = list
            this.total = res.data.data.total_count
            this.loading = false
          })
          break
        case 'category':
          api.goods.getCategory(this.params).then(res => {
            let items = []
            res.data.data.map(item => {
              let itemObj = {
                id: item.category_id,
                title: item.category_name
              }
              if (item.children.length) {
                let childs = []
                item.children.map(child => {
                  let childObj = {
                    id: child.category_id,
                    title: child.category_name
                  }
                  if (child.children.length) {
                    let grands = []
                    child.children.map(grand => {
                      const grandObj = {
                        id: grand.category_id,
                        title: grand.category_name
                      }
                      grands.push(grandObj)
                    })
                    Object.assign(childObj, { children: grands })
                  }
                  childs.push(childObj)
                })
                Object.assign(itemObj, { children: childs })
              }
              items.push(itemObj)
            })
            this.list = items
            this.loading = false
          })
          break
        case 'tag':
          Object.assign(query, {
            tag_name: this.keywords
          })
          api.goods.getTagList(query).then(res => {
            let list = []
            res.data.data.list.map(item => {
              list.push({
                id: item.tag_id,
                title: item.tag_name
              })
            })
            this.list = list
            this.total = res.data.data.total_count
            this.loading = false
          })
          break
        case 'article':
          Object.assign(query, {
            title: this.keywords
          })
          api.article.getArticleList(this.params).then(res => {
            let list = []
            res.data.data.list.map(item => {
              list.push({
                id: item.article_id,
                title: item.title
              })
            })
            this.list = list
            this.total = res.data.data.total_count
            this.loading = false
          })
          break
        case 'planting':
          // console.log(query)
          Object.assign(query, {
            article_type: 'bring',
            title: this.keywords,
            distributor_id: this.store.id
          })
          api.article.getArticleList(query).then(res => {
            let list = []
            res.data.data.list.map(item => {
              list.push({
                id: item.article_id,
                title: item.title
              })
            })
            this.list = list
            this.total = res.data.data.total_count
            this.loading = false
          })
          break
        case 'link':
          let list = [{
            title: '会员开通',
            id: 'vipgrades'
          }, {
            title: '社区团购',
            id: 'grouppurchase'
          }, {
            title: '门店列表',
            id: 'wxstore'
          }, {
            title: '服务H5私教',
            id: 'serviceH5Coach'
          }, {
            title: '服务H5私教01',
            id: 'serviceH5CoachiPhone'
          }, {
            title: '服务H5私教02',
            id: 'serviceH5CoachiPad'
          }, {
            title: '服务H5私教03',
            id: 'serviceH5CoachMac'
          }, {
            title: '服务H5私教04',
            id: 'serviceH5CoachWatch'
          }, {
            title: '服务H5公开课',
            id: 'serviceH5Classhour'
          },

          {
            title: '服务H5维修售后',
            id: 'serviceH5Weixiufuwu'
          },
          {
            title: '服务H5新机设置',
            id: 'serviceH5Shezhifuwu'
          },
          {
            title: '我的门店活动',
            id: 'shopActivity'
            },
          {
            title: 'EC自定义页面',
            id: 'ecEustomPage'
          },
          {
            title: '活动路径-01',
            id: 'serviceH5active01'
          },
          {
            title: '活动路径-02',
            id: 'serviceH5active02'
          },
          {
            title: '活动路径-03',
            id: 'serviceH5active03'
          },
          {
            title: '活动路径-04',
            id: 'serviceH5active04'
          },
          {
            title: '活动路径-05',
            id: 'serviceH5active05'
          },
          {
            title: '活动路径-06',
            id: 'serviceH5active06'
          },
          {
            title: '活动路径-07',
            id: 'serviceH5active07'
          },
          {
            title: '活动路径-08',
            id: 'serviceH5active08'
          },
          {
            title: '活动路径-09',
            id: 'serviceH5active09'
          },
          {
            title: '活动路径-10',
            id: 'serviceH5active10'
          },

          {
            title: '活动路径-11',
            id: 'serviceH5active11'
          },
          {
            title: '活动路径-12',
            id: 'serviceH5active12'
          },
          {
            title: '活动路径-13',
            id: 'serviceH5active13'
          },
          {
            title: '活动路径-14',
            id: 'serviceH5active14'
          },
          {
            title: '活动路径-15',
            id: 'serviceH5active15'
          },
          {
            title: '活动路径-16',
            id: 'serviceH5active16'
          },
          {
            title: '活动路径-17',
            id: 'serviceH5active17'
          },
          {
            title: '活动路径-18',
            id: 'serviceH5active18'
          },
          {
            title: '活动路径-19',
            id: 'serviceH5active19'
          },
          {
            title: '活动路径-20',
            id: 'serviceH5active20'
          },


          ]

          /*  {
            title: '服务H5我的已约',
            id: 'serviceH5Yiyue'
          }, {
            title: '服务H5售后',
            id: 'serviceH5Sales'
          }, */
          if (this.system_mode !== 'standard') {
            list.push({
              title: '楼层引导',
              id: 'floorguide'
            })
          }
          this.list = list
          this.total = 1
          this.loading = false
          break
        case 'marketing':
          this.list = [{
            title: '限时团购',
            id: 'groups_list'
          },{
            title: '平台订单营销',
            id: 'platform_order_marketing'
          }]
          this.total = 1
          this.loading = false
          break
        case 'platform_order_marketing':
          Object.assign(query, {
            custom_page: 1,
            activity_title: this.keywords
          })
          api.promotions.getCouponActivity(query).then(res => {
            let list = []
            res.data.data.list.map(item => {
              list.push({
                title: item.activity_title,
                activity_begin_time: item.activity_begin_time,
                activity_end_time: item.activity_end_time,
                id: item.activity_id,
                card_id: item.card_ids
              })
            })
            this.list = list
            this.total = res.data.data.total_count
            this.loading = false
          })
          break
        case 'seckill':
          Object.assign(query, {
            status: 'valid',
            name: this.keywords
          })
          api.promotions.seckillActivityGetList(query).then(res => {
            let list = []
            res.data.data.list.map(item => {
              list.push({
                id: item.seckill_id,
                title: item.activity_name
              })
            })
            this.list = list
            this.total = res.data.data.total_count
            this.loading = false
          })
          break
        case 'custom_page':
          Object.assign(query, {
            template_name: this.template_name,
            page_name: this.keywords
          })
          api.wxa.getCustomPageList(query).then(res => {
            let list = []
            res.data.data.list.map(item => {
              list.push({
                id: item.id,
                title: item.page_name
              })
            })
            this.list = list
            this.total = res.data.data.total_count
            this.loading = false
          })
          break
        case 'coupon':
          Object.assign(query, {
            store_self: false,
            page_no: query.page
          })
          api.cardticket.getCardList(query).then(res => {
            let list = []
            res.data.data.list.map(item => {
              list.push({
                id: item.card_id,
                title: item.title,
                card_type: item.card_type
              })
            })
            this.list = list
            this.total = res.data.data.total_count
            this.loading = false
          })
          break
        case 'wxstore':
          Object.assign(query, {
            is_valid: true,
            name: this.keywords
          })
          api.marketing.getDistributorList(query).then(res => {
            let list = []
            res.data.data.list.map(item => {
              list.push({
                id: item.distributor_id,
                title: item.name
              })
            })
            this.list = list
            this.total = res.data.data.total_count
            this.loading = false
          })
          break
        case 'liverooms':
          Object.assign(query, {
            wxapp_id: this.wxapp_id
          })
          api.promotions.getLiverooms(query).then(res => {
            let list = []
            res.data.data.list.map(item => {
              list.push({
                id: item.roomid,
                cover_img: item.cover_img,
                start_time: item.start_time,
                end_time: item.end_time,
                title: item.name
              })
            })
            this.list = list
            this.total = res.data.data.total_count
            this.loading = false
          })
          break
        case 'redpackage':
          api.marketing.getRedpacketList(query).then(res => {
            let list = []
            res.data.data.list.map(item => {
              list.push({
                id: item.id,
                title: item.title,
                ctoken: item.ctoken
              })
            })
            this.list = list
            this.total = res.data.data.total_count
            this.loading = false
          })
          break
        case 'offlineactivity':
          api.marketing.getOfflineactivityList(query).then(res => {
            let list = []
            res.data.data.list.map(item => {
              list.push({
                id: item.activity_id,
                title: item.activity_title
              })
            })
            this.list = list
            this.total = res.data.data.total_count
            this.loading = false
          })
          break
        case 'modal':
          this.list = [
            {
              title: '教育优惠抽屉',
              id: 'drawer'
            }, {
              title: '城市列表抽屉',
              id: 'drawer-store'
            },
          ]
          if (this.$route.path.indexOf('custompage') > -1) {
            this.list.push({
              title: '门店列表抽屉',
              id: 'drawer-shop'
            })
          }
          this.total = 0
          this.loading = false
          break
          case 'special':
          this.list = [
            {
              title: '复制链接',
              id: 'CopyLink'
            },
            {
              title: '跳转小程序',
              id: 'thirdpage'
            },
            {
              title: 'EC客服',
              id: 'ecService'
            },
            {
              title: '视频',
              id: 'videopage'
            }
          ]
          this.total = 1
          this.loading = false
          break
          case 'compare':
          this.list = [
            {
              title: '手机对比',
              id: 'iphone'
            },
            {
              title: '平板对比',
              id: 'ipad'
            },
            {
              title: '电脑对比',
              id: 'mac'
            },
            {
              title: '手表对比',
              id: 'watch'
            },
          ]
          this.total = 1
          this.loading = false
          break
        case 'lottery':
        Object.assign(query, {
          marketing_title: this.keywords
          })
          api.lottery.getLotteryActivityList(query).then(res => {
            let list = []
            res.data.data.list.map(item => {
              list.push({
                id: item.marketing_id,
                title: item.marketing_title
              })
            })
            this.list = list
            this.total = res.data.data.total_count
            this.loading = false
          })
          break
        default:
      }
    },
    pageChange (val) {
      this.params.page = val
      this.fetch(this.type)
    },
    handleCurrentChange (val) {
      this.$emit('onClick', val)
    }
  },
  mounted () {
    this.fetch(this.type)
  }
}
</script>

<style scoped lang="scss">
.pager {
  margin-top: 20px;
}
</style>
