<template>
  <div class="picker-afterfilm">
    <SpFinder ref="finder" :class="[{ 'no-multiple': !multiple }]" :other-config="{
      'max-height': 460,
    }" url="/aftersales/material/real/getlist" :fixed-row-action="true" :setting="{
      columns: [
        { name: 'ID', key: 'id', width: '100px' },
        {
          name: '封面图', key: 'cover_pic', render: (h, { row }) => h('el-image', {
            class: 'item-image',
            props: { src: row.cover_pic, fit: 'cover' },
          }
          )
        },
        { name: '描述', key: 'title' },
        {
          name: '素材内容', key: 'original_material', render: (h, scope) => h('video', {
            attrs: {
              src: scope.row.original_material?.material_url, controls: true
            },
            class: 'video-html'
          },
            '您的浏览器不支持 video 标签。'
          )
        }
      ]
    }" :hooks="{
      beforeSearch: beforeSearch,
      afterSearch: afterSearch
    }" @select="onSelect" />
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import BasePicker from './base'
import PageMixin from '../mixins/page'
import { render } from 'nprogress'
export default {
  name: 'PickerAfterFilm',
  extends: BasePicker,
  mixins: [PageMixin],
  config: {
    title: '选择商品'
  },
  props: ['value'],
  data() {
    return {
      localSelection: [],
      multiple: this.value?.multiple ?? true,
    }
  },
  created() {
    this.localSelection = cloneDeep(this.value.data) || []
  },
  methods: {
    beforeSearch(params) {
      params = {
        ...params,
        status: 'indue',
        limit:params.pageSize
      }
      return params
    },
    afterSearch(response) {
      const { list } = response.data.data
      if (this.localSelection.length > 0) {
        const ids = this.value.data.map((m) => m.id)
        const selectRows = list.filter((item) => ids.includes(item.id))
        const { finderTable } = this.$refs.finder.$refs
        setTimeout(() => {
          finderTable.$refs.finderTable.setSelection(selectRows)
        })
      }
    },
    onSearch() {
      this.$refs.finder.refresh(true)
    },
    onSelect(selection, row) {
      if (!this.multiple) {
        const { finderTable } = this.$refs.finder.$refs
        console.log('finderTable:', finderTable)
        finderTable.clearSelection()
        this.localSelection = [row]
        setTimeout(() => {
          finderTable.$refs.finderTable.setSelection(selection.length > 0 ? [row] : [])
        })
      } else {
        const isAdd = selection.includes(row)
        const idx = this.localSelection.findIndex((f) => f.id === row.id)
        if (isAdd && idx === -1) {
          this.localSelection.push(row)
        } else {
          this.localSelection.splice(idx, 1)
        }
      }
    },
    handleSelectAll(list) {
      this.localSelection = [...list]
    },
  },
  watch: {
    localSelection: {
      handler(val) {
        this.updateVal(val)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.picker-afterfilm {
  .item-image {
    width: 100px;
    height: 100px;
  }

  .sp-finder {
    &.no-multiple {
      .sp-finder-bd {
        .el-table__fixed-header-wrapper {
          table thead {
            tr {
              th {
                &:nth-child(1) {
                  .el-checkbox {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }

      .el-table__fixed-body-wrapper {
        top: 38px !important;
      }
    }
  }
}
</style>